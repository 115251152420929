import { useDispatch, useSelector } from "react-redux";
import {
  applicationSelector,
  submitApplication,
  saveValue,
  getCurrentProductLenderId,
  // @ts-expect-error: ignoring as it is existing js file
} from "@/store/slices/applicationFormSlice";
import type { ApplicationFormSlice, ApplicationState } from "@/store/slices/types/applicationFormSlice";
// @ts-expect-error: ignoring as it is existing js file
import { updateLenderName } from "@/components/Utils/helperFunction";

const useSubmission = () => {
  const { _id, submissionModal } = useSelector<ApplicationFormSlice, ApplicationState>(applicationSelector);
  const dispatch = useDispatch();

  const handleSubmit = () => {
    dispatch(
      submitApplication({
        submitType: submissionModal.submissionType,
        applicationId: _id,
        lenderType: updateLenderName(submissionModal.lender ?? ""),
        productDetails: submissionModal.productDetails,
        documents: submissionModal.selectedDocuments,
      }),
      // @ts-expect-error: existing dispatch
    ).then((_res) => {
      dispatch(saveValue({ applyApplication: true }));
      dispatch(getCurrentProductLenderId(""));
    });
  };

  return { handleSubmit };
};

export { useSubmission };
