import {
  StatusExpandCard,
  Grid,
  Typography,
  Stack,
  Divider,
  useFinanceableTheme,
} from "@financeable-com-au/financeable-ui";
import { DefaultRatesFees } from "@/components/LenderProductCard/DefaultRatesFees";
import { useLenderCardContext } from "@/components/LenderProductCard/LenderCardContext";
import { CriteriaProgress } from "@/components/LenderProductCard/CriteriaProgress";
import { FieldProgress } from "@/components/LenderProductCard/FieldProgress";
import { useLenderCardCriteria } from "@/components/LenderProductCard/hooks";

const LenderCardCondensed = () => {
  const {
    product,
    isSingleLenderProduct,
    lenderCard: { repayments, rate },
  } = useLenderCardContext();
  const theme = useFinanceableTheme();

  // If product is undefined, return null.
  if (!product) return null;

  const { logo, lender, productName } = product;
  const { criteriaStatus, criteraList } = useLenderCardCriteria();

  return (
    <StatusExpandCard status={criteriaStatus} isCondensed>
      <Grid container columnSpacing={4}>
        <Grid
          xs={3}
          borderRight={`1px solid ${theme.palette.neutral[200]}`}
          display="flex"
          flexDirection="row"
          alignItems="center"
          flexWrap="wrap"
        >
          {logo ? (
            <img
              width="100%"
              height="auto"
              style={{ objectFit: "contain", maxWidth: "75px" }}
              src={logo}
              alt={lender}
            />
          ) : (
            <Typography level="body-sm">{lender}</Typography>
          )}
        </Grid>
        <Grid xs={9} display="flex" flexDirection="column" rowGap={3}>
          {isSingleLenderProduct && (
            <Stack flexDirection="row" columnGap={1} alignItems="center">
              <Typography level="body-lg" bold component="h3" textColor="neutral.900">
                {lender}
              </Typography>
              <Typography level="body-sm">({productName})</Typography>
            </Stack>
          )}
          <Stack flexDirection="row" flexWrap="wrap" justifyContent="space-between">
            <Typography level={isSingleLenderProduct ? "title-md" : "title-sm"} component="h4">
              {repayments.monthly.formattedValue}
            </Typography>
            {!isSingleLenderProduct && (
              <>
                <Divider orientation="vertical" />
                <Typography level="title-sm" component="h4">
                  {rate.default.formattedValue}
                </Typography>
              </>
            )}
          </Stack>
          <div>
            <CriteriaProgress />
            <FieldProgress />
          </div>
        </Grid>
      </Grid>
      {isSingleLenderProduct && (
        <Stack flexDirection="column" mt={5} rowGap={5}>
          <DefaultRatesFees />
          <Divider />
          <Stack>
            <Typography level="title-sm" component="h5" mb={3}>
              Criteria
            </Typography>
            <Stack flexDirection="column" rowGap={1}>
              {criteraList.map(({ label, icon }) => (
                <Stack flexDirection="row" alignItems="center" columnGap={3} key={label}>
                  {icon}
                  <Typography level="body-xs">{label}</Typography>
                </Stack>
              ))}
            </Stack>
          </Stack>
        </Stack>
      )}
    </StatusExpandCard>
  );
};

export { LenderCardCondensed };
