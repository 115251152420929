import { startCase, lowerFirst } from "lodash";
import type { PillAccent } from "@financeable-com-au/financeable-ui";
import { fCurrencyDecimal, fPercent } from "@/utils/formatNumber";
import { type Fee, FeeFrequency, ApplicationType } from "@/store/slices/types/applicationFormSlice";
// @ts-expect-error existing utils
import calculateRepayments from "@/utils/calculateRepayments";
// @ts-expect-error existing utils
import getMonthlyRate from "@/utils/getMonthlyRate";
import { type LoadingItem } from "../LenderCardContext/types/LenderProductTypes";
import { RepaymentFrequency } from "../hooks/useQuoteCalculatorForm";

const sortLoadingList = (loadingList: LoadingItem[]) => {
  return [...loadingList].sort((firstItem, secondItem) => {
    if (firstItem.result && firstItem.result !== secondItem.result) return -1;
    if (firstItem.result === undefined && secondItem.result) return 1;
    if (secondItem.result === undefined && !firstItem.result) return 1;
    if (firstItem.result === secondItem.result) return 0;
    if (!secondItem.result) return -1;
    return 0;
  });
};

const getLoadingList = (loadingList: LoadingItem[]) => {
  return loadingList.map((item) => ({
    ...item,
    formattedValue: item.valueType === "percentage" ? fPercent(item.value) : `${item.value}`,
  }));
};

const getFeesList = (fees: Fee[]) => {
  return fees.map((fee) => ({
    ...fee,
    formattedValue: fCurrencyDecimal(fee.value ?? 0),
    key: lowerFirst(startCase(fee.name).replace(/\s/g, "")),
  }));
};

const allAvailableFeesList = [
  {
    name: "Origination fee",
  },
  {
    name: "Document fee",
  },
  {
    name: "Establishment fee",
  },
  {
    name: "Monthly fee",
  },
  {
    name: "Inspection fee",
  },
  {
    name: "Account fee",
  },
  {
    name: "Referral fee",
  },
  {
    name: "Payment processing fee",
  },
];

const getSelectableFeesList = (feesList: Fee[]) => {
  const allAvailableFeesListWithProperties: Fee[] = allAvailableFeesList.map((fee) => ({
    ...fee,
    key: lowerFirst(startCase(fee.name).replace(/\s/g, "")),
    value: 0,
    frequency: FeeFrequency.Upfront,
  }));
  return allAvailableFeesListWithProperties.filter(
    (fee) => !feesList.some((f) => f.name.toUpperCase() === fee.name.toUpperCase()),
  );
};

const getTotalFeesByFrequency = ({ fees, frequency }: { fees: Fee[]; frequency: FeeFrequency }) => {
  return fees.reduce((acc, fee) => {
    if (fee.frequency === frequency) {
      return acc + (fee.value ? Number(fee.value) : 0);
    }
    return acc;
  }, 0);
};

const getDocumentPillColour: Record<string, PillAccent> = {
  "Full doc": 300,
  Adverse: 100,
  "Light doc": 50,
  "Low doc": 400,
  Standard: 200,
};

const calculateQuoteRepayments = ({
  financeAmount,
  rate,
  term,
  balloon,
  netAssetValue,
  isInArrears,
}: {
  financeAmount: number;
  rate: number;
  term: number;
  balloon: number;
  netAssetValue: number;
  isInArrears: boolean;
}) => {
  const monthlyRate = getMonthlyRate(rate);
  const repayments = calculateRepayments(
    monthlyRate,
    term,
    -financeAmount,
    (balloon / 100) * netAssetValue,
    isInArrears ? 0 : 1,
  );
  return Number(repayments.toFixed(2));
};

const getLoanAmount = (
  applicationType: ApplicationType,
  {
    totalFinancedFees,
    netAssetValue,
    requestedLoanAmount,
  }: { totalFinancedFees: number; netAssetValue: number; requestedLoanAmount: number },
) => {
  if (applicationType === ApplicationType.Personal) {
    return requestedLoanAmount + totalFinancedFees;
  }
  return netAssetValue + totalFinancedFees;
};

const getFinanceAmount = (
  applicationType: ApplicationType,
  {
    totalFinancedFees,
    netAssetValue,
    brokerageAmount,
    requestedLoanAmount,
  }: { totalFinancedFees: number; netAssetValue: number; brokerageAmount: number; requestedLoanAmount: number },
) => {
  switch (applicationType) {
    case ApplicationType.Commercial:
      return netAssetValue + totalFinancedFees + brokerageAmount;
    case ApplicationType.Consumer:
      return netAssetValue + totalFinancedFees;
    case ApplicationType.Personal:
      return requestedLoanAmount + totalFinancedFees;
    default:
      return 0;
  }
};

const getRepaymentsByFrequency = ({
  monthlyRepayments,
  repaymentFrequency,
}: {
  monthlyRepayments: number;
  repaymentFrequency: RepaymentFrequency;
}) => {
  const annualRepayments = monthlyRepayments * 12;
  switch (repaymentFrequency) {
    case RepaymentFrequency.Weekly:
      return annualRepayments / 52;
    case RepaymentFrequency.Fortnightly:
      return annualRepayments / 26;
    case RepaymentFrequency.Monthly:
      return monthlyRepayments;
  }
};

const getWeeklyRepaymentsHavingMonthly = ({ monthlyRepaymentsValue }: { monthlyRepaymentsValue: number }) => {
  return monthlyRepaymentsValue / (52 / 12);
};

const getFormattedWeeklyRepayments = ({
  repaymentsValue,
  repaymentFrequency,
}: {
  repaymentsValue: number;
  repaymentFrequency: RepaymentFrequency.Monthly;
}) => {
  if (repaymentFrequency === RepaymentFrequency.Monthly) {
    const weeklyRepayments = getWeeklyRepaymentsHavingMonthly({ monthlyRepaymentsValue: repaymentsValue });
    return `${fCurrencyDecimal(weeklyRepayments)}${getRepaymentFrequencyLabel(RepaymentFrequency.Weekly)}`;
  }
  return "";
};

const getMonthlyRepaymentsHavingWeekly = ({ weeklyRepaymentsValue }: { weeklyRepaymentsValue: number }) => {
  return (weeklyRepaymentsValue * 52) / 12;
};

const getMonthlyRepaymentsHavingFortnightly = ({
  fortnightlyRepaymentsValue,
}: {
  fortnightlyRepaymentsValue: number;
}) => {
  return (fortnightlyRepaymentsValue * 26) / 12;
};

const getFormattedMonthlyRepayments = ({
  repaymentsValue,
  repaymentFrequency,
}: {
  repaymentsValue: number;
  repaymentFrequency: RepaymentFrequency.Weekly | RepaymentFrequency.Fortnightly;
}) => {
  switch (repaymentFrequency) {
    case RepaymentFrequency.Weekly: {
      const monthlyRepayments = getMonthlyRepaymentsHavingWeekly({ weeklyRepaymentsValue: repaymentsValue });
      return `${fCurrencyDecimal(monthlyRepayments)}${getRepaymentFrequencyLabel(RepaymentFrequency.Monthly)}`;
    }
    case RepaymentFrequency.Fortnightly: {
      const monthlyRepayments = getMonthlyRepaymentsHavingFortnightly({ fortnightlyRepaymentsValue: repaymentsValue });
      return `${fCurrencyDecimal(monthlyRepayments)}${getRepaymentFrequencyLabel(RepaymentFrequency.Monthly)}`;
    }
  }
};

const getRepaymentFrequencyLabel = (repaymentFrequency: RepaymentFrequency) => {
  switch (repaymentFrequency) {
    case RepaymentFrequency.Weekly:
      return "/wk";
    case RepaymentFrequency.Fortnightly:
      return "/ftn";
    case RepaymentFrequency.Monthly:
      return "/mo";
  }
};

// ## TODO: (FeeFrequency) When the fee frequency has changed, we do not need to convert the lettercase.
const formatFeesForSaveQuote = (fees: Fee[]) => {
  return fees.map(({ key, name, value, frequency }) => ({
    key: key ?? lowerFirst(startCase(name).replace(/\s/g, "")),
    name,
    value: Number(value),
    frequency: startCase(frequency),
  }));
};

export {
  sortLoadingList,
  getLoadingList,
  getFeesList,
  getSelectableFeesList,
  getTotalFeesByFrequency,
  getDocumentPillColour,
  calculateQuoteRepayments,
  getLoanAmount,
  getFinanceAmount,
  getRepaymentsByFrequency,
  getWeeklyRepaymentsHavingMonthly,
  getFormattedWeeklyRepayments,
  getFormattedMonthlyRepayments,
  getRepaymentFrequencyLabel,
  formatFeesForSaveQuote,
};
