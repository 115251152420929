import { reusableFetch } from "../../utils/useAxios";

export const expirationMiddleware = (store) => (next) => async (action) => {
  if (action.type === "persist/REHYDRATE" && action.payload) {
    const currentTime = Date.now();
    const expirationTime = 2629440 * 1000; // 1 month

    try {
      const { data } = await reusableFetch(`lender/log`, "GET", null);
      const lenderLog = data.data.lenderLog;
      const logTimestamp = Math.floor((new Date(lenderLog?.createdAt) || 0) / 1000) * 1000;

      Object.keys(action.payload).forEach((key) => {
        const persistedState = action.payload[key];
        if (persistedState && persistedState.timestamp) {
          if (currentTime - persistedState.timestamp > expirationTime) {
            delete action.payload[key];
          }
        }
        if (persistedState && persistedState.queries) {
          const queries = persistedState.queries;
          Object.keys(queries).forEach((query) => {
            const queryTimestamp = Math.floor(queries[query].fulfilledTimeStamp / 1000) * 1000;
            if (["pending", "rejected"].includes(queries[query].status) || logTimestamp > queryTimestamp) {
              delete queries[query];
            }
          });
        }
      });
    } catch (err) {
      console.error("Failed to fetch lender log:", err);
      store.dispatch({
        type: "lender/fetchError",
        payload: err.message,
      });
      return next(action);
    }
  }
  return next(action);
};
