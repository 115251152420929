import { useSelector } from "react-redux";
import InfoIcon from "@mui/icons-material/Info";
import type { FormikProps } from "formik";
import { Stack, Typography, Tooltip, IconButton } from "@financeable-com-au/financeable-ui";
// @ts-expect-error: ignoring as it is existing js file
import { applicationSelector } from "@/store/slices/applicationFormSlice";
import {
  type ApplicationFormSlice,
  type ApplicationState,
  ApplicationType,
} from "@/store/slices/types/applicationFormSlice";
import { useLenderCardContext } from "@/components/LenderProductCard/LenderCardContext";

const CustomRatesFees = () => {
  const {
    isSingleLenderProduct,
    lenderCard,
    lenderCardCustom: { rate, loanAmount, financeAmount, brokerage, fees },
  } = useLenderCardContext();
  const typographySize = isSingleLenderProduct ? "body-sm" : "body-xs";
  // Get application information.
  const { applicationType } = useSelector<ApplicationFormSlice, ApplicationState>(applicationSelector);

  return (
    <Stack flexDirection="column" rowGap={2} flexGrow={1}>
      <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
        <Typography level="body-md" bold>
          Lender / Custom Rate
        </Typography>
        <Typography level="title-md">
          {lenderCard.rate.default.formattedValue} | {rate.default.formattedValue}
        </Typography>
      </Stack>
      <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
        <Typography level={typographySize} textColor="neutral.500">
          Effective Rate
        </Typography>
        <Typography level={typographySize} bold>
          {rate.effective.formattedValue}
        </Typography>
      </Stack>
      <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
        <Typography level={typographySize} textColor="neutral.500">
          Comparison Rate
        </Typography>
        <Typography level={typographySize} bold>
          {rate.comparison.formattedValue}
        </Typography>
      </Stack>
      <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
        <Stack flexDirection="row" alignItems="center" justifyContent="flex-start">
          <Typography level={typographySize} textColor="neutral.500">
            Loan Amount
          </Typography>
          <Tooltip
            title={
              <Stack padding={2} flexDirection="column" rowGap={2}>
                <Typography>
                  Includes total financed fees of <Typography bold>{fees.totalFinanced.formattedValue}</Typography>
                </Typography>
              </Stack>
            }
          >
            <IconButton aria-label="Tooltip" isRounded={true} size="sm" variant="inline">
              <InfoIcon sx={{ fontSize: "1rem" }} />
            </IconButton>
          </Tooltip>
        </Stack>
        <Typography level={typographySize} bold>
          {loanAmount.formattedValue}
        </Typography>
      </Stack>
      {applicationType === ApplicationType.Commercial && (
        <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
          <Stack flexDirection="row" alignItems="center" justifyContent="flex-start">
            <Typography level={typographySize} textColor="neutral.500">
              Finance Amount
            </Typography>
            <Tooltip
              title={
                <Stack padding={2} flexDirection="column" rowGap={2}>
                  <Typography>
                    Includes brokerage amount of <Typography bold>{brokerage.amount.formattedValue}</Typography>
                  </Typography>
                </Stack>
              }
            >
              <IconButton aria-label="Tooltip" isRounded={true} size="sm" variant="inline">
                <InfoIcon sx={{ fontSize: "1rem" }} />
              </IconButton>
            </Tooltip>
          </Stack>
          <Typography level={typographySize} bold>
            {financeAmount.formattedValue}
          </Typography>
        </Stack>
      )}
      {(applicationType === ApplicationType.Commercial || applicationType === ApplicationType.Consumer) && (
        <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
          <Typography level={typographySize} textColor="neutral.500">
            {applicationType === ApplicationType.Consumer ? "Commission" : "Brokerage"}
          </Typography>
          <Typography level={typographySize} bold>
            {brokerage.percentage.formattedValue} | {brokerage.amount.formattedValue}
          </Typography>
        </Stack>
      )}
      <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
        <Typography level={typographySize} textColor="neutral.500">
          Upfront / Financed Fees
        </Typography>
        <Typography level={typographySize} bold>
          {fees.totalUpfront.formattedValue} | {fees.totalFinanced.formattedValue}
        </Typography>
      </Stack>
    </Stack>
  );
};

export { CustomRatesFees };
