const consumerCreditProviders = [
  {
    name: "ACN 603 303 126 Pty Ltd t/as Angle Finance",
    displayLink: "www.anglefinance.com.au",
    link: "https://www.anglefinance.com.au/",
  },
  {
    name: "Affordable Car Loans Pty Ltd",
    displayLink: "www.affordablecarloans.com.au",
    link: "https://affordablecarloans.com.au/",
  },
  {
    name: "Alex Bank Pty Ltd",
    displayLink: "www.alex.bank",
    link: "https://www.alex.bank/",
  },
  {
    name: "ANZ Banking Group Pty Ltd",
    displayLink: "www.anz.com",
    link: "https://www.anz.com.au/",
  },
  {
    name: "Australian Motorcycle Marine Finance Pty Ltd",
    displayLink: "www.ammf.com.au",
    link: "https://www.ammf.com.au/",
  },
  {
    name: "Automotive Financial Services Pty Ltd",
    displayLink: "www.afs.com",
    link: "",
  },
  {
    name: "Azora Asset Finance Pty Ltd",
    displayLink: "www.azorafinance.com.au",
    link: "",
  },
  {
    name: "Branded Financial Services Pty Ltd",
    displayLink: "www.brandedfinancial.com.au",
    link: "https://www.brandedfinancial.com.au/",
  },
  {
    name: "Bank of Queensland Limited",
    displayLink: "www.boq.com.au",
    link: "https://www.boq.com.au/",
  },
  {
    name: "Bizcap AU Pty Ltd t/as Bizcap",
    displayLink: "www.bizcap.com.au",
    link: "https://www.bizcap.com.au/",
  },
  {
    name: "BOQ Credit Pty Limited",
    displayLink: "www.boq.com.au",
    link: "https://www.boq.com.au/",
  },
  {
    name: "BOQ Equipment Finance Limited",
    displayLink: "www.boq.com.au",
    link: "https://www.boq.com.au/",
  },
  {
    name: "Capital Finance Australia Limited",
    displayLink: "www.capitalfinance.com.au",
    link: "https://www.capitalfinance.com.au/",
  },
  {
    name: "Earlypay Equipment Finance Pty Ltd Earlypay Equipment Group Pty Ltd Earlypay Cashflow Finance Pty Ltd",
    displayLink: "www.earlypay.com.au",
    link: "https://www.earlypay.com.au/",
  },
  {
    name: "Commonwealth Bank of Australia Ltd",
    displayLink: "www.commbank.com.au",
    link: "https://www.commbank.com.au/",
  },
  {
    name: "Dynamoney Limited",
    displayLink: "www.dynamoney.com",
    link: "https://dynamoney.com/",
  },
  {
    name: "Equity-One Mortgage Fund Ltd",
    displayLink: "www.equity-one.com",
    link: "http://www.equity-one.com/",
  },
  {
    name: "Firstmac Asset Funding Pty Ltd/Firstmac Limited",
    displayLink: "www.firstmac.com.au",
    link: "https://www.firstmac.com.au/",
  },
  {
    name: "Fin One Pty Ltd t/as Finance One / Finance One Commercial Pty Ltd",
    displayLink: "www.financeone.com.au",
    link: "https://financeone.com.au/",
  },
  {
    name: "flexicommercial Pty Ltd",
    displayLink: "www.flexicommercial.com/au",
    link: "https://www.flexicommercial.com/au/",
  },
  {
    name: "Gamma Duo Financial Services Pty Ltd",
    displayLink: "www.gammaduo.com.au",
    link: "https://www.gammaduo.com.au/",
  },
  {
    name: "Judo Bank Pty Ltd",
    displayLink: "www.judo.bank",
    link: "https://www.judo.bank/",
  },
  {
    name: "Latitude Financial Services Limited",
    displayLink: "www.latitudefinancial.com.au",
    link: "https://www.latitudefinancial.com.au/",
  },
  {
    name: "Liberty Financial Pty Ltd",
    displayLink: "www.liberty.com.au",
    link: "https://www.liberty.com.au/",
  },
  {
    name: "Metro Finance Pty Ltd",
    displayLink: "www.metrofin.com.au",
    link: "https://metrofin.com.au/",
  },
  {
    name: "Metro CF Pty Ltd",
    displayLink: "www.metrofin.com.au",
    link: "https://metrofin.com.au/",
  },
  {
    name: "MoneyMe Financial Group Pty Ltd",
    displayLink: "www.moneyme.com.au",
    link: "https://www.moneyme.com.au/",
  },
  {
    name: "Money Place Assets Pty Ltd",
    displayLink: "www.moneyplace.com.au",
    link: "https://www.moneyplace.com.au/",
  },
  {
    name: "Money Place AFLS Ltd",
    displayLink: "www.moneyplace.com.au",
    link: "https://www.moneyplace.com.au/",
  },
  {
    name: "Money 3 Loans Pty Ltd",
    displayLink: "www.money3.com.au",
    link: "https://www.money3.com.au/",
  },
  {
    name: "Moneytech Finance Pty Ltd",
    displayLink: "www.moneytech.com.au",
    link: "https://www.moneytech.com.au/",
  },
  {
    name: "Morris Finance Ltd",
    displayLink: "www.morrisfinance.com.au",
    link: "https://morrisfinance.com.au/",
  },
  {
    name: "Moula Money Pty Ltd",
    displayLink: "www.moula.com.au",
    link: "https://moula.com.au/",
  },
  {
    name: "Multipli Pty Limited",
    displayLink: "www.multipli.com.au",
    link: "https://www.multipli.com/",
  },
  {
    name: "National Australia Bank Limited",
    displayLink: "www.nab.com.au",
    link: "https://www.nab.com.au/",
  },
  {
    name: "Now Finance Group Pty Ltd",
    displayLink: "www.nowfinance.com.au",
    link: "https://nowfinance.com.au/",
  },
  {
    name: "On Deck Capital Pty Limited",
    displayLink: "www.ondeck.com.au",
    link: "https://www.ondeck.com.au/",
  },
  {
    name: "Pepper Asset Finance Pty Ltd",
    displayLink: "www.pepper.com.au",
    link: "https://www.peppermoney.com.au/",
  },
  {
    name: "Plenti RE Limited",
    displayLink: "www.plenti.com.au",
    link: "https://www.plenti.com.au/",
  },
  {
    name: "Prospa Advance Pty Ltd",
    displayLink: "www.prospa.com.au",
    link: "",
  },
  {
    name: "Quest Finance Australia Pty Ltd",
    displayLink: "https://quest.finance",
    link: "https://quest.finance/",
  },
  {
    name: "Resimac Asset Finance Pty Ltd",
    displayLink: "www.resimacassetfinance.com.au",
    link: "https://www.resimacassetfinance.com.au/",
  },
  {
    name: "RACV Finance Limited",
    displayLink: "www.racv.com.au",
    link: "https://www.racv.com.au/",
  },
  {
    name: "Scottish Pacific Business Finance Pty Ltd",
    displayLink: "www.scotpac.com",
    link: "https://www.scotpac.com.au/",
  },
  {
    name: "Scottish Pacific (BFS) Pty Ltd trading as ScotPac",
    displayLink: "www.scotpac.com",
    link: "https://www.scotpac.com.au/",
  },
  {
    name: "Shift Financial Pty Limited t/as Shift Financial",
    displayLink: "www.shift.com.au",
    link: "https://www.shift.com.au/",
  },
  {
    name: "SocietyOne Australia Pty Ltd",
    displayLink: "www.societyone.com.au",
    link: "https://societyone.com.au/",
  },
  {
    name: "Selfco a division of Auswide Bank Ltd ABN 40 087 652 060",
    displayLink: "www.selfco.com.au",
    link: "http://www.selfco.com.au/",
  },
  {
    name: "Vestone Capital Pty Limited",
    displayLink: "https://vestonecapital.com/",
    link: "https://vestonecapital.com/",
  },
  {
    name: "Volkswagen Financial Services Australia Pty Ltd",
    displayLink: "www.vwfs.com.au",
    link: "https://www.vwfs.com.au/",
  },
  {
    name: "Westlawn Finance Limited",
    displayLink: "www.westlawn.com.au",
    link: "http://www.westlawn.com.au/",
  },
  {
    name: "Westpac Banking Corporation",
    displayLink: "www.westpac.com.au",
    link: "https://www.westpac.com.au/",
  },
  {
    name: "WISR Finance Pty Ltd",
    displayLink: "www.wisr.com.au",
    link: "https://wisr.com.au/",
  },
];

const commercialCreditProviders = [
  {
    name: "ACN 603 303 126 Pty Ltd t/as Angle Finance",
    displayLink: "www.anglefinance.com.au",
    link: "https://www.anglefinance.com.au/",
  },
  {
    name: "ANZ Banking Group Pty Ltd",
    displayLink: "www.anz.com",
    link: "https://www.anz.com.au/",
  },
  {
    name: "Australian Motorcycle Marine Finance Pty Ltd",
    displayLink: "www.ammf.com.au",
    link: "https://www.ammf.com.au/",
  },
  {
    name: "Automotive Financial Services Pty Ltd",
    displayLink: "www.afs.com",
    link: "",
  },
  {
    name: "Azora Asset Finance Pty Ltd",
    displayLink: "www.azorafinance.com.au",
    link: "",
  },
  {
    name: "Branded Financial Services Pty Ltd",
    displayLink: "www.brandedfinancial.com.au",
    link: "https://www.brandedfinancial.com.au/",
  },
  {
    name: "Bank of Queensland Limited",
    displayLink: "www.boq.com.au",
    link: "https://www.boq.com.au/",
  },
  {
    name: "Bizcap AU Pty Ltd t/as Bizcap",
    displayLink: "www.bizcap.com.au",
    link: "https://www.bizcap.com.au/",
  },
  {
    name: "BOQ Credit Pty Limited",
    displayLink: "www.boq.com.au",
    link: "https://www.boq.com.au/",
  },
  {
    name: "BOQ Equipment Finance Limited",
    displayLink: "www.boq.com.au",
    link: "https://www.boq.com.au/",
  },
  {
    name: "Capital Finance Australia Limited",
    displayLink: "www.capitalfinance.com.au",
    link: "https://www.capitalfinance.com.au/",
  },
  {
    name: "Earlypay Equipment Finance Pty Ltd Earlypay Equipment Group Pty Ltd Earlypay Cashflow Finance Pty Ltd",
    displayLink: "www.earlypay.com.au",
    link: "https://www.earlypay.com.au/",
  },
  {
    name: "CFI Finance Pty Ltd",
    displayLink: "www.cfifinance.com.au",
    link: "https://cfifinance.com.au/",
  },
  {
    name: "Commonwealth Bank of Australia Ltd",
    displayLink: "www.commbank.com.au",
    link: "https://www.commbank.com.au/",
  },
  {
    name: "Dynamoney Limited",
    displayLink: "www.dynamoney.com",
    link: "https://dynamoney.com/",
  },
  {
    name: "Equity-One Mortgage Fund Ltd",
    displayLink: "www.equity-one.com",
    link: "http://www.equity-one.com/",
  },
  {
    name: "Fin One Pty Ltd t/as Finance One / Finance One Commercial Pty Ltd",
    displayLink: "www.financeone.com.au",
    link: "https://financeone.com.au/",
  },
  {
    name: "flexicommercial Pty Ltd",
    displayLink: "www.flexicommercial.com/au",
    link: "https://www.flexicommercial.com/au/",
  },
  {
    name: "Fund2Market Pty Ltd t/as Banjo Loans",
    displayLink: "www.banjoloans.com",
    link: "https://www.banjoloans.com/",
  },
  {
    name: "Judo Bank Pty Ltd",
    displayLink: "www.judo.bank",
    link: "https://www.judo.bank/",
  },
  {
    name: "Latitude Financial Services Limited",
    displayLink: "www.latitudefinancial.com.au",
    link: "https://www.latitudefinancial.com.au/",
  },
  {
    name: "Liberty Financial Pty Ltd",
    displayLink: "www.liberty.com.au",
    link: "https://www.liberty.com.au/",
  },
  {
    name: "Lumi Finance Pty Ltd",
    displayLink: "www.lumi.com.au",
    link: "https://www.lumi.com.au/",
  },
  {
    name: "Maple Asset Finance Pty Ltd",
    displayLink: "www.maplecommercial.com.au",
    link: "https://www.maplecommercial.com.au/",
  },
  {
    name: "Medfin Australia Pty Ltd",
    displayLink: "www.medfin.com.au",
    link: "https://www.medfin.com.au/",
  },
  {
    name: "Metro Finance Pty Ltd",
    displayLink: "www.metrofin.com.au",
    link: "https://metrofin.com.au/",
  },
  {
    name: "Metro CF Pty Ltd",
    displayLink: "www.metrofin.com.au",
    link: "https://metrofin.com.au/",
  },
  {
    name: "MoneyMe Financial Group Pty Ltd",
    displayLink: "www.moneyme.com.au",
    link: "https://www.moneyme.com.au/",
  },
  {
    name: "Moneytech Finance Pty Ltd",
    displayLink: "www.moneytech.com.au",
    link: "https://www.moneytech.com.au/",
  },
  {
    name: "Morris Finance Ltd",
    displayLink: "www.morrisfinance.com.au",
    link: "https://morrisfinance.com.au/",
  },
  {
    name: "Moula Money Pty Ltd",
    displayLink: "www.moula.com.au",
    link: "https://moula.com.au/",
  },
  {
    name: "Multipli Pty Limited",
    displayLink: "www.multipli.com.au",
    link: "https://www.multipli.com/",
  },
  {
    name: "National Australia Bank Limited",
    displayLink: "www.nab.com.au",
    link: "https://www.nab.com.au/",
  },
  {
    name: "On Deck Capital Pty Limited",
    displayLink: "www.ondeck.com.au",
    link: "https://www.ondeck.com.au/",
  },
  {
    name: "Pepper Asset Finance Pty Ltd",
    displayLink: "www.pepper.com.au",
    link: "https://www.peppermoney.com.au/",
  },
  {
    name: "Plenti RE Limited",
    displayLink: "www.plenti.com.au",
    link: "https://www.plenti.com.au/",
  },
  {
    name: "Prospa Advance Pty Ltd",
    displayLink: "www.prospa.com.au",
    link: "",
  },
  {
    name: "Quest Finance Australia Pty Ltd",
    displayLink: "https://quest.finance",
    link: "https://quest.finance/",
  },
  {
    name: "RACV Finance Limited",
    displayLink: "www.racv.com.au",
    link: "https://www.racv.com.au/",
  },
  {
    name: "Resimac Asset Finance Pty Ltd",
    displayLink: "www.resimacassetfinance.com.au",
    link: "https://www.resimacassetfinance.com.au/",
  },
  {
    name: "Scottish Pacific Business Finance Pty Ltd",
    displayLink: "www.scotpac.com",
    link: "https://www.scotpac.com.au/",
  },
  {
    name: "Scottish Pacific (BFS) Pty Ltd trading as ScotPac",
    displayLink: "www.scotpac.com",
    link: "https://www.scotpac.com.au/",
  },
  {
    name: "Shift Financial Pty Limited t/as Shift Financial",
    displayLink: "www.shift.com.au",
    link: "https://www.shift.com.au/",
  },
  {
    name: "Selfco a division of Auswide Bank Ltd ABN 40 087 652 060",
    displayLink: "www.selfco.com.au",
    link: "http://www.selfco.com.au/",
  },
  {
    name: "TruePillars Pty Ltd",
    displayLink: "https://www.truepillars.com/",
    link: "https://www.truepillars.com/",
  },
  {
    name: "Vestone Capital Pty Limited",
    displayLink: "https://vestonecapital.com/",
    link: "https://vestonecapital.com/",
  },
  {
    name: "Volkswagen Financial Services Australia Pty Ltd",
    displayLink: "www.vwfs.com.au",
    link: "https://www.vwfs.com.au/",
  },
  {
    name: "Westlawn Finance Limited",
    displayLink: "www.westlawn.com.au",
    link: "http://www.westlawn.com.au/",
  },
  {
    name: "Westpac Banking Corporation",
    displayLink: "www.westpac.com.au",
    link: "https://www.westpac.com.au/",
  },
  {
    name: "YellowGate Group Pty Ltd",
    displayLink: "www.yellowgategroup.com.au",
    link: "https://www.yellowgategroup.com.au/",
  },
];

const creditReportingBodies = [
  {
    name: "Experian",
    tel: "(03) 8622 1600",
    websiteLink: "https://www.experian.com.au/",
    website: "www.experian.com.au",
  },
  {
    name: "Illion",
    tel: "13 23 33",
    websiteLink: "https://www.illion.com.au/",
    website: "www.illion.com.au",
  },
  {
    name: "Tasmanian Collection Service",
    tel: "(03) 6213 5555",
    websiteLink: "https://www.tascol.com.au/",
    website: "www.tascol.com.au",
  },
  {
    name: "Equifax",
    tel: "1300 921 621",
    websiteLink: "https://www.equifax.com.au/",
    website: "www.equifax.com.au",
  },
];

export { consumerCreditProviders, commercialCreditProviders, creditReportingBodies };
