import moment from "moment";
import { readableDateFormat, isoDateFormat } from "@/constants";

interface DateOptions {
  year: "numeric" | "2-digit" | undefined;
  month: "numeric" | "2-digit" | "long" | "short" | "narrow" | undefined;
  day: "numeric" | "2-digit" | undefined;
}

interface TimeOptions {
  hour: "numeric" | "2-digit" | undefined;
  minute: "numeric" | "2-digit" | undefined;
  hour12: boolean;
}

// Input date format: YYYY-MM-DD.
// Output date format: DD-MM-YYYY, unless overwritten.
// If the date is invalid, the date will be returned as is.
const formatToReadableDate = (dateStr: string, dateFormat?: string) => {
  const date = moment(dateStr, isoDateFormat);
  const isValid = date.isValid();
  if (isValid) {
    return date.format(dateFormat || readableDateFormat);
  }
  return dateStr;
};

// Input date format: DD-MM-YYYY.
// Output date format: YYYY-MM-DD.
// If the date is invalid, the date will be returned as is.
const formatToISODate = (dateStr: string) => {
  const date = moment(dateStr, readableDateFormat);
  const isValid = date.isValid();
  if (isValid) {
    return date.format(isoDateFormat);
  }
  return dateStr;
};

const formatDateTime = (dateStr: string) => {
  // Sample: Oct 14, 2024 | 11:35 AM
  const date = new Date(dateStr);

  const dateOptions: DateOptions = { year: "numeric", month: "short", day: "numeric" };
  const timeOptions: TimeOptions = { hour: "numeric", minute: "numeric", hour12: true };

  const formattedDate = date.toLocaleDateString("en-US", dateOptions);
  const formattedTime = date.toLocaleTimeString("en-US", timeOptions);

  const finalFormatted = `${formattedDate} | ${formattedTime}`;

  return finalFormatted;
};

export { formatToReadableDate, formatToISODate, formatDateTime };
