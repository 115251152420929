import { useState, useEffect } from "react";
import { Stack, Divider } from "@financeable-com-au/financeable-ui";
import { Loader } from "@components/Loader";
import { LendersSettingsContext } from "./LendersSettingsContext";
import type { LendersModalType, SelectedLenderType, FullLendersList } from "./LendersSettingsContext";
import { LendersHeader } from "./LendersHeader";
import { LendersModal } from "./LendersModal";
import { LendersList } from "./LendersList";
import { useLenderList } from "./hooks";

const Lenders = () => {
  // Sets the states to be shared via context.
  const [openLendersModal, setOpenLendersModal] = useState<LendersModalType>(null);
  const [selectedLender, setSelectedLender] = useState<SelectedLenderType>(null);
  const [addedLenders, setAddedLenders] = useState<FullLendersList[]>([]);
  const [remainingLenders, setRemainingLenders] = useState<FullLendersList[]>([]);
  const { remainingLendersList, addedLendersList, consumerIsFetching, personalIsFetching, commercialIsFetching } =
    useLenderList();

  useEffect(() => {
    setRemainingLenders(remainingLendersList);
  }, [remainingLendersList.length]);

  useEffect(() => {
    if (JSON.stringify(addedLenders) !== JSON.stringify(addedLendersList)) {
      setAddedLenders(addedLendersList);
    }
  }, [addedLendersList]);

  if (consumerIsFetching || personalIsFetching || commercialIsFetching) {
    return (
      <Stack flexDirection="column" gap={6} width="100%">
        <Loader text="Loading lenders..." />
      </Stack>
    );
  }

  return (
    <LendersSettingsContext.Provider
      value={{
        openLendersModal,
        selectedLender,
        addedLenders,
        remainingLenders,
        setOpenLendersModal,
        setSelectedLender,
      }}
    >
      <Stack flexDirection="column" gap={6} width="100%" mb={10}>
        <LendersHeader />
        <Divider />
        <LendersList />
      </Stack>
      <LendersModal />
    </LendersSettingsContext.Provider>
  );
};

export { Lenders };
