import { useSelector } from "react-redux";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { Stepper, Step, StepIndicator } from "@financeable-com-au/financeable-ui";
// @ts-expect-error: ignoring as it is existing js file
import { applicationSelector } from "@/store/slices/applicationFormSlice";
import type { ApplicationFormSlice, ApplicationState, SubmissionStep } from "@/store/slices/types/applicationFormSlice";

interface SubmissionStepperProps {
  activeStep: SubmissionStep["type"];
}

const SubmissionStepper = ({ activeStep }: SubmissionStepperProps) => {
  const { submissionModal } = useSelector<ApplicationFormSlice, ApplicationState>(applicationSelector);
  const { steps } = submissionModal;

  return (
    <Stepper width="100%">
      {steps.map(({ isCompleted, type, stepper }, index) => {
        const isActive = type === activeStep;
        return (
          <Step
            key={type}
            indicator={
              isCompleted ? (
                <StepIndicator variant="solid" color="success" data-testid="step-indicator-complete">
                  <CheckRoundedIcon sx={{ fontSize: "1rem" }} />
                </StepIndicator>
              ) : (
                <StepIndicator
                  variant={isActive ? "solid" : "outlined"}
                  data-testid={`step-indicator-${isActive ? "active" : "incomplete"}`}
                >
                  {index + 1}
                </StepIndicator>
              )
            }
          >
            {stepper}
          </Step>
        );
      })}
    </Stepper>
  );
};

export { SubmissionStepper };
