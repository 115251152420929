import { useSelector } from "react-redux";
import { Grid, Stack, Divider, Pill, useBreakpoints } from "@financeable-com-au/financeable-ui";
import type { ApplicationFormSlice, UserDetailsState } from "@/store/slices/types/applicationFormSlice";
// @ts-expect-error: ignoring as it is existing js file
import { userDetailsSelector } from "@/store/slices/applicationFormSlice";
import { CustomRatesFees } from "@/components/LenderProductCard/CustomRatesFees";
import { CustomRepayments } from "@/components/LenderProductCard/CustomRepayments";
import { DefaultRatesFees } from "@/components/LenderProductCard/DefaultRatesFees";
import { CriteriaProgress } from "@/components/LenderProductCard/CriteriaProgress";
import { FieldProgress } from "@/components/LenderProductCard/FieldProgress";
import { useLenderCardContext } from "@/components/LenderProductCard/LenderCardContext";
import { type QuoteCalculatorFormFields } from "@/components/LenderProductCard/hooks/useQuoteCalculatorForm";
import { DefaultRepayments } from "@/components/LenderProductCard/DefaultRepayments";

interface LenderCardInfoProps {
  isCustomQuote: boolean;
}

const LenderCardInfo = ({ isCustomQuote }: LenderCardInfoProps) => {
  const {
    product,
    lenderCard: { documents },
  } = useLenderCardContext();
  const { smUp } = useBreakpoints();

  // If product is undefined, return null.
  if (!product) return null;

  const { logo, lender } = product;
  // Use isQuoteOpen to determine if this is part of a quote flow.
  const { isQuoteOpen } = useSelector<ApplicationFormSlice, UserDetailsState>(userDetailsSelector);
  const isApplication = !isQuoteOpen;

  return (
    <Grid container columnSpacing={{ xs: 0, sm: 5 }} rowSpacing={{ xs: 4, sm: 0 }}>
      <Grid xs={12} sm={2}>
        <Stack flexDirection={{ xs: "column", sm: "row" }} justifyContent="space-between" height="100%">
          {logo && <img width="80%" height="auto" style={{ objectFit: "contain" }} src={logo} alt={lender} />}
          <Divider orientation={smUp ? "vertical" : "horizontal"} />
        </Stack>
      </Grid>
      <Grid xs={12} sm={6}>
        <Stack flexDirection="column" rowGap={4} flexGrow={1}>
          {isCustomQuote ? <CustomRepayments /> : <DefaultRepayments />}
          <Stack>
            <CriteriaProgress />
            {isApplication && <FieldProgress />}
          </Stack>
        </Stack>
      </Grid>
      <Grid xs={12} sm={4}>
        <Stack
          flexDirection="row"
          justifyContent="flex-end"
          alignItems="center"
          columnGap={2}
          mb={2}
          flexWrap="wrap"
          rowGap={1}
        >
          {documents?.map(({ name, colour }) => (
            <Pill accent={colour} key={name}>
              {name}
            </Pill>
          ))}
        </Stack>
        {isCustomQuote ? <CustomRatesFees /> : <DefaultRatesFees />}
      </Grid>
    </Grid>
  );
};

export { LenderCardInfo };
