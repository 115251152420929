import { useDispatch, useSelector } from "react-redux";
import { Stack, Button, Typography } from "@financeable-com-au/financeable-ui";
// @ts-expect-error: ignoring as it is existing js file
import { setSubmissionModalOpen, applicationSelector } from "@/store/slices/applicationFormSlice";
import type { ApplicationFormSlice, ApplicationState } from "@/store/slices/types/applicationFormSlice";
import { SubmissionStepper } from "@/components/ApplicationSubmissionModal/SubmissionStepper";
import { useSubmissionSteps } from "@/components/ApplicationSubmissionModal/hooks";

const Confirmation = () => {
  const { proceedSubmissionSteps } = useSubmissionSteps();
  const dispatch = useDispatch();
  const handleCloseSubmissionModal = () => {
    dispatch(setSubmissionModalOpen({ isOpen: false }));
  };
  const { submissionModal } = useSelector<ApplicationFormSlice, ApplicationState>(applicationSelector);

  const handleConfirm = () => {
    proceedSubmissionSteps({ activeStep: "confirmation" });
  };

  return (
    <Stack rowGap={10}>
      <SubmissionStepper activeStep="confirmation" />
      <Stack rowGap={2}>
        <Typography component="h2" id="modal-title" level="title-md">
          Confirmation
        </Typography>
        <Typography>
          By proceeding, you are submitting your application to{" "}
          <Typography component="span" textColor="text.primary" bold>
            '{submissionModal.lender}'
          </Typography>
          .
        </Typography>
      </Stack>
      <Stack direction="row" columnGap={2}>
        <Button variant="secondary" onClick={handleCloseSubmissionModal} fullWidth>
          Cancel
        </Button>
        <Button variant="primary" fullWidth onClick={handleConfirm}>
          Confirm
        </Button>
      </Stack>
    </Stack>
  );
};

export { Confirmation };
