import { Link, Typography, useFinanceableTheme } from "@financeable-com-au/financeable-ui";
import { ApplicationType } from "@/store/slices/types/applicationFormSlice";
import { consumerCreditProviders, commercialCreditProviders } from "../constants/creditLists";

interface CreditProvidersTableProps {
  applicationType: ApplicationType;
}

const CreditProvidersTable = ({ applicationType }: CreditProvidersTableProps) => {
  const theme = useFinanceableTheme();
  const creditProviders =
    applicationType === ApplicationType.Consumer || applicationType === ApplicationType.Personal
      ? consumerCreditProviders
      : commercialCreditProviders;

  return (
    <table width="100%">
      <thead>
        <tr style={{ backgroundColor: theme.palette.neutral[400], color: theme.palette.neutral[100] }}>
          <th colSpan={2} style={{ padding: "10px" }}>
            SCHEDULE 1 - CREDIT PROVIDERS
          </th>
        </tr>
      </thead>
      <tbody>
        {creditProviders.map((provider) => (
          <tr>
            <td style={{ border: `1px solid ${theme.palette.neutral[400]}`, padding: "8px" }}>
              <Typography>{provider.name}</Typography>
            </td>
            <td style={{ border: `1px solid ${theme.palette.neutral[400]}`, padding: "8px" }}>
              <Typography>
                <Link href={provider.link} target="_blank" rel="noreferrer">
                  {provider.displayLink}
                </Link>
              </Typography>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export { CreditProvidersTable };
