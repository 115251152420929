import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dialog, DialogTitle, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { useSnackbar } from "notistack";
import { APPLICATION_TYPES, ERRORS_MESSAGE, getApplicationTab, getApplicationTypeList, getStartApplicationUrl } from "../../constants";
import {
  saveValue,
  startApplication,
  applicationSelector,
  userDetailsSelector,
} from "../../store/slices/applicationFormSlice";
import { userPermissionsSelector } from "../../store/slices/userSlice";
export function StartApplicationDialog({ open, handleClose, startApplication, applicationTypeList }) {

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Select application type</DialogTitle>
      <List sx={{ pt: 0 }}>
        {applicationTypeList?.map((item) => (
          <ListItem key={item?.key}>
            <ListItemButton onClick={() => startApplication(item?.value)}>
              <ListItemText primary={item?.label} />
            </ListItemButton>
          </ListItem>
        ))}
        <ListItem>
          <ListItemButton
            disabled
          // onClick={() => handleListItemClick(email)} key={email}
          >
            <ListItemText primary="Unsecured Business Loan" />
          </ListItemButton>
        </ListItem>
      </List>
    </Dialog>
  );
}
export default function StartApplication() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation()
  const { enqueueSnackbar } = useSnackbar();
  const permissions = useSelector(userPermissionsSelector);
  const isQuoteEnable = permissions?.application?.quote_create;
  const isApplicationEnable = permissions?.application?.create;
  const isOrganisationEnable = permissions?.org?.create
  const { isNewApplication } = useSelector(userDetailsSelector);
  // const application = useSelector(applicationSelector);
  const { _id } = useSelector(applicationSelector);
  const [openDialog, setOpenDialog] = useState(false);
  let currentActiveTab = ''
  const pathName = location?.pathname
  
  if (pathName?.includes(getApplicationTab.quote)) {
    currentActiveTab = getApplicationTab.quote
  } else if (pathName?.includes(getApplicationTab.calculator)) {
    currentActiveTab = getApplicationTab.calculator
  }
  else if(pathName?.includes(getApplicationTab.organisation)){
    currentActiveTab = getApplicationTab.organisation
  }
  else {
    currentActiveTab = getApplicationTab.application
  }

  const handle = {
    handleClose: () => {
      setOpenDialog(false);
    },
    startApplication: (applicationType) => {
      const url = getStartApplicationUrl[currentActiveTab]

      dispatch(startApplication({ url: `${url}/${applicationType}` })).then((res) => {
        if (res?.payload?.data?.data) {
          // enqueueSnackbar(SUCCESS_MESSAGE.applicationCreateSuccessMsg, {
          //   variant: "success",
          //   autoHideDuration: 5000,
          // });
        } else {
          enqueueSnackbar(res?.payload?.status_message || ERRORS_MESSAGE.fetchErrorMsg, {
            variant: "error",
            autoHideDuration: 5000,
          });
        }
      });
    },
    createCalculatorApplication: () => {
      handle.startApplication(APPLICATION_TYPES.COMMERCIAL)
    },
    createOrganisation:()=>{
      navigate('/organisation/new')
    }
  };
  useEffect(() => {
    if (isNewApplication && _id) {
      dispatch(saveValue({ isNewApplication: false }));
      navigate(`/${currentActiveTab}/${_id}`);
      handle.handleClose();
    }
  }, [isNewApplication]);
  return (
    <>
      {getApplicationTab[currentActiveTab] === getApplicationTab.application && isApplicationEnable && (
        <Button
          color="primary"
          variant="contained"
          size="small"
          onClick={async () => {
            setOpenDialog(true);
          }}
          style={{ marginRight: "30px" }}
        >
          New {getApplicationTab[currentActiveTab]}
        </Button>
      )}
      {getApplicationTab[currentActiveTab] === getApplicationTab.quote && isQuoteEnable && (
        <Button
          color="primary"
          variant="contained"
          size="small"
          onClick={async () => {
            setOpenDialog(true);
          }}
          style={{ marginRight: "30px" }}
        >
          New {getApplicationTab[currentActiveTab]}
        </Button>
      )}
      {getApplicationTab[currentActiveTab] === getApplicationTab.calculator && (
        <Button
          color="primary"
          variant="contained"
          size="small"
          onClick={handle.createCalculatorApplication}
          style={{ marginRight: "30px" }}
        >
          New {getApplicationTab[currentActiveTab]}
        </Button>
      )}
     {getApplicationTab[currentActiveTab] === getApplicationTab.organisation && isOrganisationEnable && (
        <Button
          color="primary"
          variant="contained"
          size="small"
          onClick={handle.createOrganisation}
          style={{ marginRight: "30px" }}
        >
          New {getApplicationTab[currentActiveTab]}
        </Button>
      )}
      <StartApplicationDialog
        open={openDialog}
        handleClose={handle.handleClose}
        startApplication={handle.startApplication}
        applicationTypeList={getApplicationTypeList[currentActiveTab]}
      />
    </>
  );
}