import * as yup from "yup";
import { useLenderContext } from "../LendersSettingsContext";
import type { LenderCredentials } from "../LendersSettingsContext";

interface LenderCredentialsFields extends LenderCredentials {
  type?: string;
  tooltip?: string;
  value?: string;
}

interface LenderFormText {
  title?: string;
  description?: string;
  fields: LenderCredentialsFields[];
  validationSchema: yup.ObjectSchema<Record<string, yup.StringSchema>>;
  initialValues: Record<string, string>;
}

const inputType = (attribute: string) => {
  if (attribute === "email") {
    return "email";
  }
  if (attribute === "password" || attribute === "apiKey") {
    return "password";
  }
  return "text";
};

const useLenderForm = () => {
  const { selectedLender } = useLenderContext();

  if (selectedLender) {
    const {
      lenderCredentials,
      lender: lenderName,
      apiKey,
      organisationId,
      brokerId,
      password,
      username,
      lenderEmail,
    } = selectedLender;

    // Tooltip messages dictionary.
    const tooltipMessageDictionary = {
      email: `The email address you use to submit applications to ${lenderName || "the lender"}.`,
      brokerId: `Your ID given to you by ${lenderName || "the lender"}.`,
      organisationId: `The ID of your organisation assigned by ${lenderName || "the lender"}.`,
      apiKey: `Your unique identifier provided by ${lenderName || "the lender"}.`,
    };

    // Values dictionary.
    const valueDictionary = {
      email: lenderEmail,
      username,
      password,
      brokerId,
      organisationId,
      apiKey,
    };

    // Map fields with type, tooltip and value.
    const getFields = (lenderCredentials: LenderCredentials[]) => {
      return lenderCredentials.map(({ attribute, label }) => {
        return {
          attribute,
          label,
          type: inputType(attribute),
          tooltip: tooltipMessageDictionary[attribute as keyof typeof tooltipMessageDictionary],
          value: valueDictionary[attribute as keyof typeof valueDictionary],
        };
      });
    };
    const fields = getFields(lenderCredentials);

    // Initial values.
    const initialValues = fields.reduce<Record<string, string>>((acc, field) => {
      acc[field.attribute] = field.value ?? "";
      return acc;
    }, {});

    // Validation.
    const validationObject: Record<string, yup.StringSchema> = fields.reduce(
      (acc, currField) => ({
        ...acc,
        [currField.attribute]: yup.string().trim().required(`${currField.label} is required`),
      }),
      {},
    );
    const validationSchema = yup.object(validationObject);

    // ## TODO: Update title and description.
    // ## https://financeable.atlassian.net/browse/FWA-69
    const sections: LenderFormText = {
      title: "Placeholder title to be replaced",
      description: "Placeholder description to be replaced",
      fields,
      validationSchema,
      initialValues,
    };
    return sections;
  }

  return null;
};

export { useLenderForm };
