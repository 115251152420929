import { forwardRef } from "react";
import { NumericFormat, type NumberFormatValues } from "react-number-format";

interface DecimalMaskFormatProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  id?: string;
  isAllowedFlag?: boolean;
  maxDigits?: number;
  maxPlaces?: number;
}

const DecimalMaskFormat = forwardRef<HTMLInputElement, DecimalMaskFormatProps>(
  ({ onChange, name, id, isAllowedFlag, maxDigits = 7, maxPlaces = 2, ...other }, ref) => {
    const isAllowedNumberInput = (values: NumberFormatValues) => {
      const { floatValue } = values;
      // Allow empty values
      if (floatValue === undefined) return true;
      // Ensure the number does not exceed ${maxDigits} before the decimal point and decimal ${maxPlaces}
      const [integer, fraction] = floatValue.toString().split(".");
      return (!integer || integer.length <= maxDigits) && (!fraction || fraction.length <= maxPlaces);
    };

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        decimalScale={2}
        fixedDecimalScale
        name={name}
        id={id}
        onValueChange={({ value }) => {
          onChange({
            target: {
              name,
              value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
        isAllowed={isAllowedFlag ? isAllowedNumberInput : undefined}
      />
    );
  },
);

export { DecimalMaskFormat };
