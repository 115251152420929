import { useDispatch, useSelector } from "react-redux";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Typography,
  Stack,
  Checkbox,
  Button,
  FormControl,
  FormHelperText,
} from "@financeable-com-au/financeable-ui";
import { openSnackbar } from "@/store/slices/snackbarSlice";
import { ERRORS_MESSAGE } from "@/constants";
import {
  setSubmissionModalOpen,
  applicationSelector,
  updateAcknowledgement,
  // @ts-expect-error: ignoring as it is existing js file
} from "@/store/slices/applicationFormSlice";
import { SubmissionStepper } from "@/components/ApplicationSubmissionModal/SubmissionStepper";
import type { ApplicationFormSlice, ApplicationState } from "@/store/slices/types/applicationFormSlice";
import { useSubmissionSteps } from "@/components/ApplicationSubmissionModal/hooks";

const AcknowledgementDisclaimer = () => {
  // Acknowledge step from submission modal state.
  const { submissionModal, _id } = useSelector<ApplicationFormSlice, ApplicationState>(applicationSelector);
  const acknowledgeStep = submissionModal.steps.find((step) => step.type === "acknowledgement");

  const { proceedSubmissionSteps } = useSubmissionSteps();
  const dispatch = useDispatch();

  const handleCloseSubmissionModal = () => {
    dispatch(setSubmissionModalOpen({ isOpen: false }));
  };

  const handleAcceptAcknowledgement = ({ isAgreed }: { isAgreed: boolean }) => {
    dispatch(
      updateAcknowledgement({
        applicationId: _id,
        isAcknowledgementAccept: isAgreed,
      }),
      // @ts-expect-error: existing dispatch
    ).then(({ payload, error }) => {
      if (error) {
        dispatch(
          openSnackbar({
            message: payload.status_message || ERRORS_MESSAGE.fetchErrorMsg,
            intent: "danger",
          }),
        );
      } else {
        proceedSubmissionSteps({ activeStep: "acknowledgement" });
      }
    });
  };

  const formik = useFormik({
    initialValues: {
      isAgreed: false,
    },
    onSubmit: (values) => {
      handleAcceptAcknowledgement(values);
    },
    validationSchema: yup.object({
      isAgreed: yup.boolean().isTrue("Please agree to the above terms and conditions."),
    }),
    validateOnChange: false,
    validateOnBlur: false,
  });

  return (
    <Stack rowGap={10}>
      <SubmissionStepper activeStep="acknowledgement" />
      <form onSubmit={formik.handleSubmit}>
        <Stack rowGap={6}>
          <Stack rowGap={3}>
            {acknowledgeStep?.label && (
              <Typography component="h2" id="modal-title" level="title-md">
                {acknowledgeStep.label}
              </Typography>
            )}
            {acknowledgeStep?.body && (
              <Typography>
                <span dangerouslySetInnerHTML={{ __html: acknowledgeStep.body }}></span>
              </Typography>
            )}
          </Stack>
          <FormControl error={!!formik.errors.isAgreed}>
            <Checkbox
              label="I acknowledge and agree to the above terms and conditions."
              size="sm"
              name="isAgreed"
              value="isAgreed"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {!!formik.errors.isAgreed && (
              <FormHelperText marginX={0} marginY={1}>
                <ErrorOutlineIcon sx={{ fontSize: "1rem" }} />
                {formik.errors.isAgreed}
              </FormHelperText>
            )}
          </FormControl>
          <Stack direction="row" columnGap={2}>
            <Button variant="secondary" onClick={handleCloseSubmissionModal} fullWidth>
              Cancel
            </Button>
            <Button variant="primary" fullWidth type="submit" loading={submissionModal.acknowledgement === "pending"}>
              Proceed
            </Button>
          </Stack>
        </Stack>
      </form>
    </Stack>
  );
};

export { AcknowledgementDisclaimer };
