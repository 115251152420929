import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/styles";
import { Button, Card, CardActionArea, Grid, Typography, Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import { usePaidServices } from "@/hooks";
import { PaidServicesTooltip } from "@/components/PaidServicesTooltip";
import CustomCardSending from "./CustomCardSending";
import Document from "./Document";
import usePoll from "../../utils/usePoll";
import {
  ALLOWED_DOCUMENT_TYPE,
  ERRORS_MESSAGE,
  MAX_DOCUMENT_UPLOAD_SIZE,
  SUCCESS_MESSAGE,
  VALID_DOCUMENT_TYPE_EXTENSION,
  creditCardModalInfo,
  payInvoiceModalInfo,
} from "../../constants";
import { userPermissionsSelector, userSelector } from "../../store/slices/userSlice";
import { HtmlTooltip } from "../ProductSelector/Product";
import {
  uploadDocument,
  saveValue,
  userDetailsSelector,
  startVerification,
  getIdentityVerification,
} from "../../store/slices/applicationFormSlice";
import { customerSelector } from "../../store/slices/customerSlice";

const Input = styled("input")({
  display: "none",
});

const Identity = React.memo(({ customer, applicationId }) => {
  const { canUsePaidServices } = usePaidServices();

  const dispatch = useDispatch();
  const permissions = useSelector(userPermissionsSelector);
  const { isCustomerLogin } = useSelector(customerSelector);
  const isDisable = isCustomerLogin ? true : permissions?.application?.edit;
  // const ref = useRef(null);
  const fileInput = useRef();
  const [error, setError] = useState("");

  const { uploadIDLoading = "", sendingIDLink, idVerificationRequest } = useSelector(userDetailsSelector);
  const userDetails = useSelector(userSelector);
  const { enqueueSnackbar } = useSnackbar();
  // const [startedCount, setStartedCount] = useState(0);
  // const [finishedCount, setFinishedCount] = useState(0);

  const [delay, setDelay] = useState(null);

  const onStart = () => {
    setDelay(30000);
  };

  // const onStop = () => {
  //   setDelay(null);
  // };

  const handle = {
    startIdentityVerification: async (customerId, mobile) => {
      let res = await dispatch(startVerification({ data: { customerId, applicationId, mobile } }));

      if (res) {
        enqueueSnackbar(SUCCESS_MESSAGE.idVerification, {
          variant: "success",
          autoHideDuration: 5000,
        });
        dispatch(saveValue({ sendingIDLink: false }));

        handle.getIdentityVerificationFn(customer?._id);
      } else {
        enqueueSnackbar(ERRORS_MESSAGE.fetchErrorMsg, {
          variant: "error",
          autoHideDuration: 5000,
        });
        dispatch(saveValue({ sendingIDLink: false }));
      }
    },
    getIdentityVerificationFn: (customerId) => {
      onStart();
    },
    uploadID: (event) => {
      // ======================================================================
      const files = event.target.files;
      const newSelectedFiles = [];
      const formData = new FormData();

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (ALLOWED_DOCUMENT_TYPE.includes(file.type)) {
          if (file.size <= MAX_DOCUMENT_UPLOAD_SIZE) {
            newSelectedFiles.push(file);
          } else {
            setError(ERRORS_MESSAGE.documentSizeMsg);
          }
        } else {
          setError(ERRORS_MESSAGE.documentTypeMsg);
        }
      }
      if (newSelectedFiles?.length) {
        dispatch(saveValue({ uploadIDLoading: true }));
        newSelectedFiles.forEach((file) => {
          formData.append("file", file);
        });
        // Update the formData object
        // formData.append("file", event.target.files[0]);
        formData.append("applicationId", applicationId);
        formData.append("customerId", customer._id);
        formData.append("name", "ID document");

        dispatch(uploadDocument({ formData })).then((res) => {
          if (res?.payload?.data) {
            enqueueSnackbar(SUCCESS_MESSAGE.fileUploadSuccess, {
              variant: "success",
              autoHideDuration: 3000,
            });
          } else {
            enqueueSnackbar(ERRORS_MESSAGE.fetchErrorMsg, {
              variant: "error",
              autoHideDuration: 3000,
            });
          }
        });
        event.target.input = null;
        setError("");
      }
    },
  };

  // const updateMobile = customer?.phoneNumbers?.find((ele) => ele.status === "current");

  // const [IDUploadButtonBackground, setIDUploadButtonBackground] = useState("secondary");
  // const [IDUploadButtonText, setIDUploadButtonText] = useState("Upload ID");

  // const [sendIDLinkBackground, setSendIDLinkBackground] = useState("secondary");
  // const [sendIDLinkText, setSendIDLinkText] = useState("Send ID Capture Link");

  // function handleIDButtonSuccessStyle() {
  // setIDUploadButtonBackground("success");
  // setIDUploadButtonText("Upload ID Successful");
  // setTimeout(() => {
  //   setIDUploadButtonBackground("secondary");
  //   setIDUploadButtonText("UPLOAD ID");
  // }, 6000);
  // }

  // function handleSendIDLinkSuccessStyle() {
  // setSendIDLinkBackground("success");
  // setSendIDLinkText("Link sent");
  // setTimeout(() => {
  //   setSendIDLinkBackground("secondary");
  //   setSendIDLinkText("Send ID capture link");
  // }, 6000);
  // }

  usePoll(async () => {
    // setStartedCount((count) => count + 1);
    dispatch(
      getIdentityVerification({
        data: {
          customer: customer._id,
          // application: applicationId,
        },
      }),
    );
    // setFinishedCount((count) => count + 1);
  }, delay);

  useEffect(() => {
    if (customer?.documents?.length > 0) setDelay(null);
  }, [customer]);

  // const [imageWidth, setImageWidth] = useState();

  // useEffect(() => {
  //   // console.log("width", ref.current ? ref.current.offsetWidth : 0);
  //   setImageWidth(ref.current ? ref.current.offsetWidth : 0);
  // }, [ref.current]);

  useEffect(() => {
    if (uploadIDLoading === false) {
      // handleIDButtonSuccessStyle();
      dispatch(saveValue({ uploadIDLoading: null }));
    }
  }, [uploadIDLoading]);

  useEffect(() => {
    if (sendingIDLink === false) {
      // handleSendIDLinkSuccessStyle();
      dispatch(saveValue({ sendingIDLink: null }));
    }
  }, [sendingIDLink]);

  return (
    <>
      <Grid
        container
        item
        xs={12}
        spacing={0}
        style={{
          margin: "0 0 30px",
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
          paddingBottom: "30px",
        }}
      >
        <Grid item xs={12}>
          <Typography fontWeight={600} style={{ marginBottom: "15px" }}>
            Identity
          </Typography>
        </Grid>

        <Stack spacing={1} direction="row" style={{ margin: "0 0 20px" }}>
          {customer?.documents?.length > 0 &&
            customer?.documents
              ?.filter((doc) =>
                [
                  "Driver licence (back)",
                  "Driver licence (front)",
                  "Customer photo",
                  "Verification certificate",
                  "ID document",
                ].includes(doc.name),
              )
              .map((doc) => <Document document={doc} customer={customer} name="identity" />)}
        </Stack>

        <Grid
          container
          item
          spacing={2}
          direction="row"
          // divider={<Divider orientation="vertical" flexItem />}
        >
          {!isCustomerLogin && (
            <Grid item xs={12} sm={6}>
              <HtmlTooltip
                tooltipPadding={"20px"}
                title={
                  canUsePaidServices ? null : <PaidServicesTooltip />
                }
              >
                <Card
                  onClick={() => {
                    if (
                      !canUsePaidServices ||
                      !customer?.phoneNumbers?.slice(-1)[0]?.number
                        ? true
                        : false
                    ) {
                      return;
                    }
                    handle.startIdentityVerification(customer._id, customer?.phoneNumbers?.slice(-1)[0]?.number);
                    // dispatch(saveValue({ linkSent: true }));
                    dispatch(saveValue({ sendingIDLink: true }));
                  }}
                >
                  <CardActionArea disableRipple={!customer?.phoneNumbers?.slice(-1)[0]?.number ? true : false}>
                    <CustomCardSending
                      title={"Get ID documents"}
                      description={`Send a self service link to 
                      upload ID documents and verify identity.`}
                      imagePath={"/static/ocr-labs-logo-dark.png"}
                      loadingState={sendingIDLink}
                      financialState={false}
                      disabled={
                        !isDisable ||
                        !canUsePaidServices ||
                        !customer?.phoneNumbers?.slice(-1)[0]?.number
                          ? true
                          : false
                      }
                      icon={false}
                      lastTitle={"Sending SMS link..."}
                    />
                  </CardActionArea>
                </Card>

                {!customer?.phoneNumbers?.filter((a) => a.status === "current")[0]?.number && (
                  <Typography variant="caption">Requires customer mobile phone number</Typography>
                )}
                {idVerificationRequest && <Typography variant="caption">{SUCCESS_MESSAGE.idVerification}</Typography>}
              </HtmlTooltip>
            </Grid>
          )}

          <Grid item xs={12} sm={6}>
            <Card style={{ height: "120px" }}>
              <Input
                accept={VALID_DOCUMENT_TYPE_EXTENSION}
                ref={fileInput}
                id={`upload-id-${customer._id}`}
                multiple
                type="file"
                value={null}
                onChange={(event) => handle.uploadID(event)}
              />
              <CardActionArea onClick={() => fileInput.current.click()}>
                <CustomCardSending
                  title={"Upload ID documents"}
                  description={`Upload ID documents from files stored locally on your
                    computer.`}
                  imagePath={""}
                  loadingState={uploadIDLoading}
                  financialState={false}
                  icon={false}
                  lastTitle={"Upload ID document..."}
                />
              </CardActionArea>
            </Card>
            {error && (
              <Typography variant="body2" style={{ color: "red" }}>
                {error}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
});

export default Identity;
