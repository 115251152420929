import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { Typography, Stack, Ul, UlItem, useFinanceableTheme } from "@financeable-com-au/financeable-ui";
import { organisationInfo } from "../constants/organisationInfo";

const CreditGuide = () => {
  const theme = useFinanceableTheme();
  return (
    <Accordion style={{ position: "static", margin: 0 }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography level="body-md">Show Credit Guide</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack rowGap={10}>
          <table width="100%">
            <thead>
              <tr style={{ backgroundColor: theme.palette.neutral[400], color: theme.palette.neutral[100] }}>
                <th colSpan={2} style={{ padding: "10px" }}>
                  AUSTRALIAN CREDIT LICENSEE DETAILS (“we”, “us”, “our”)
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ border: `1px solid ${theme.palette.neutral[400]}`, padding: "8px" }}>
                  <Typography>Australian Credit Licensee Name</Typography>
                </td>
                <td style={{ border: `1px solid ${theme.palette.neutral[400]}`, padding: "8px" }}>
                  <Typography>{organisationInfo.name}</Typography>
                </td>
              </tr>
              <tr>
                <td style={{ border: `1px solid ${theme.palette.neutral[400]}`, padding: "8px" }}>
                  <Typography>AFSL Number</Typography>
                </td>
                <td style={{ border: `1px solid ${theme.palette.neutral[400]}`, padding: "8px" }}>
                  <Typography>{organisationInfo.afsl}</Typography>
                </td>
              </tr>
              <tr>
                <td style={{ border: `1px solid ${theme.palette.neutral[400]}`, padding: "8px" }}>
                  <Typography>Address</Typography>
                </td>
                <td style={{ border: `1px solid ${theme.palette.neutral[400]}`, padding: "8px" }}>
                  <Typography>{organisationInfo.address}</Typography>
                </td>
              </tr>
              <tr>
                <td style={{ border: `1px solid ${theme.palette.neutral[400]}`, padding: "8px" }}>
                  <Typography>Email</Typography>
                </td>
                <td style={{ border: `1px solid ${theme.palette.neutral[400]}`, padding: "8px" }}>
                  <Typography>{organisationInfo.email}</Typography>
                </td>
              </tr>
              <tr>
                <td style={{ border: `1px solid ${theme.palette.neutral[400]}`, padding: "8px" }}>
                  <Typography>Phone Number</Typography>
                </td>
                <td style={{ border: `1px solid ${theme.palette.neutral[400]}`, padding: "8px" }}>
                  <Typography>{organisationInfo.tel}</Typography>
                </td>
              </tr>
            </tbody>
          </table>
          <Stack rowGap={2}>
            <Typography>About this Credit Guide:</Typography>
            <Typography>
              This Credit Guide sets out important information to help you decide whether to accept our assistance in
              obtaining a credit contract or consumer lease.
            </Typography>
          </Stack>
          <Stack rowGap={2}>
            <Typography>This Credit Guide will tell you:</Typography>
            <Ul>
              <UlItem>Who we are & how to contact us;</UlItem>
              <UlItem>Engagement & Conditions;</UlItem>
              <UlItem>Fees & Commissions;</UlItem>
              <UlItem>Referrers & Referral Fees;</UlItem>
              <UlItem>Our Responsible Lending obligations;</UlItem>
              <UlItem>Credit Providers we conduct consumer credit business with; and</UlItem>
              <UlItem>What to do if you have a complaint.</UlItem>
            </Ul>
            <Typography>
              We are required to provide this Credit Guide to you as soon as practicable after it becomes apparent, we
              are likely to provide credit assistance to you.
            </Typography>
          </Stack>
          <Stack rowGap={2}>
            <Typography>We provide “Credit Assistance” when we:</Typography>
            <Ul>
              <UlItem>
                Suggest or assist you to apply for a particular credit contract with a particular credit provider; or
              </UlItem>
              <UlItem>
                Suggest or assist you to apply for an increase to the credit limit of a particular credit contract with
                a particular credit provider; or
              </UlItem>
              <UlItem>Suggest you remain in a particular credit contract with a particular credit provider.</UlItem>
            </Ul>
          </Stack>
          <Stack rowGap={2}>
            <Typography>Engagement and Conditions:</Typography>
            <Typography>
              You (the customer) engage us (the broker) to arrange a loan on your behalf. You acknowledge that we act as
              an independent contractor to assist you to obtain and negotiate a loan.
            </Typography>
          </Stack>
          <Stack rowGap={2}>
            <Typography>Fees Payable by You:</Typography>
            <Typography>
              We sometimes charge a fee for our services. More details about any fees payable will be detailed in a
              “Quote” we will give you before a finance application is lodged. No commission is payable by you to us,
              this is paid by the credit provider.
            </Typography>
          </Stack>
          <Stack rowGap={2}>
            <Typography>How we are paid:</Typography>
            <Typography>
              We are paid commissions by Credit Providers for introducing customers. The Credit Providers we deal with
              will usually pay a commission based on:
            </Typography>
            <Ul>
              <UlItem>the size of the loan; and</UlItem>
              <UlItem>the particular loan product you have selected.</UlItem>
            </Ul>
            <Typography>
              We only receive a commission if your loan is settled and is paid to us either directly by the lender or
              paid to us by our aggregator. We may receive the following commissions after we provide credit assistance
              and your loan has settled.
            </Typography>
          </Stack>
          <Stack rowGap={2}>
            <table width="100%">
              <thead>
                <tr style={{ backgroundColor: theme.palette.neutral[400], color: theme.palette.neutral[100] }}>
                  <th colSpan={2} style={{ padding: "10px" }}>
                    Type of Commission:
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ border: `1px solid ${theme.palette.neutral[400]}`, padding: "8px" }}>
                    <Typography>Commission/Brokerage. (Paid shortly after settlement)</Typography>
                  </td>
                  <td style={{ border: `1px solid ${theme.palette.neutral[400]}`, padding: "8px" }}>
                    <Typography>Range from 0% to 5% depending on type of finance sought.</Typography>
                  </td>
                </tr>
                <tr>
                  <td style={{ border: `1px solid ${theme.palette.neutral[400]}`, padding: "8px" }}>
                    <Typography>Volume Bonus</Typography>
                  </td>
                  <td style={{ border: `1px solid ${theme.palette.neutral[400]}`, padding: "8px" }}>
                    <Typography>
                      We have a volume bonus arrangement in place with the majority of our credit providers.
                    </Typography>
                    <Typography>
                      We may receive additional commission depending on the total volume of business that we arrange
                      with a credit provider. If the relevant volume targets are met with a credit provider, additional
                      commission is payable by the credit provider to us.
                    </Typography>
                  </td>
                </tr>
                <tr>
                  <td style={{ border: `1px solid ${theme.palette.neutral[400]}`, padding: "8px" }}>
                    <Typography>Method of Calculation:</Typography>
                  </td>
                  <td style={{ border: `1px solid ${theme.palette.neutral[400]}`, padding: "8px" }}>
                    <Typography>Based on Net amount financed plus GST</Typography>
                  </td>
                </tr>
              </tbody>
            </table>
            <Typography>
              If you would like a detailed estimate of how much commission we would be paid by a particular credit
              provider, we will provide this to you.
            </Typography>
          </Stack>
          <Stack rowGap={2}>
            <Typography>Referrers and Referral fees:</Typography>
            <Typography>
              In some cases, your business may have been referred to us by non- regulated third parties such as
              accountants, financial planners, motor resellers etc. Where this is the case, we may pay a referral fee to
              these parties. If we do pay a fee to these parties, then
            </Typography>
            <Ul>
              <UlItem>They should already have told you about this; and</UlItem>
              <UlItem>
                We will either disclose the fee or a reasonable estimate in our Proposal Disclosure Document.
              </UlItem>
            </Ul>
            <Typography>
              Alternatively, if you want to know, you can ask about the fees and we will tell you how much was paid and
              how it was worked out.
            </Typography>
          </Stack>
          <Stack rowGap={2}>
            <Typography>Preliminary Assessment:</Typography>
            <Typography>
              <em>What we will need from you:</em>
            </Typography>
            <Typography>
              When we provide you with credit assistance, we must only recommend credit products that are not unsuitable
              for you. To be able to determine which loan products are not unsuitable, we are required to complete a
              Preliminary Assessment. When we make this preliminary assessment, we determine:
            </Typography>
            <Ul>
              <UlItem>
                your requirements and objectives – that is, what kind of loan do you want, and for what purpose;
              </UlItem>
              <UlItem>your financial and relevant personal situation; and</UlItem>
              <UlItem>your ability to repay the loan that you are considering.</UlItem>
            </Ul>
            <Typography>
              In assessing these factors, we are also required to take reasonable steps to verify some of the
              information you provide us.
            </Typography>
            <Typography>
              <em>This verification may include:</em>
            </Typography>
            <Ul>
              <UlItem>
                asking you for copies of documents that demonstrate your financial situation – in some cases we may also
                need to sight original documents;
              </UlItem>
            </Ul>
            <Typography>contacting third parties to assist in verifying the information that you provide.</Typography>
          </Stack>
          <Stack rowGap={2}>
            <Typography>
              <em>Obtaining a copy of your Preliminary Assessment:</em>
            </Typography>
            <Typography>
              If we haven’t already provided it to you, you may request a copy of our Preliminary Assessment, and we
              must give you a copy of it:
            </Typography>
            <Ul>
              <UlItem>
                at any time during the first 2 of years of conduction the assessment, within 7 business days; or
              </UlItem>
              <UlItem>
                between 2 years and 7 years after it was conducted, we must provide it within 21 business days.
              </UlItem>
            </Ul>
            <Typography>
              There is no charge for requesting or receiving a copy of the Preliminary Assessment.
            </Typography>
          </Stack>
          <Stack rowGap={2}>
            <Typography>Our “Consumer” Credit Providers include:</Typography>
            <Typography>
              We source credit products from a range of banks, lenders and other credit providers. However, at present,
              we write a greater percentage of loans with the following banks, lenders and other credit providers.
            </Typography>
            <Typography>
              These Lenders do not necessarily represent all the lenders who offer credit of the nature you seek.
            </Typography>
          </Stack>
          <Stack rowGap={2}>
            <Typography>Dispute Resolution and Complaints:</Typography>
            <Typography>
              Within our business we follow specific procedures to try to resolve any complaints that you may have.
            </Typography>
          </Stack>
          <Stack rowGap={2}>
            <Typography>Internal Dispute Resolution:</Typography>
            <Typography>
              If you have a complaint, please contact the disputes officer on the contact details above. They will try
              to resolve all concerns quickly & fairly.
            </Typography>
          </Stack>
          <Stack rowGap={2}>
            <Typography>External Dispute Resolution:</Typography>
            <Typography>
              In the unlikely event we cannot resolve your complaint in a satisfactory manner, or you have not received
              a response from us after 30 days, you can escalate your complaint to the below Ombudsman, a free and
              independent dispute resolution service provider.
            </Typography>
          </Stack>
          <Stack rowGap={2}>
            <Typography>Australian Financial Complaints Authority (AFCA):</Typography>
            <Typography>AFCA Tel: 1800 931 678 or info@afca.org.au</Typography>
            <Typography>
              A copy of AFCA's dispute resolution policy is available at www.afca.org.au by request.
            </Typography>
          </Stack>
          <Stack>
            <Typography>
              For more information regarding anything referred to in the Credit Guide or anything else about our
              services, just ask at any time. We’re here to help you.
            </Typography>
          </Stack>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export { CreditGuide };
