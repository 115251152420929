const organisationInfo = {
  name: "CP Insurance Pty Ltd trading as Co-Pilot Insurance",
  afsl: "232987",
  abn: "14 064 465 309",
  address: "65 Kimberley Road Hurstville, NSW 2220",
  email: "apply@cpfi.com.au",
  tel: "1300 673 171",
};

export { organisationInfo };
