import { Link, Typography, useFinanceableTheme } from "@financeable-com-au/financeable-ui";
import { creditReportingBodies } from "../constants/creditLists";

const CreditReportingBodies = () => {
  const theme = useFinanceableTheme();
  return (
    <table width="100%">
      <thead>
        <tr style={{ backgroundColor: theme.palette.neutral[400], color: theme.palette.neutral[100] }}>
          <th colSpan={3} style={{ padding: "10px" }}>
            SCHEDULE 2 - CREDIT REPORTING BODIES (CRB)
          </th>
        </tr>
      </thead>
      <tbody>
        {creditReportingBodies.map((body) => (
          <tr>
            <td style={{ border: `1px solid ${theme.palette.neutral[400]}`, padding: "8px" }}>
              <Typography>{body.name}</Typography>
            </td>
            <td style={{ border: `1px solid ${theme.palette.neutral[400]}`, padding: "8px" }}>
              <Typography>{body.tel}</Typography>
            </td>
            <td style={{ border: `1px solid ${theme.palette.neutral[400]}`, padding: "8px" }}>
              <Typography>
                <Link href={body.websiteLink} target="_blank" rel="noreferrer">
                  {body.website}
                </Link>
              </Typography>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export { CreditReportingBodies };
