import { Modal, ModalDialog, ModalOverflow } from "@financeable-com-au/financeable-ui";
import { LendersAdd } from "../LendersAdd";
import { LendersEdit } from "../LendersEdit";
import { LendersRemove } from "../LendersRemove";
import { useLenderContext } from "../LendersSettingsContext";

const modalWidth = "480px";

const LendersModal = () => {
  const { openLendersModal, setOpenLendersModal, setSelectedLender } = useLenderContext();
  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={!!openLendersModal}
      onClose={() => {
        setOpenLendersModal(null);
        setSelectedLender(null);
      }}
    >
      <ModalOverflow>
        <ModalDialog minWidth={modalWidth} maxWidth={modalWidth} padding={0}>
          {openLendersModal === "add" && <LendersAdd />}
          {openLendersModal === "edit" && <LendersEdit />}
          {openLendersModal === "remove" && <LendersRemove />}
        </ModalDialog>
      </ModalOverflow>
    </Modal>
  );
};

export { LendersModal };
