import { useState } from "react";
import { Typography, Stack, Divider } from "@financeable-com-au/financeable-ui";
import { ApplicationType } from "@/store/slices/types/applicationFormSlice";
import { PrivacyStatement } from "./PrivacyStatement";
import { CreditGuide } from "./CreditGuide";
import { Signature } from "./Signature";
interface PrivacyCreditGuideProps {
  applicationType: ApplicationType;
}

const PrivacyCreditGuide = ({ applicationType }: PrivacyCreditGuideProps) => {
  const showConsumerPrivacyCreditGuide =
    applicationType === ApplicationType.Consumer || applicationType === ApplicationType.Personal;
  const [submitted, setSubmitted] = useState(false);

  if (submitted) {
    return (
      <Stack flexDirection="column" rowGap={5} paddingBottom={10}>
        <Stack flexDirection="column" rowGap={2}>
          <Typography level="title-lg">Agreement Signed Successfully</Typography>
          <Typography level="body-lg">
            Too easy! You're one step closer and not far to go now. Enjoy the rest of your day.
          </Typography>
        </Stack>
        <Divider />
        <Typography level="body-md">You can close this window.</Typography>
      </Stack>
    );
  }
  return (
    <Stack flexDirection="column" rowGap={5} paddingBottom={10}>
      <Stack flexDirection="column" rowGap={2}>
        <Typography level="title-lg">
          Privacy Disclosure Statement {showConsumerPrivacyCreditGuide && "& Credit Guide"}
        </Typography>
        <Typography level="body-lg">
          Please read the privacy statement {showConsumerPrivacyCreditGuide && "and credit guide"} then sign at the
          bottom.
        </Typography>
      </Stack>
      <Divider />
      <PrivacyStatement applicationType={applicationType} />
      {showConsumerPrivacyCreditGuide && <CreditGuide />}
      <Signature setSubmitted={setSubmitted} />
    </Stack>
  );
};

export { PrivacyCreditGuide };
