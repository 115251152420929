import { Grid, Stack, Typography, StatusExpandContent, Ul, UlItem } from "@financeable-com-au/financeable-ui";
import { useLenderCardContext } from "@/components/LenderProductCard/LenderCardContext";
import { useLenderCardCriteria } from "@/components/LenderProductCard/hooks";

const CriteriaExpanded = () => {
  const {
    showCriteria,
    lenderCard: { fees, loadingList, infoList },
  } = useLenderCardContext();
  const { criteraList } = useLenderCardCriteria();

  return (
    <StatusExpandContent expanded={showCriteria}>
      <Grid container columnSpacing={{ xs: 0, sm: 12 }} rowSpacing={{ xs: 4, sm: 0 }}>
        <Grid xs={12} sm={4}>
          <Stack flexDirection="column" height="100%">
            <Typography level="title-sm" component="h5" mb={3}>
              Criteria
            </Typography>
            <Stack flexDirection="column" rowGap={1}>
              {criteraList.map(({ label, icon }) => (
                <Stack flexDirection="row" alignItems="center" columnGap={3} key={label}>
                  {icon}
                  <Typography level="body-xs">{label}</Typography>
                </Stack>
              ))}
            </Stack>
          </Stack>
        </Grid>
        <Grid xs={12} sm={3}>
          <Typography level="title-sm" component="h5" mb={3}>
            Extras
          </Typography>
          <Stack flexDirection="column" rowGap={2} flexGrow={1}>
            {loadingList.map(({ name, formattedValue, result }) => (
              <Stack flexDirection="row">
                <Typography level="body-xs" textColor="neutral.500">
                  {!result ? <s>{name}:&nbsp;</s> : <span>{name}:&nbsp;</span>}
                </Typography>
                <Typography level="body-xs" bold>
                  {!result ? <s>{formattedValue}</s> : <span>{formattedValue}</span>}
                </Typography>
              </Stack>
            ))}
            {(fees.lenders ?? []).map(({ name, formattedValue }) => (
              <Stack flexDirection="row">
                <Typography level="body-xs" textColor="neutral.500">
                  {name === "Origination fee" ? "Maximum origination fee" : name}:&nbsp;
                </Typography>
                <Typography level="body-xs" bold>
                  {formattedValue}
                </Typography>
              </Stack>
            ))}
          </Stack>
        </Grid>
        {infoList.length > 0 && (
          <Grid xs={12} sm={5}>
            <Typography level="title-sm" mb={3}>
              Info & Requirements
            </Typography>
            <Ul level="body-xs" textColor="neutral.500">
              {infoList.map((info) => (
                <UlItem>{info}</UlItem>
              ))}
            </Ul>
          </Grid>
        )}
      </Grid>
    </StatusExpandContent>
  );
};

export { CriteriaExpanded };
