import { Stack, Typography, Button } from "@financeable-com-au/financeable-ui";
import { useLenderContext } from "../LendersSettingsContext";

const LendersHeader = () => {
  const { setOpenLendersModal, addedLenders } = useLenderContext();
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%" columnGap={4}>
      <Stack rowGap={2}>
        <Typography level="title-lg" component="h1">
          Lenders
        </Typography>
        <Typography level="body-md">
          Configure your settings for each lender such as their email address, broker credentials and more.
        </Typography>
      </Stack>
      {addedLenders.length > 0 && <Button onClick={() => setOpenLendersModal("add")}>Add Lender</Button>}
    </Stack>
  );
};

export { LendersHeader };
