import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import LoginIcon from "@mui/icons-material/Login";
import Cookies from "js-cookie";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Stack,
  Typography,
  FormLabel,
  Input,
  Button,
  FormControl,
  FormHelperText,
  Sheet,
} from "@financeable-com-au/financeable-ui";
import { ERRORS_MESSAGE } from "@/constants";
// @ts-expect-error: ignoring as it is existing js file
import { authenticatePassword, userSelector } from "@/store/slices/userSlice";
import { openSnackbar } from "@/store/slices/snackbarSlice";
import type { UserSlice, UserState } from "@/store/slices/types/userSlice";

const SignIn = () => {
  const { login } = useSelector<UserSlice, UserState>(userSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      handleAuthenticate(values);
    },
    validationSchema: yup.object({
      email: yup.string().required("Please enter an email."),
      password: yup.string().required("Please enter a password."),
    }),
    validateOnChange: false,
    validateOnBlur: false,
  });

  const handleAuthenticate = (values: { email: string; password: string }) => {
    // @ts-expect-error: existing dispatch
    dispatch(authenticatePassword(values)).then(({ payload, error }) => {
      if (error) {
        // Show snackbar.
        dispatch(
          openSnackbar({
            message: payload.status_message || ERRORS_MESSAGE.fetchErrorMsg,
            intent: "danger",
          }),
        );
      } else {
        window.location.href = "/applications";
      }
    });
  };

  return (
    <Stack flexDirection="column" justifyContent="center" alignItems="center" height="100vh" rowGap={10}>
      <Stack textAlign="center">
        <Typography level="title-lg">Welcome back</Typography>
        <Typography level="body-md">Please enter your details</Typography>
      </Stack>
      <Sheet color="neutral" variant="outlined" hasBorderRadius={true} sx={{ width: "40vw", textAlign: "center" }}>
        <form onSubmit={formik.handleSubmit}>
          <Stack rowGap={5}>
            <FormControl error={!!formik.errors.email}>
              <FormLabel>Email</FormLabel>
              <Input
                placeholder="Your email address"
                type="text"
                name="email"
                value={formik.values.email ?? ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {!!formik.errors.email && (
                <FormHelperText>
                  <ErrorOutlineIcon sx={{ fontSize: "1rem" }} />
                  {formik.errors.email}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl error={!!formik.errors.password}>
              <FormLabel>Password</FormLabel>
              <Input
                placeholder="Your password"
                type="password"
                name="password"
                value={formik.values.password ?? ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {!!formik.errors.password && (
                <FormHelperText>
                  <ErrorOutlineIcon sx={{ fontSize: "1rem" }} />
                  {formik.errors.password}
                </FormHelperText>
              )}
            </FormControl>
            <Button
              type="submit"
              size="lg"
              variant="primary"
              endDecorator={<LoginIcon />}
              loading={login?.status === "pending"}
            >
              Sign In
            </Button>
          </Stack>
        </form>
      </Sheet>
    </Stack>
  );
};

export { SignIn };
