import { replace } from "lodash";
import numeral from "numeral";

const fCurrency = (number: number) => {
  return numeral(number).format(Number.isInteger(number) ? "$0,0" : "$0,0.00");
};

const fCurrencyDecimal = (number: number) => {
  return numeral(number).format("$0,0.00");
};

const fPercent = (number: number) => {
  return numeral(number / 100).format("0.00%");
};

const fNumber = (number: number) => {
  return numeral(number).format();
};

const fShortenNumber = (number: number) => {
  return replace(numeral(number).format("0.00a"), ".00", "");
};

const fData = (number: number) => {
  return numeral(number).format("0.0 b");
};

export { fCurrency, fCurrencyDecimal, fPercent, fNumber, fShortenNumber, fData };
