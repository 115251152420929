import { Stack, Typography, CircularProgress } from "@financeable-com-au/financeable-ui";

const SubmissionPending = () => {
  return (
    <Stack alignItems="center" rowGap={3}>
      <CircularProgress />
      <Typography component="h2" id="modal-title" level="title-md">
        Uploading Application
      </Typography>
    </Stack>
  );
};

export { SubmissionPending };
