import { useDispatch, useSelector } from "react-redux";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useFormik } from "formik";
import {
  Divider,
  Typography,
  Stack,
  Checkbox,
  Button,
  FormControl,
  FormHelperText,
} from "@financeable-com-au/financeable-ui";
import {
  setSubmissionModalOpen,
  applicationSelector,
  setSubmissionSelectedDocuments,
  // @ts-expect-error: ignoring as it is existing js file
} from "@/store/slices/applicationFormSlice";
import type { ApplicationFormSlice, ApplicationState } from "@/store/slices/types/applicationFormSlice";
import { SubmissionStepper } from "@/components/ApplicationSubmissionModal/SubmissionStepper";
import { useSubmissionSteps } from "@/components/ApplicationSubmissionModal/hooks";

const SelectDocuments = () => {
  // List of documents
  const { documents } = useSelector<ApplicationFormSlice, ApplicationState>(applicationSelector);
  const { proceedSubmissionSteps } = useSubmissionSteps();
  const dispatch = useDispatch();

  const handleCloseSubmissionModal = () => {
    dispatch(setSubmissionModalOpen({ isOpen: false }));
  };

  const formik = useFormik({
    initialValues: {
      documents: [],
    },
    onSubmit: (values) => {
      dispatch(setSubmissionSelectedDocuments({ selectedDocuments: values.documents }));
      proceedSubmissionSteps({ activeStep: "documents" });
    },
    validateOnChange: false,
    validateOnBlur: false,
  });

  return (
    <Stack rowGap={10}>
      <SubmissionStepper activeStep="documents" />
      <form onSubmit={formik.handleSubmit}>
        <Stack rowGap={6}>
          {(documents ?? []).length === 0 ? (
            <Stack rowGap={2}>
              <Typography component="h2" id="modal-title" level="title-md">
                There are no documents to select
              </Typography>
              <Typography id="document-checkboxes">
                Sorry, we couldn't find any documents uploaded for this application.
              </Typography>
            </Stack>
          ) : (
            <>
              <Stack rowGap={2}>
                <Typography component="h2" id="modal-title" level="title-md">
                  Select Documents
                </Typography>
                <Typography id="document-checkboxes">
                  Please select the documents to proceed with your application
                </Typography>
                <Divider />
              </Stack>
              <FormControl error={!!formik.errors.documents}>
                <Stack rowGap={3} role="group" aria-labelledby="document-checkboxes">
                  {(documents ?? []).map((document) => (
                    <Checkbox
                      name="documents"
                      label={document.name}
                      value={document._id}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  ))}
                  {!!formik.errors.documents && (
                    <FormHelperText margin={0}>
                      <ErrorOutlineIcon sx={{ fontSize: "1rem" }} />
                      {formik.errors.documents}
                    </FormHelperText>
                  )}
                </Stack>
              </FormControl>
            </>
          )}
          <Stack direction="row" columnGap={2}>
            <Button variant="secondary" onClick={handleCloseSubmissionModal} fullWidth>
              Cancel
            </Button>
            <Button variant="primary" fullWidth type="submit">
              Proceed
            </Button>
          </Stack>
        </Stack>
      </form>
    </Stack>
  );
};

export { SelectDocuments };
