import { useSelector } from "react-redux";
import type { PillAccent } from "@financeable-com-au/financeable-ui";
import { RepaymentFrequency } from "@/components/LenderProductCard/hooks/useQuoteCalculatorForm";
import { fCurrencyDecimal, fPercent } from "@/utils/formatNumber";
import { FeeFrequency, ApplicationType, InArrears } from "@/store/slices/types/applicationFormSlice";
import type {
  ApplicationFormSlice,
  Asset,
  LoanDetails,
  ApplicationState,
  Fee,
} from "@/store/slices/types/applicationFormSlice";
// @ts-expect-error: ignoring as it is existing js file
import calculateEffectiveRate from "@/utils/calculateEffectiveRate";
import {
  assetSelector,
  loanDetailsSelector,
  applicationSelector,
  // @ts-expect-error: ignoring as it is existing js file
} from "@/store/slices/applicationFormSlice";
import type { LoadingItem, LenderProduct } from "../LenderCardContext/types/LenderProductTypes";
import {
  sortLoadingList,
  getFeesList,
  getLoadingList,
  getTotalFeesByFrequency,
  getDocumentPillColour,
  getLoanAmount,
  getFinanceAmount,
  getWeeklyRepaymentsHavingMonthly,
  calculateQuoteRepayments,
  getRepaymentFrequencyLabel,
} from "../utils";

/**
 * Types.
 */
interface LoadingItemFormatted extends LoadingItem {
  formattedValue: string;
}

interface FeeFormatted extends Fee {
  formattedValue: string;
}

interface Document {
  name: string;
  colour: PillAccent;
}

interface CommonValue {
  value: number | undefined;
  formattedValue: string | undefined;
}

interface UseLenderCardResult {
  repayments: {
    monthly: CommonValue;
    weekly: CommonValue;
  };
  rate: {
    default: CommonValue;
    effective: CommonValue;
    comparison: CommonValue;
  };
  fees: {
    totalMonthly: CommonValue;
    totalUpfront: CommonValue;
    totalFinanced: CommonValue;
    lenders: FeeFormatted[] | undefined;
    application: FeeFormatted[];
    maxOriginationFeeSet: string | undefined;
  };
  loanAmount: CommonValue;
  financeAmount: CommonValue;
  brokerage: {
    percentage: CommonValue;
    amount: CommonValue;
    max: CommonValue;
  };
  documents?: Document[];
  loadingList: LoadingItemFormatted[];
  infoList: string[];
}

const defaultCommonValue: CommonValue = {
  value: undefined,
  formattedValue: "",
};

const defaultResult: UseLenderCardResult = {
  repayments: {
    monthly: defaultCommonValue,
    weekly: defaultCommonValue,
  },
  rate: {
    default: defaultCommonValue,
    effective: defaultCommonValue,
    comparison: defaultCommonValue,
  },
  fees: {
    totalMonthly: defaultCommonValue,
    totalUpfront: defaultCommonValue,
    totalFinanced: defaultCommonValue,
    lenders: [],
    application: [],
    maxOriginationFeeSet: undefined,
  },
  loanAmount: defaultCommonValue,
  financeAmount: defaultCommonValue,
  brokerage: {
    percentage: defaultCommonValue,
    amount: defaultCommonValue,
    max: defaultCommonValue,
  },
  documents: [],
  loadingList: [],
  infoList: [],
};

interface UseLenderCardProps {
  product: LenderProduct;
}

const useLenderCard = ({ product }: UseLenderCardProps): UseLenderCardResult => {
  const { assetValue } = useSelector<ApplicationFormSlice, Asset>(assetSelector);
  const {
    deposit,
    tradeIn,
    payout,
    requestedLoanAmount,
    term,
    balloon,
    originationFee: applicationOriginationFee,
  } = useSelector<ApplicationFormSlice, LoanDetails>(loanDetailsSelector);
  const { applicationType } = useSelector<ApplicationFormSlice, ApplicationState>(applicationSelector);
  const netAssetValue = assetValue - deposit - tradeIn + payout;

  if (!product) {
    return defaultResult;
  }

  const {
    rate,
    fees: lenderFees,
    brokerage,
    brokerageAmount,
    brokerageMax,
    labels,
    loading,
    info,
  } = product;

  const isCommercialApplication = applicationType === ApplicationType.Commercial;
  const defaultInArrears = applicationType === ApplicationType.Commercial ? InArrears.No : InArrears.Yes;
  const isInArrears = defaultInArrears === InArrears.Yes;

  // Fees.
  // If origination fee set in the application is less than the max that
  // has been set by the lender, then use the application's origination fee.
  const fees = lenderFees.map((fee) => {
    if (fee.name.toUpperCase() === "ORIGINATION FEE" && applicationOriginationFee < (fee.value ?? 0)) {
      return { ...fee, value: applicationOriginationFee };
    }
    return fee;
  });
  const feesList = getFeesList(fees);
  const lendersFeesList = getFeesList(lenderFees);
  const maxOriginationFeeSet = lendersFeesList.find(
    (fee) => fee.name.toUpperCase() === "ORIGINATION FEE",
  )?.formattedValue;
  // Monthly fees.
  const totalMonthlyFees = getTotalFeesByFrequency({ fees, frequency: FeeFrequency.Monthly });
  const formattedTotalMonthlyFees = totalMonthlyFees ? fCurrencyDecimal(totalMonthlyFees) : undefined;
  // Upfront fees.
  const totalUpfrontFees = getTotalFeesByFrequency({ fees, frequency: FeeFrequency.Upfront });
  const formattedTotalUpfrontFees = fCurrencyDecimal(totalUpfrontFees);
  // Financed fees.
  const totalFinancedFees = getTotalFeesByFrequency({ fees, frequency: FeeFrequency.Financed });
  const formattedTotalFinancedFees = fCurrencyDecimal(totalFinancedFees);
  // Loan amount.
  const loanAmount = getLoanAmount(applicationType, { totalFinancedFees, netAssetValue, requestedLoanAmount });
  const formattedLoanAmount = fCurrencyDecimal(loanAmount);
  // Finance amount.
  const financeAmount = getFinanceAmount(applicationType, {
    totalFinancedFees,
    netAssetValue,
    brokerageAmount,
    requestedLoanAmount,
  });
  const formattedFinanceAmount = fCurrencyDecimal(financeAmount);
  // Brokerage.
  const formattedBrokerage = fPercent(brokerage ?? 0);
  const formattedBrokerageAmount = fCurrencyDecimal(brokerageAmount);
  const formattedBrokerageMax = fPercent(brokerageMax);
  // Document pill.
  const documents = labels.map((label) => ({
    name: label,
    colour: getDocumentPillColour[label as keyof typeof getDocumentPillColour],
  }));
  // Extras.
  const loadingList = getLoadingList(sortLoadingList(loading?.loadingList));
  const infoList = info;

  // Repayments.
  const repayments = calculateQuoteRepayments({
    financeAmount,
    balloon,
    term,
    rate,
    netAssetValue,
    isInArrears,
  });
  const monthlyRepayments = repayments + totalMonthlyFees;
  const formattedMonthlyRepayments = `${fCurrencyDecimal(monthlyRepayments)}${getRepaymentFrequencyLabel(RepaymentFrequency.Monthly)}`;
  const weeklyRepayments = getWeeklyRepaymentsHavingMonthly({
    monthlyRepaymentsValue: monthlyRepayments,
  });
  const formattedWeeklyRepayments = `${fCurrencyDecimal(weeklyRepayments)}${getRepaymentFrequencyLabel(RepaymentFrequency.Weekly)}`;

  // Rate.
  const ratePercentage = fPercent(rate);
  const balloonAmount = (balloon / 100) * netAssetValue;
  // Effective rate.
  const effectiveRate = calculateEffectiveRate(
    term,
    repayments,
    -(netAssetValue + totalFinancedFees),
    balloonAmount,
    isCommercialApplication ? 1 : 0,
    Number(rate) / 100,
  );
  const effectiveRateValue = Number(effectiveRate.toFixed(2));
  const formattedEffectiveRate = fPercent(effectiveRateValue);
  // Present value.
  const presentValue = balloonAmount / Math.pow(1 + Number(rate), term);
  // True loan amount.
  const trueLoanAmount = financeAmount - presentValue;
  // Comparison rate.
  const comparisonRate = calculateEffectiveRate(
    term,
    repayments,
    -(trueLoanAmount - totalUpfrontFees - (isCommercialApplication ? brokerageAmount : 0)),
    balloonAmount,
    isCommercialApplication ? 1 : 0,
    Number(rate) / 100,
  );
  const formattedComparisonRate = fPercent(Number(comparisonRate.toFixed(2)));

  return {
    repayments: {
      monthly: {
        value: monthlyRepayments,
        formattedValue: formattedMonthlyRepayments,
      },
      weekly: {
        value: weeklyRepayments,
        formattedValue: formattedWeeklyRepayments,
      },
    },
    rate: {
      default: {
        value: rate,
        formattedValue: ratePercentage,
      },
      effective: {
        value: effectiveRateValue,
        formattedValue: formattedEffectiveRate,
      },
      comparison: {
        value: comparisonRate,
        formattedValue: formattedComparisonRate,
      },
    },
    fees: {
      totalMonthly: {
        value: totalMonthlyFees,
        formattedValue: formattedTotalMonthlyFees,
      },
      totalUpfront: {
        value: totalUpfrontFees,
        formattedValue: formattedTotalUpfrontFees,
      },
      totalFinanced: {
        value: totalFinancedFees,
        formattedValue: formattedTotalFinancedFees,
      },
      lenders: lendersFeesList,
      application: feesList,
      maxOriginationFeeSet,
    },
    loanAmount: {
      value: loanAmount,
      formattedValue: formattedLoanAmount,
    },
    financeAmount: {
      value: financeAmount,
      formattedValue: formattedFinanceAmount,
    },
    brokerage: {
      percentage: {
        value: brokerage,
        formattedValue: formattedBrokerage,
      },
      amount: {
        value: brokerageAmount,
        formattedValue: formattedBrokerageAmount,
      },
      max: {
        value: brokerageMax,
        formattedValue: formattedBrokerageMax,
      },
    },
    documents,
    loadingList,
    infoList,
  };
};

export { useLenderCard, defaultResult as defaultLenderCard };
export type { FeeFormatted, UseLenderCardResult, CommonValue };
