import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, Typography } from '@mui/material'
import * as Yup from "yup";
// eslint-disable-next-line import/no-extraneous-dependencies
import Finance from 'tvm-financejs';
import { useSnackbar } from 'notistack';
import CalFinance from './CalFinance'
import CalCostOfGoods from './CalCostOfGoods'
import CalRevenue from './CalRevenue'
import CalBrokerage from './CalBrokerage'
import CalAmountFinanced from './CalAmountFinanced'
import CalBalloon from './CalBalloon'
import CalRate from './CalRate'
import CalRepaymentSchedule from './CalRepaymentSchedule'
import CalSingleRepayment from './CalSingleRepayment'
import CalAllRepayment from './CalAllRepayment'
import CalTermCharges from './CalTermCharges'
import CalDeposit from './CalDeposit';
import { ERRORS_MESSAGE, RepaymentStructure, StateList, calculationMethods, defaultRevenueFeesList, fieldRequiredMessage, getApplicationTab, getApplicationTypeList, validFrequencyList } from '../../constants';
import {
  loanDetailsSelector,
  setCalculatorFieldsError,
  saveValue,
  userDetailsSelector,
  assetSelector,
  getApplication,
  updateLoanDetails,
  // saveAsset,
  updateAsset,
  applicationSelector,
  updateAppStatusToWorkShop
} from '../../store/slices/applicationFormSlice'; // saveLoanDetails
import regex from '../../utils/regex'
import { getAllLenderList } from '../../utils/getAllLenderList';
import calculateRepayments from '../../utils/calculateRepayments';
import getMonthlyRate from '../../utils/getMonthlyRate';
import { StartApplicationDialog } from '../Application/StartApplication';


const defaultState = {
  lender: "",
  // state: "",
  cash: 0,
  balloon: 0,
  tradeIn: 0,
  // fees: [],
  fees: defaultRevenueFeesList,
  brokerage: 0,
  brokerageAmount: 0,
  brokerageFinanced: "",
  netAssetValue: 0,
  // isLuxury: "",
  // fuelEfficiency: "",
  // luxuryGst: 0,
  additionalFees: 0,
  additionalTaxes: 0,
  rate: 0,
  inArrears: "",
  repayments: 0,
  repaymentFrequency: "",
  repaymentStructure: "",
  term: null,
  amortStartDate: null,
  // Custom calculation fields
  costOfGoodTotal: 0,
  assetGst: 0
}


const Calculator = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { applicationId } = useParams();
  const { calculatorFieldsErrors, startApplicationBtnLoader } = useSelector(userDetailsSelector); // gettingCalculator
  const loanDetails = useSelector(loanDetailsSelector);
  const asset = useSelector(assetSelector);
  const application = useSelector(applicationSelector);
  const lenderList = getAllLenderList({ isKeyOnly: true })

  const [allValues, setAllValue] = useState(defaultState);
  const [openDialog, setOpenDialog] = useState(false);


  useEffect(() => {
    if (loanDetails?._id && asset?._id) {
      setAllValue({
        ...allValues,
        _id: loanDetails?._id,
        lender: loanDetails?.lender,
        // state: loanDetails?.state,
        cash: loanDetails?.cash,
        balloon: loanDetails?.balloon,
        tradeIn: loanDetails?.tradeIn,
        fees: loanDetails?.fees || [],
        brokerage: loanDetails?.brokerage,
        brokerageAmount: loanDetails?.brokerageAmount,
        brokerageFinanced: loanDetails?.brokerageFinanced || "No",
        rate: loanDetails?.rate,
        inArrears: loanDetails?.inArrears || "No",
        repayments: loanDetails?.repayments,
        repaymentFrequency: loanDetails?.repaymentFrequency,
        repaymentStructure: loanDetails?.repaymentStructure,
        term: loanDetails?.term || 0,
        amortStartDate: loanDetails?.amortStartDate || null,
        costOfGoodTotal: handle.getTotalCostOfGoods(asset?.netAssetValue).costOfGoodTotal,
        assetGst: handle.getTotalCostOfGoods(asset?.netAssetValue).assetGst,
        netAssetValue: asset?.netAssetValue,
        isLuxury: asset?.isLuxury || "No",
        fuelEfficiency: asset?.fuelEfficiency || "No",
        luxuryGst: asset?.luxuryGst,
        additionalFees: asset?.additionalFees,
        additionalTaxes: asset?.additionalTaxes,
      })
    } else {
      setAllValue(defaultState)
    }
  }, [loanDetails, asset])

  const validationSchema = Yup.object({
    calculationMethod: Yup.string()
      .oneOf(calculationMethods, "Please select valid method")
      .required("Method is required"),
    lender: Yup.string()
      .oneOf(lenderList, "Please select valid lender")
      .required("Lender is required"),
    state: Yup.string()
      .oneOf(StateList, "Please select valid state")
      .required("State is required"),
    itcLevel: Yup.string()
      .required("ITC Level is required")
      .matches(regex.percentageRegex, "Please enter valid percentage.")
      .matches(regex.percentageRegex, {
        message: "Percentage should be upto 100%.",
      }),
    costOfGoodTotal: Yup.string()
      .optional()
      .matches(regex.allowNumberWithZero, "Only numbers and decimal points allowed.")
      .matches(regex.allowNumberAndDecimal, {
        message: "Number must be to up 7 digit and up to 2 decimal places",
      })
      .min(3, "Minimum of 3 digits")
      .max(10, "Maximum of 7 digits"),
    netAssetValue: Yup.string()
      .required(fieldRequiredMessage.netAssetValue)
      .matches(regex.allowNumberWithZero, "Only numbers and decimal points allowed.")
      .matches(regex.allowNumberAndDecimal, {
        message: "Number must be to up 7 digit and up to 2 decimal places",
      })
      .min(3, "Minimum of 3 digits")
      .max(10, "Maximum of 7 digits"),
    luxuryGst: Yup.string()
      .required(fieldRequiredMessage.luxuryGst)
      .matches(regex.allowNumberWithZero, "Only numbers and decimal points allowed.")
      .matches(regex.allowNumberAndDecimal, {
        message: "Number must be to up 7 digit and up to 2 decimal places",
      })
      // .min(2, "Minimum of 2 digits")
      .max(10, "Maximum of 7 digits"),
    additionalFees: Yup.string()
      .optional()
      .matches(regex.allowNumberWithZero, "Only numbers and decimal points allowed.")
      .matches(regex.allowNumberAndDecimal, {
        message: "Number must be to up 7 digit and up to 2 decimal places",
      })
      // .min(2, "Minimum of 2 digits")
      .max(10, "Maximum of 7 digits"),
    additionalTaxes: Yup.string()
      .optional()
      .matches(regex.allowNumberWithZero, "Only numbers and decimal points allowed.")
      .matches(regex.allowNumberAndDecimal, {
        message: "Number must be to up 7 digit and up to 2 decimal places",
      })
      // .min(2, "Minimum of 2 digits")
      .max(10, "Maximum of 7 digits"),
    cash: Yup.string()
      .optional()
      .matches(regex.allowNumberWithZero, "Only numbers and decimal points allowed.")
      .matches(regex.allowNumberAndDecimal, {
        message: "Number must be to up 7 digit and up to 2 decimal places",
      })
      .max(10, "Maximum of 7 digits"),

    tradeIn: Yup.string()
      .optional()
      .matches(regex.allowNumberWithZero, "Only numbers and decimal points allowed.")
      .matches(regex.allowNumberAndDecimal, {
        message: "Number must be to up 7 digit and up to 2 decimal places",
      })
      .max(10, "Maximum of 7 digits"),
    balloon: Yup.string()
      .required("Balloon is required")
      .matches(regex.percentageRegex, "Please enter valid percentage.")
      .matches(regex.percentageRegex, {
        message: "Percentage should be upto 100%.",
      }),
    rate: Yup.string()
      .required("Rate is required")
      .matches(regex.percentageRegex, "Please enter valid percentage.")
      .matches(regex.percentageRegex, {
        message: "Percentage should be upto 100%.",
      }),
    // effectiveRate: Yup.string()
    //   .required("Effective rate is required")
    //   .matches(regex.percentageRegex, "Please enter valid percentage.")
    //   .matches(regex.percentageRegex, {
    //     message: "Percentage should be upto 100%.",
    //   }),
    brokerage: Yup.string()
      .required("Brokerage is required")
      .matches(regex.percentageRegex, "Please enter valid percentage.")
      .matches(regex.percentageRegex, {
        message: "Percentage should be upto 100%.",
      }),
    brokerageAmount: Yup.string()
      .optional()
      .matches(regex.allowNumberWithZero, "Only numbers and decimal points allowed.")
      .matches(regex.allowNumberAndDecimal, {
        message: "Number must be to up 7 digit and up to 2 decimal places",
      })
      .max(10, "Maximum of 7 digits"),
    repayments: Yup.string()
      .optional()
      .matches(regex.allowNumberWithZero, "Only numbers and decimal points allowed.")
      .matches(regex.allowNumberAndDecimal, {
        message: "Number must be to up 7 digit and up to 2 decimal places",
      })
      .max(10, "Maximum of 7 digits"),
    repaymentFrequency: Yup.string()
      .oneOf(validFrequencyList, "Please select valid period")
      .required("Period is required"),
    repaymentStructure: Yup.string()
      .oneOf(RepaymentStructure, "Please select valid structure")
      .required("Structure is required"),
    term: Yup.string()
      .optional()
      .matches(regex.allowNumberWithZero, "Only numbers and decimal points allowed.")
      .max(2, "Maximum of 2 digits"),
    amortStartDate: Yup.string()
      .optional()
      .typeError("Please enter a valid date")
      .matches(regex.customerDateOfBirthRegexOptional, "Invalid date, please enter a valid date")
      .max(new Date(), "Date is not select in the future"),
    termsCharges: Yup.string()
      .optional()
      .matches(regex.allowNumberWithZero, "Only numbers and decimal points allowed.")
      .matches(regex.allowNumberAndDecimal, {
        message: "Number must be to up 7 digit and up to 2 decimal places",
      })
      .max(10, "Maximum of 7 digits"),
  });

  const validateField = async ({ fieldName, value, fieldObj }) => {
    try {
      await validationSchema.validateAt(fieldName, { [fieldName]: value });
      dispatch(setCalculatorFieldsError({ ...calculatorFieldsErrors, [fieldObj]: { ...calculatorFieldsErrors[fieldObj], [fieldName]: "" } }))
    } catch (error) {
      dispatch(setCalculatorFieldsError({ ...calculatorFieldsErrors, [fieldObj]: { ...calculatorFieldsErrors[fieldObj], [fieldName]: error.message } }))
    }
  };

  async function handleApplicationLoad() {
    setAllValue(defaultState)
    dispatch(saveValue({ gettingCalculator: true }))
    if (applicationId) await dispatch(getApplication(applicationId)).unwrap();
  }

  const handle = {
    loanDetailsFn: (value) => {
      const keys = Object.keys(value);
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (loanDetails[key] !== value[key]) {
          // dispatch(saveLoanDetails(value));
          // setAllValue({ ...allValues, ...value });
          dispatch(updateLoanDetails({ _id: loanDetails._id, ...value }));
        }
      }
    },
    updateAsset: async (value) => {
      if (asset?._id) {
        const keys = Object.keys(value);
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          if (asset[key] !== value[key]) {
            // dispatch(saveAsset(value));
            dispatch(updateAsset({ _id: asset._id, ...value }));
            return;
          }
        }
      }
    },
    getTotalCostOfGoods: (netAssetValue) => {
      if (!netAssetValue) {
        return 0
      }

      const gstValue = netAssetValue * 0.10; // GST is 10% of the net amount
      const totalValue = handle.formatValueToDecimal(netAssetValue) + handle.formatValueToDecimal(gstValue);
      
      return {
        costOfGoodTotal: Number(totalValue) || 0,
        assetGst: gstValue || 0,
      }
    },
    startApplicationHandler: (applicationType) => {

      const updateStatusPayload = {
        applicationId,
        applicationType: applicationType,
        loanDetailsId: loanDetails?._id
      }

      dispatch(updateAppStatusToWorkShop(updateStatusPayload)).then((res) => {
        if (res?.payload?.data?.data) {
          // Navigate to application route
          navigate(`/application/${applicationId}`);
        } else {
          enqueueSnackbar(res?.payload?.status_message || ERRORS_MESSAGE.fetchErrorMsg, {
            variant: "error",
            autoHideDuration: 5000,
          });
        }
      });
    },
    formatValueToDecimal: (value) => {
      const result = value > 0 && !isNaN(value) && isFinite(value)
        ? parseFloat((value)?.toFixed(2))
        : 0;

      return result
    },
    formatNumberValue: (value) => {
      const result = value > 0 && !isNaN(value) && isFinite(value)
        ? value
        : 0;

      return result
    },
    reCalculateValue: () => {
      const { netAssetValue = 0, cash = 0, tradeIn = 0, additionalTaxes = 0, additionalFees = 0, fees = [],
        balloon = 0, brokerageFinanced = "Yes", brokerage = 0, brokerageAmount = 0, rate = 0, term = 0, inArrears = "" } = allValues
      let { repayments = 0 } = allValues

      // Common calculations
      // ======================================================================
      const netAssetTotal = +netAssetValue > 0 ? ((+netAssetValue * 0.10) + +netAssetValue) : 0
      const netTotalValue = +netAssetTotal - (+cash * 1) - (+tradeIn * 1);
      const financedFees = fees?.length > 0 ? +fees?.find(fee => fee.name === "financedFee")?.value : 0
      const accountFee = fees?.length > 0 ? +fees?.find(fee => fee.name === "accountFee")?.value : 0
      const financedAmount = +additionalFees + +additionalTaxes + netTotalValue + financedFees // Also know as excludeBrokerage

      // ======================================================================

      // Cost of Goods ==========================>>>>>>>>>>>
      // assetGst is 10% of net
      const assetGst = +netAssetValue > 0 ? +(netAssetValue * 0.10) : 0
      // Total is addition of netAssetValue and assetGST
      const costOfGoodTotal = +netAssetValue > 0 ? +netAssetValue + assetGst : 0

      // Deposit ==========================>>>>>>>>>>>
      const depositTotal = +cash + (+tradeIn)
      const newBrokerage = +netAssetValue > 0 ? brokerage : 0
      const newBalloon = +netAssetValue > 0 ? balloon : 0

      const newBrokerageAmount = financedFees ? (financedAmount * newBrokerage) / 100 : brokerageAmount

      // Brokerage ==========================>>>>>>>>>>>
      const brokerageGST = ((+newBrokerageAmount * newBrokerage) / 100).toFixed(2)

      // Balloon ==========================>>>>>>>>>>>
      const balloonAmount = (financedAmount * newBalloon) / 100
      const finance = new Finance()

      // Amount financed ==========================>>>>>>>>>>>
      const financedWithBrokerage = financedAmount + (brokerageFinanced === "Yes" ? +newBrokerageAmount : 0)

      // Repayments ==========================>>>>>>>>>>>
      if (rate) {
        const monthlyRate = getMonthlyRate(+rate) || 0;
        repayments = parseFloat(calculateRepayments(
          monthlyRate,
          term,
          -financedWithBrokerage,
          balloonAmount,
          inArrears === "Yes" ? 0 : 1,
        )?.toFixed(2));
      }

      // Rates ==========================>>>>>>>>>>>
      const effectiveRateRaw = finance.RATE(
        term,
        repayments,
        -financedAmount,
        balloonAmount,
        inArrears === "Yes" ? 0 : 1,
        rate / 100
      );

      // const effectiveRate = effectiveRateRaw * 12 * 100
      const effectiveRate = effectiveRateRaw && !isNaN(effectiveRateRaw) && isFinite(effectiveRateRaw)
        ? effectiveRateRaw * 12 * 100
        : 0;

      // Single Repayment Schedule ==========================>>>>>>>>>>>
      const singleRepaymentTotal = +repayments + accountFee + 0

      // All Repayment Schedule ==========================>>>>>>>>>>>
      const allRepayments = repayments * term
      const totalExcRepayments = (allRepayments) + (accountFee * term)
      const totalINCRepayments = totalExcRepayments + balloonAmount

      // terms charges ==========================>>>>>>>>>>>
      const termsCharges = (allRepayments) + balloonAmount - financedWithBrokerage // (inc residual - amount financed total)
      setAllValue({
        ...allValues,
        rate: rate <= 0 ? 0 : rate,
        // brokerageAmount: newBrokerageAmount <= 0 ? 0 : newBrokerageAmount,
        brokerage: handle.formatNumberValue(newBrokerage),
        brokerageAmount: handle.formatNumberValue(newBrokerageAmount),
        financedAmount: handle.formatNumberValue(financedAmount),
        financedFees: handle.formatNumberValue(financedFees),
        costOfGoodTotal: costOfGoodTotal <= 0 ? "" : parseFloat(costOfGoodTotal),
        depositTotal: handle.formatNumberValue(depositTotal),
        financedWithBrokerage: handle.formatNumberValue(financedWithBrokerage),
        brokerageGST: handle.formatNumberValue(brokerageGST),
        balloon: handle.formatNumberValue(newBalloon),
        balloonAmount: handle.formatNumberValue(balloonAmount),
        effectiveRate: handle.formatNumberValue(effectiveRate),
        accountFee: handle.formatNumberValue(accountFee),
        singleRepaymentTotal: handle.formatNumberValue(singleRepaymentTotal),
        repayments: repayments <= 0 ? "" : repayments,
        allRepayments: handle.formatNumberValue(allRepayments),
        totalExcRepayments: handle.formatNumberValue(totalExcRepayments),
        totalINCRepayments: handle.formatNumberValue(totalINCRepayments),
        termsCharges: handle.formatNumberValue(termsCharges),
        // fees: fees?.length <= 0 ? defaultRevenueFeesList : fees,
      })
    }
  }

  useEffect(() => {
    handleApplicationLoad();
  }, [applicationId]);

  useEffect(() => {
    handle.reCalculateValue();
  }, [
    allValues?.brokerageFinanced,
    allValues?.brokerage,
    allValues?.brokerageAmount,
    allValues?.netAssetValue,
    allValues?.cash,
    allValues?.term,
    allValues?.tradeIn,
    allValues?.balloon,
    allValues?.balloonAmount,
    allValues?.rate,
    allValues?.repayments,
    allValues?.inArrears,
    allValues?.lender,
    allValues?.repaymentFrequency,
    allValues?.repaymentStructure,
    allValues?.fees,
  ]);

  return (
    <Grid
      container
      item
      xs={12}
      sm={12}
      md={12}
      mb={"30px"}
      spacing={3}
      direction="row"
    // sx={{ borderBottom: "1px solid rgba(0,0,0,0.12)" }}
    >
      <Grid
        sm={12}
        item
        style={{
          margin: "5px 0 0 0",
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <Typography
          variant="h2"
          style={{
            fontSize: "22px",
            fontWeight: 600
          }}
        >
          {'Calculator' + (application?.humanId && ` - ${application?.humanId}`)}
        </Typography>

        <Button
          color="primary"
          variant="contained"
          size="small"
          // onClick={handle.startApplicationHandler}
          onClick={async () => {
            setOpenDialog(true);
          }}
          disabled={startApplicationBtnLoader}
        >
          {startApplicationBtnLoader ? 'Starting...' : 'Start Application'}
        </Button>
      </Grid >

      <Grid item xs={4} >
        <CalFinance
          calculatorFieldsErrors={calculatorFieldsErrors}
          validateField={validateField}
          saveLoanDetails={handle.loanDetailsFn}
          allValues={allValues}
          lenderList={lenderList}
          setAllValue={setAllValue}
        />
        <CalCostOfGoods
          calculatorFieldsErrors={calculatorFieldsErrors}
          validateField={validateField}
          asset={asset}
          saveAssetDetails={handle.updateAsset}
          allValues={allValues}
          setAllValue={setAllValue}
        />
        <CalDeposit
          calculatorFieldsErrors={calculatorFieldsErrors}
          validateField={validateField}
          saveLoanDetails={handle.loanDetailsFn}
          allValues={allValues}
          setAllValue={setAllValue}
        />
        <CalBalloon
          calculatorFieldsErrors={calculatorFieldsErrors}
          validateField={validateField}
          saveLoanDetails={handle.loanDetailsFn}
          allValues={allValues}
          setAllValue={setAllValue}
        />
        <CalRate
          calculatorFieldsErrors={calculatorFieldsErrors}
          validateField={validateField}
          saveLoanDetails={handle.loanDetailsFn}
          allValues={allValues}
          setAllValue={setAllValue}
        />

      </Grid>
      <Grid item xs={4} >
        <CalRevenue
          calculatorFieldsErrors={calculatorFieldsErrors}
          validateField={validateField}
          saveLoanDetails={handle.loanDetailsFn}
          allValues={allValues}
          setAllValue={setAllValue}
        />
        <CalBrokerage
          calculatorFieldsErrors={calculatorFieldsErrors}
          validateField={validateField}
          saveLoanDetails={handle.loanDetailsFn}
          allValues={allValues}
          setAllValue={setAllValue}
        />
        <CalAmountFinanced
          allValues={allValues}
        />
      </Grid>

      <Grid item xs={4} >
        <CalRepaymentSchedule
          calculatorFieldsErrors={calculatorFieldsErrors}
          validateField={validateField}
          saveLoanDetails={handle.loanDetailsFn}
          allValues={allValues}
          setAllValue={setAllValue}
        />
        <CalSingleRepayment
          calculatorFieldsErrors={calculatorFieldsErrors}
          validateField={validateField}
          saveLoanDetails={handle.loanDetailsFn}
          allValues={allValues}
          setAllValue={setAllValue}
        />
        <CalAllRepayment allValues={allValues} />
        <CalTermCharges allValues={allValues} />
      </Grid>

      <StartApplicationDialog
        open={openDialog}
        handleClose={handle.handleClose}
        // startApplication={handle.startApplication}
        startApplication={handle.startApplicationHandler}
        applicationTypeList={getApplicationTypeList[getApplicationTab.calculator]}
      />
    </Grid>
  )
}

export default Calculator