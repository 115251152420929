/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Autocomplete,
  Divider,
  CircularProgress,
  Grid,
  TextField,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import * as Yup from "yup";
import { userTypeOptions, userType } from "../../../constants";
import { updateUser, updateUserOrg, updateUserSetting, userSelector } from "../../../store/slices/userSlice";
import regex from "../../../utils/regex";
import ConfirmModal from "../../../components/customComponents/ConfirmModal";

const UserDetails = () => {
  const dispatch = useDispatch();
  const userState = useSelector(userSelector);
  const { _id, isServicePaid, service_invoice_paid_by } = userState;
  const [isOpen, setIsOpen] = useState(false);
  const [userSetting, setUserSetting] = useState(service_invoice_paid_by || isServicePaid);
  const [fieldErrors, setFieldErrors] = useState({
    firstName: "",
    userType: "",
    lastName: "",
    userMobile: "",
    crn: "",
    // organisationACN: "",
    australianCreditLicenceNumber: "",
    organisationAddress: "",
    organisationPhoneNumber: "",
    organisationEmail: "",
  });

  const [userDetailState, setUserDetailState] = useState(null);

  useEffect(() => {
    if (service_invoice_paid_by) {
      setUserSetting(service_invoice_paid_by);
    }
  }, [service_invoice_paid_by]);

  useEffect(() => {
    if (userState?._id) {
      setUserDetailState({
        firstName: userState?.firstName || "",
        userType: userState?.userType || "",
        lastName: userState?.lastName || "",
        userEmail: userState?.email?.address || "",
        userMobile: userState?.phoneNumber?.number || "",
        crn: userState?.crn || "",
        organisationName: userState?.organisation?.name || "",
        organisationABN: userState?.organisation?.abn || "",
        organisationACN: userState?.organisation?.acn || "",
        australianCreditLicenceNumber: userState?.organisation?.acl || "",
        organisationAddress: userState?.organisation?.address || "",
        // organisationPhoneNumber: userState?.organisation?.phoneNumber || "",
        organisationEmail: userState?.organisation?.email || "",
      });
    }
  }, [userState]);

  const validationSchema = Yup.object({
    firstName: Yup.string().optional().matches(regex.name, "Only string allowed"),
    lastName: Yup.string().optional().matches(regex.name, "Only string allowed"),
    userType: Yup.string().optional().nullable().matches(regex.stringRegex, "Only string allowed"),
    userMobile: Yup.string().optional().matches(regex.phoneNumber, "Please enter mobile number"),
    crn: Yup.string().optional().matches(regex.allowOnlyNumber, "Please enter credit representative number"),
    // organisationACN: Yup.string().optional().matches(regex.allowOnlyNumber, "Please enter valid acn number"),
    australianCreditLicenceNumber: Yup.string()
      .optional()
      .matches(regex.allowOnlyNumber, "Please enter australian credit licence number"),
    organisationAddress: Yup.string().optional().matches(regex.addressRegex, "Please enter organisation address"),
    organisationPhoneNumber: Yup.string()
      .optional()
      .matches(regex.phoneNumber, "Please enter organisation phone number"),
    organisationEmail: Yup.string().optional().matches(regex.emailOptional, "Please enter organisation email"),
  });

  const validateField = async (fieldName, value) => {
    try {
      await validationSchema.validateAt(fieldName, { [fieldName]: value });
      setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
    } catch (error) {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: error.message,
      }));
    }
  };

  const handle = {
    PaidBy: (event) => {
      setUserSetting(event.target.value);
      userType?.owner === userState?.userType
        ? setIsOpen({
            isOpen: true,
          })
        : dispatch(updateUserSetting({ serviceInvoicePaidBy: event.target.value, isUpdateAllUserSettings: false }));
    },
    onChange: async (value, name) => {
      let valid = true;

      if (!valid) {
        await validateField(name, value);
        return;
      } else {
        setUserDetailState({
          ...userDetailState,
          [name]: value,
        });
        await validateField(name, value);
      }
    },
    blurFn: (value, name) => {
      try {
        let oldVal = "";
        if (name === "userEmail") {
          oldVal = userState?.email?.address;
        } else if (name === "userMobile") {
          oldVal = userState?.phoneNumber?.number;
        } else if (name === "crn") {
          oldVal = userState?.crn;
        } else if (name === "organisationName") {
          oldVal = userState?.organisation?.name;
        } else if (name === "organisationABN") {
          oldVal = userState?.organisation?.abn;
        } else if (name === "organisationACN") {
          oldVal = userState?.organisation?.acn;
        } else if (name === "australianCreditLicenceNumber") {
          oldVal = userState?.organisation?.acl;
        } else if (name === "organisationAddress") {
          oldVal = userState?.organisation?.address;
        } else if (name === "organisationEmail") {
          oldVal = userState?.organisation?.email;
        } else {
          oldVal = userState[name];
        }

        if (oldVal === value) {
          return;
        }

        if (fieldErrors[name] === "" || name) {
          setUserDetailState({
            ...userDetailState,
            [name]: value,
          });
          if (name?.includes("organisation") || name?.includes("australianCreditLicenceNumber")) {
            let newKey = name;
            if (name === "australianCreditLicenceNumber") {
              newKey = "acl";
            } else if (name === "organisationAddress") {
              newKey = "address";
            } else if (name === "organisationEmail") {
              newKey = "email";
            }

            handle.updateOrgDetails({ [newKey]: value });
          } else {
            handle.updateProfile({ [name]: value });
          }
        }
      } catch (error) {
        const newErrors = {};
        error?.inner?.forEach((validationError) => {
          newErrors[validationError.path] = validationError.message;
        });
        setFieldErrors(newErrors);
      }
    },
    updateProfile: (value) => {
      if (_id && value !== "") {
        dispatch(
          updateUser({
            userId: _id,
            ...(value.crn ? { crn: value.crn } : { ...value }),
          }),
        );
      }
    },
    updateOrgDetails: (value) => {
      if (userState?.organisation?._id) {
        dispatch(
          updateUserOrg({
            orgId: userState.organisation._id,
            ...value,
          }),
        );
      }
    },
    updateInvoicePaidBy: (data) => {
      dispatch(updateUserSetting({ serviceInvoicePaidBy: userSetting, isUpdateAllUserSettings: data.isSave }));
    },
  };

  return (
    <>
      <Grid container spacing={1.5}>
        {userState?._id ? (
          <>
            <Grid item xs={8}>
              <Typography variant="h4" style={{ margin: "0 0 5px 0" }}>
                User Details
              </Typography>
            </Grid>

            <Grid item xs={8}>
              <TextField
                label="First name"
                variant="filled"
                type="text"
                value={userDetailState?.firstName}
                error={fieldErrors?.firstName}
                helperText={fieldErrors?.firstName}
                onChange={(event) => handle.onChange(event.target.value, "firstName")}
                onBlur={(event) => handle.blurFn(event.target.value, "firstName")}
                size="small"
                style={{ margin: "0 0 5px 0" }}
                fullWidth
              />
            </Grid>

            <Grid item xs={8}>
              <TextField
                label="Last name"
                variant="filled"
                type="text"
                value={userDetailState?.lastName}
                error={fieldErrors?.lastName}
                helperText={fieldErrors?.lastName}
                onChange={(event) => handle.onChange(event.target.value, "lastName")}
                onBlur={(event) => handle.blurFn(event.target.value, "lastName")}
                size="small"
                style={{ margin: "0 0 5px 0" }}
                fullWidth
              />
            </Grid>

            <Grid item xs={8}>
              <TextField
                label="Email address"
                variant="filled"
                type="text"
                value={userDetailState?.userEmail}
                disabled
                size="small"
                style={{ margin: "0 0 5px 0" }}
                fullWidth
              />
            </Grid>

            <Grid item xs={8}>
              <TextField
                label="Mobile number"
                variant="filled"
                type="text"
                value={userDetailState?.userMobile}
                error={fieldErrors?.userMobile}
                helperText={fieldErrors?.userMobile}
                onChange={(event) => handle.onChange(event.target.value, "userMobile")}
                onBlur={(event) => handle.blurFn(event.target.value, "userMobile")}
                size="small"
                style={{ margin: "0 0 5px 0" }}
                fullWidth
              />
            </Grid>

            <Grid item xs={8}>
              <TextField
                label="Credit Representative Number"
                variant="filled"
                type="text"
                value={userDetailState?.crn}
                error={fieldErrors?.crn}
                helperText={fieldErrors?.crn}
                onChange={(event) => handle.onChange(event.target.value, "crn")}
                onBlur={(event) => handle.blurFn(event.target.value, "crn")}
                size="small"
                style={{ margin: "0 0 5px 0" }}
                fullWidth
              />
            </Grid>

            <Grid item xs={8}>
              <Autocomplete
                options={userTypeOptions}
                variant="filled"
                size="small"
                disabled
                fullWidth
                value={userDetailState?.userType || ""}
                onChange={(event, newValue) => handle.onChange(newValue, "userType")}
                onBlur={(event) => handle.blurFn(event.target.value, "userType")}
                style={{ margin: "0 0 5px 0" }}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    label="User type"
                    variant="filled"
                    error={fieldErrors?.userType}
                    helperText={fieldErrors?.userType}
                  />
                )}
              />
            </Grid>

            <Grid item xs={8} sx={{ marginBottom: "20px" }}>
              <FormControl fullWidth>
                <InputLabel id="invoice-paid-by">Invoice paid by</InputLabel>
                <Select
                  variant="filled"
                  size="small"
                  id="invoice-paid-by"
                  value={userSetting || ""}
                  label="Invoice paid by"
                  onChange={handle.PaidBy}
                >
                  {["ORGANISATION", "USER"].map((item) => (
                    <MenuItem value={item} key={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={8}>
              <Divider style={{ margin: "20px 0 10px 0" }} />
            </Grid>

            <Grid item xs={8}>
              <Typography variant="h5" style={{ margin: "0 0 5px 0" }}>
                Organisation details
              </Typography>
            </Grid>

            <Grid item xs={8}>
              <TextField
                label="Organisation name"
                variant="filled"
                value={userDetailState?.organisationName}
                type="text"
                disabled
                size="small"
                style={{ margin: "0 0 5px 0" }}
                fullWidth
              />
            </Grid>

            <Grid item xs={8}>
              <TextField
                label="ABN"
                variant="filled"
                value={userDetailState?.organisationABN}
                type="text"
                disabled
                size="small"
                style={{ margin: "0 0 5px 0" }}
                fullWidth
              />
            </Grid>

            <Grid item xs={8}>
              <TextField
                label="ACN"
                variant="filled"
                value={userDetailState?.organisationACN}
                type="text"
                disabled
                size="small"
                style={{ margin: "0 0 5px 0" }}
                fullWidth
                // onChange={(event) => handle.onChange(event.target.value, "organisationACN")}
                // onBlur={(event) => handle.blurFn(event.target.value, "organisationACN")}
                // error={fieldErrors?.organisationACN}
                // helperText={fieldErrors?.organisationACN}
              />
            </Grid>

            <Grid item xs={8}>
              <TextField
                label="Australian Credit Licence Number"
                variant="filled"
                type="text"
                value={userDetailState?.australianCreditLicenceNumber}
                error={fieldErrors?.australianCreditLicenceNumber}
                helperText={fieldErrors?.australianCreditLicenceNumber}
                onChange={(event) => handle.onChange(event.target.value, "australianCreditLicenceNumber")}
                onBlur={(event) => handle.blurFn(event.target.value, "australianCreditLicenceNumber")}
                size="small"
                style={{ margin: "0 0 5px 0" }}
                fullWidth
              />
            </Grid>

            <Grid item xs={8}>
              <TextField
                label="Address"
                variant="filled"
                type="text"
                value={userDetailState?.organisationAddress}
                error={fieldErrors?.organisationAddress}
                helperText={fieldErrors?.organisationAddress}
                onChange={(event) => handle.onChange(event.target.value, "organisationAddress")}
                onBlur={(event) => handle.blurFn(event.target.value, "organisationAddress")}
                size="small"
                style={{ margin: "0 0 5px 0" }}
                fullWidth
              />
            </Grid>

            {/* <Grid item xs={8}>
        <TextField
          label="Phone number"
          variant="filled"
          type="text"
          value={userDetailState?.organisationPhoneNumber}
          error={fieldErrors?.organisationPhoneNumber}
          helperText={fieldErrors?.organisationPhoneNumber}
          onChange={(event) =>
            handle.onChange(event.target.value, "organisationPhoneNumber")
          }
          onBlur={(event) =>
            handle.blurFn(event.target.value, "organisationPhoneNumber")
          }
          size="small"
          style={{ margin: "0 0 5px 0" }}
          fullWidth
        />
      </Grid> */}

            <Grid item xs={8}>
              <TextField
                label="Email"
                variant="filled"
                type="text"
                value={userDetailState?.organisationEmail}
                error={fieldErrors?.organisationEmail}
                helperText={fieldErrors?.organisationEmail}
                onChange={(event) => handle.onChange(event.target.value, "organisationEmail")}
                onBlur={(event) => handle.blurFn(event.target.value, "organisationEmail")}
                size="small"
                style={{ margin: "0 0 5px 0" }}
                fullWidth
              />
            </Grid>
          </>
        ) : (
          <Box
            sx={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              width: "100%",
              marginTop: "50px",
            }}
          >
            <CircularProgress size={40} />
          </Box>
        )}
      </Grid>
      <ConfirmModal
        isOpen={isOpen}
        handle={handle.updateInvoicePaidBy}
        setIsOpen={setIsOpen}
        title={"Confirm"}
        btnTextNo="No"
        btnTextYes="Yes"
        message={"Are you sure you want to update your organisation's user invoice paid by?"}
      />
    </>
  );
};

export default UserDetails;
