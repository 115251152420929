import InfoIcon from "@mui/icons-material/Info";
import { Stack, Typography, ProgressBar, Tooltip, IconButton, Divider } from "@financeable-com-au/financeable-ui";
import { useLenderCardCriteria } from "../hooks";

const CriteriaProgress = () => {
  const { criteriaCheckedPercentage, criteriaCheckedLength, criteriaTotalLength, criteriaStatus, criteraList } =
    useLenderCardCriteria();

  return (
    <Stack direction="row" alignItems="center" columnGap={2} maxWidth="350px">
      <Typography level="body-xs">Criteria</Typography>
      <ProgressBar value={criteriaCheckedPercentage} status={criteriaStatus} />
      <Typography level="body-xs" bold={true}>
        {criteriaCheckedLength}/{criteriaTotalLength}
      </Typography>
      {/* Tooltip of criteria list. */}
      <Tooltip
        title={
          <Stack padding={2} flexDirection="column" rowGap={2}>
            <Typography level="body-sm" bold>
              Criteria
            </Typography>
            <Divider />
            {criteraList.map(({ label, icon }) => (
              <Stack flexDirection="row" alignItems="center" columnGap={3} key={label}>
                {icon}
                <Typography level="body-xs">{label}</Typography>
              </Stack>
            ))}
          </Stack>
        }
      >
        <IconButton aria-label="Tooltip" isRounded={true} size="sm" variant="inline">
          <InfoIcon sx={{ fontSize: "1rem" }} />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

export { CriteriaProgress };
