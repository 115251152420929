import { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Sheet, Stack, Typography, Button } from "@financeable-com-au/financeable-ui";
import { type FormikHelpers, useFormik } from "formik";
import { ERRORS_MESSAGE } from "@/constants";
// @ts-expect-error: ignoring as it is existing js file
import { updateLenderSetting } from "@/store/slices/userSlice";
import { openSnackbar } from "@/store/slices/snackbarSlice";
import { useLenderContext } from "../LendersSettingsContext";
import { LendersForm } from "../LendersForm";
import { useLenderForm } from "../hooks";

const LendersEdit = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { selectedLender, setOpenLendersModal, setSelectedLender } = useLenderContext();
  const { lender: lenderName } = selectedLender ?? {};

  // Set up formik.
  const lenderForm = useLenderForm();
  const { initialValues, validationSchema } = lenderForm ?? {};
  const formik = useFormik({
    initialValues: initialValues ?? {},
    onSubmit: (values: Record<string, string>, _helpers: FormikHelpers<Record<string, string>>) => {
      handleEditLender(values);
    },
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema,
  });

  // Handle edit lender.
  const handleEditLender = useCallback(
    (values: Record<string, string>) => {
      setIsLoading(true);
      const { email, ...restValues } = values;
      // Dispatch the action.
      dispatch(
        updateLenderSetting({
          ...restValues,
          _id: selectedLender?._id,
          lenderEmail: email,
          lender: selectedLender?.lender,
          user: selectedLender?.user,
        }),
      )
        // @ts-expect-error: existing dispatch
        .then(({ payload, error }) => {
          // Reset the form.
          formik.resetForm();
          // Close the modal.
          setOpenLendersModal(null);
          setSelectedLender(null);
          if (error) {
            // Show snackbar.
            dispatch(
              openSnackbar({
                message: payload.status_message || ERRORS_MESSAGE.fetchErrorMsg,
                intent: "danger",
              }),
            );
          } else {
            // Show snackbar.
            dispatch(
              openSnackbar({
                message: `Lender ${selectedLender?.lender} was successfully edited.`,
                intent: "success",
              }),
            );
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [selectedLender],
  );

  return (
    <form onSubmit={formik.handleSubmit}>
      <Sheet sx={{ pb: 0 }}>
        <Typography component="h2" id="modal-title" level="title-md">
          Edit {lenderName}
        </Typography>
      </Sheet>
      <Sheet sx={{ maxHeight: "calc(100vh - 500px)", pb: 0, overflowY: "auto" }}>
        <LendersForm formik={formik} />
      </Sheet>
      <Sheet>
        <Stack direction="row" columnGap={2} width="100%" justifyContent="flex-end">
          <Button
            variant="secondary"
            fullWidth
            onClick={() => {
              setOpenLendersModal(null);
              setSelectedLender(null);
            }}
          >
            Cancel
          </Button>
          <Button variant="primary" fullWidth type="submit" loading={isLoading}>
            Edit Lender
          </Button>
        </Stack>
      </Sheet>
    </form>
  );
};

export { LendersEdit };
