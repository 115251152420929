import { Sheet } from "@financeable-com-au/financeable-ui";
import { useLenderContext } from "../LendersSettingsContext";
import { LendersEmptyList } from "../LendersEmptyList";
import { LendersCard } from "../LendersCard";

const LendersList = () => {
  const { setOpenLendersModal, setSelectedLender, addedLenders } = useLenderContext();

  if (addedLenders.length === 0) {
    return <LendersEmptyList />;
  }

  // Maps through the lender list and returns a LenderCard component for each lender.
  return (
    <Sheet role="list" variant="outlined" hasBorderRadius={true} sx={{ padding: 0, overflow: "hidden" }}>
      {addedLenders.map((addedLender, index) => {
        const isLastItem = index === addedLenders.length - 1;
        const currentLender = {
          _id: addedLender._id,
          lender: addedLender.lender,
          lenderCredentials: addedLender.lenderCredentials,
          apiKey: addedLender.apiKey,
          organisationId: addedLender.organisationId,
          brokerId: addedLender.brokerId,
          password: addedLender.password,
          username: addedLender.username,
          lenderEmail: addedLender.lenderEmail,
          user: addedLender.user,
        };
        return (
          <LendersCard
            key={addedLender._id}
            logo={addedLender.logo}
            name={addedLender.lender}
            isLastItem={isLastItem}
            onEditLender={() => {
              setOpenLendersModal("edit");
              setSelectedLender(currentLender);
            }}
            onRemoveLender={() => {
              setOpenLendersModal("remove");
              setSelectedLender(currentLender);
            }}
          />
        );
      })}
    </Sheet>
  );
};

export { LendersList };
