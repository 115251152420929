import { Stack, Button, Typography } from "@financeable-com-au/financeable-ui";
import { useLenderContext } from "../LendersSettingsContext";

const LendersEmptyList = () => {
  const { setOpenLendersModal } = useLenderContext();
  return (
    <Stack width="100%" alignItems="center">
      <Stack
        maxWidth="420px"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gap={4}
        textAlign="center"
      >
        <img src="/static/illustrations/add-lender.svg" alt="No lenders found" width="100%" />
        <Typography level="title-md" component="h2">
          No lenders found
        </Typography>
        <Typography level="body-md">
          It looks like you haven't added any lenders yet. Start building your list to manage them all in one place.
        </Typography>
        <Button onClick={() => setOpenLendersModal("add")} fullWidth>
          Add New Lender
        </Button>
      </Stack>
    </Stack>
  );
};

export { LendersEmptyList };
