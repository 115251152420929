import { createContext, useContext } from "react";
import type { LendersSettingsContextType } from "./types/LenderSettingsTypes";

const LendersSettingsContext = createContext<LendersSettingsContextType>({
  openLendersModal: null,
  selectedLender: null,
  addedLenders: [],
  remainingLenders: [],
  setOpenLendersModal: () => {},
  setSelectedLender: () => {},
});

const useLenderContext = () => {
  const context = useContext(LendersSettingsContext);
  if (!context) {
    throw new Error("useLenderContext must be used within LendersSettingsContext");
  }
  return context;
};

export { LendersSettingsContext, useLenderContext };

