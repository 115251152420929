import { brandedOrganisations, BrandedOrganisation } from "@/constants";

interface CustomTheme {
  customPrimary?: Record<string, string> | undefined;
}

const useCustomTheme = (): CustomTheme => {
  const subdomain = window.location.host.split(".")[0].split("-")[0];
  const isBranded = brandedOrganisations.some((organisation) => organisation === subdomain);
  // Default is undefined when not customised to be branded.
  if (isBranded) {
    if (subdomain === BrandedOrganisation.GGF) {
      const customPrimary = {
        "50": "#D8F0F8",
        "100": "#00A7DF",
        "200": "#009FD5",
        "300": "#046E9A",
        "400": "#17527D",
      };
      return { customPrimary };
    }
    return { customPrimary: undefined };
  }
  return { customPrimary: undefined };
};

export { useCustomTheme };
