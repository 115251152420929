import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import _ from "lodash";
import { reusableFetch } from "../../utils/useAxios";

export const createOrganisation = createAsyncThunk("organisation/create", async (data, { rejectWithValue }) => {
  const response = await reusableFetch(`organisation/`, "POST", data, rejectWithValue);
  return response?.data;
});

export const getOrganisation = createAsyncThunk("organisation/get", async (Id, { rejectWithValue }) => {
  const response = await reusableFetch(`organisation/get-organisation/${Id}`, "GET", {}, rejectWithValue);
  return response?.data;
});

export const updateOrganisation = createAsyncThunk("organisation/update", async (data, { rejectWithValue }) => {
  const response = await reusableFetch(`organisation/${data?._id}`, "PATCH", data, rejectWithValue);
  return response;
});

export const getAllOrganisation = createAsyncThunk("organisation/get", async (data, { rejectWithValue }) => {
  const response = await reusableFetch(`organisation/all`, "POST", data, rejectWithValue);
  return response?.data;
});

export const getAllParentOrganisation = createAsyncThunk(
  "parentOrganisation/get",
  async (data, { rejectWithValue }) => {
    const response = await reusableFetch(`organisation/parent/org`, "POST", data, rejectWithValue);
    return response?.data;
  },
);

export const updateAddress = createAsyncThunk("address/update", async (data, { rejectWithValue }) => {
  const response = await reusableFetch(`organisation/address`, "POST", data, rejectWithValue);
  return response;
});

export const updateEmail = createAsyncThunk("address/update", async (data, { rejectWithValue }) => {
  const response = await reusableFetch(`organisation/email`, "POST", data, rejectWithValue);
  return response;
});

export const updatePhoneNumberOrg = createAsyncThunk("address/update", async (data, { rejectWithValue }) => {
  const response = await reusableFetch(`organisation/phone-number`, "POST", data, rejectWithValue);
  return response;
});

export const requestToJoin = createAsyncThunk("organisation/request", async (data, { rejectWithValue }) => {
  const response = await reusableFetch(`organisation/request`, "POST", data, rejectWithValue);
  return response;
});

export const inviteUsers = createAsyncThunk("organisation/invite", async (data, { rejectWithValue }) => {
  const response = await reusableFetch(`organisation/invite`, "POST", data, rejectWithValue);
  return response;
});

export const inviteAccept = createAsyncThunk("organisation/invite/accept", async (key, { rejectWithValue }) => {
  const response = await reusableFetch(`organisation/invite/accept/${key}`, "GET", {}, rejectWithValue);
  console.log({ response });
  return response;
});

export const createBankDetails = createAsyncThunk("bankDetails/create", async (data, { rejectWithValue }) => {
  const response = await reusableFetch(`bank-details/`, "POST", data, rejectWithValue);
  return response;
});

export const validateEmail = createAsyncThunk("validate/email", async (email, { rejectWithValue }) => {
  const response = await reusableFetch(`user/email/validate/${email}`, "GET", {}, rejectWithValue);
  return response?.data;
});

export const validatePhone = createAsyncThunk("validate/phone", async (phone, { rejectWithValue }) => {
  const response = await reusableFetch(`user/phone/validate/${phone}`, "GET", {}, rejectWithValue);
  return response?.data;
});

export const getOrgBrand = createAsyncThunk("brand/get", async (orgId, { rejectWithValue }) => {
  const response = await reusableFetch(`organisation/brand/${orgId}`, "GET", {}, rejectWithValue);
  return response?.data;
});

export const addOrgBrand = createAsyncThunk("brand/add", async (orgBrand, { rejectWithValue }) => {
  const response = await reusableFetch(`organisation/brand/create`, "POST", orgBrand, {}, rejectWithValue);
  return response;
});

export const updateOrgBrand = createAsyncThunk("brand/update", async (orgBrand, { rejectWithValue }) => {
  const response = await reusableFetch(`organisation/brand/update`, "PUT", orgBrand, {}, rejectWithValue);
  return response;
});

export const organisationSlice = createSlice({
  name: "organisation",
  initialState: {
    isCreating: false,
    phoneInUse: false,
    emailInUse: false,
    isNew: null,
    organisationList: [],
    parentOrganisationList: [],
    requestSuccess: false,
  },
  reducers: {
    saveValue: (state, action) => _.merge(state, action.payload),
  },
  extraReducers: {
    [validateEmail.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.emailInUse = payload.data.emailInUse;
    },
    [validateEmail.pending]: (state) => {
      state.isFetching = true;
    },
    [validateEmail.rejected]: (state) => {
      state.isFetching = false;
      state.isError = true;
    },

    [validatePhone.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.phoneInUse = payload.data.phoneInUse;
    },
    [validatePhone.pending]: (state) => {
      state.isFetching = true;
    },
    [validatePhone.rejected]: (state) => {
      state.isFetching = false;
      state.isError = true;
    },
    [createOrganisation.fulfilled]: (state, { payload }) => {
      state = _.merge(state, payload.data);
      state.isCreating = false;
      state.isNew = true;
    },
    [createOrganisation.pending]: (state) => {
      state.isCreating = true;
    },
    [createOrganisation.rejected]: () => { },

    [getOrganisation.fulfilled]: (state, { payload }) => {
      // state = _.merge(state, payload.data);
    },
    [getOrganisation.pending]: () => { },
    [getOrganisation.rejected]: (state) => {
      state.isError = true;
    },

    [updateOrganisation.fulfilled]: (state, { payload }) => { },
    [updateOrganisation.pending]: () => { },
    [updateOrganisation.rejected]: (state) => { },

    [updateAddress.fulfilled]: (state, { payload }) => {
      state = _.merge(state, payload.data);
    },
    [updateAddress.pending]: () => { },
    [updateAddress.rejected]: () => { },

    [updateEmail.fulfilled]: (state, { payload }) => {
      state = _.merge(state, payload.data);
    },
    [updateEmail.pending]: () => { },
    [updateEmail.rejected]: () => { },
    [updatePhoneNumberOrg.fulfilled]: (state, { payload }) => {
      state = _.merge(state, payload.data);
    },
    [updatePhoneNumberOrg.pending]: () => { },
    [updatePhoneNumberOrg.rejected]: () => { },

    [requestToJoin.fulfilled]: (state, { payload }) => {
      state = _.merge(state, payload.data);
      state.requestSuccess = true;
    },
    [requestToJoin.pending]: (state) => {
      state.isCreating = true;
    },
    [requestToJoin.rejected]: () => { },

    [inviteUsers.fulfilled]: (state, { payload }) => {
      // state = _.merge(state, payload.data);
      state.inviteUsersSuccess = true;
    },
    [inviteUsers.pending]: () => { },
    [inviteUsers.rejected]: () => { },

    [inviteAccept.fulfilled]: (state, { payload }) => {
      // state = _.merge(state, payload.data);
      // state.inviteUsersSuccess = true;
    },
    [inviteAccept.pending]: () => { },
    [inviteAccept.rejected]: () => { },

    [getAllOrganisation.fulfilled]: (state, { payload }) => {
      state.organisationList = payload?.data?.getOrganisationsUsersData;
    },
    [getAllOrganisation.pending]: () => { },
    [getAllOrganisation.rejected]: () => { },

    [getAllParentOrganisation.fulfilled]: (state, { payload }) => {
      state.parentOrganisationList = payload?.data;
    },
    [getAllParentOrganisation.pending]: (state) => {
      state.parentOrganisationList = [];
    },
    [getAllParentOrganisation.rejected]: () => { },

    //brand section
    [updateOrgBrand.fulfilled]: (state, { payload }) => {
      const oldOrgList = current(state.organisationList?.docs).map((organisation) => {
        if (organisation?._id === payload?.data?.data?._id) {
          return {
            ...organisation,
          };
        }
        return organisation;
      });
      state.organisationList = { ...state.organisationList, docs: [...oldOrgList] };
    },
    [updateOrgBrand.pending]: (state) => { },
    [updateOrgBrand.rejected]: () => { },

    [addOrgBrand.fulfilled]: (state, { payload }) => {
      const oldOrgList = current(state.organisationList?.docs).map((organisation) => {
        if (organisation?._id === payload?.data?.data?._id) {
          return {
            ...organisation,
            isBrandingExists: true
          };
        }
        return organisation;
      });
      state.organisationList = { ...state.organisationList, docs: [...oldOrgList] };
    },
    [addOrgBrand.pending]: (state) => { },
    [addOrgBrand.rejected]: () => { },
  },
});

export const { saveValue } = organisationSlice.actions;

export const organisationSelector = (state) => state.organisation;
export const organisationsSelector = (state) => state.organisation.organisationList;
