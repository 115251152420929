import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { startCase } from "lodash";
import {
  Modal,
  ModalDialog,
  ModalOverflow,
  Typography,
  Stack,
  Divider,
  Button,
} from "@financeable-com-au/financeable-ui";
import { useLenderCardContext } from "@/components/LenderProductCard/LenderCardContext";
import { ERRORS_MESSAGE, SUCCESS_MESSAGE } from "@/constants";
// @ts-expect-error: ignoring as it is existing js file
import { sendDisclosure, applicationSelector, userDetailsSelector } from "@/store/slices/applicationFormSlice";
import { RepaymentFrequency } from "@/components/LenderProductCard/hooks/useQuoteCalculatorForm";
import type {
  ApplicationFormSlice,
  ApplicationState,
  UserDetailsState,
} from "@/store/slices/types/applicationFormSlice";
import { openSnackbar } from "@/store/slices/snackbarSlice";

interface DisclosureStatementModalProps {
  isCustomQuote: boolean;
}

const modalWidth = "480px";

const DisclosureStatementModal = ({ isCustomQuote }: DisclosureStatementModalProps) => {
  const dispatch = useDispatch();
  const application = useSelector<ApplicationFormSlice, ApplicationState>(applicationSelector);
  const {
    product,
    showDisclosureStatement,
    setShowDisclosureStatement,
    setShowQuoteCalculator,
    setShowCriteria,
    lenderCard,
    lenderCardCustom,
  } = useLenderCardContext();
  const { disclosureLoader } = useSelector<ApplicationFormSlice, UserDetailsState>(userDetailsSelector);

  const disclosureInfo = {
    referrer: lenderCardCustom.referrer,
    inArrears: lenderCardCustom.inArrears,
    ...(isCustomQuote
      ? {
          ratePercentage: lenderCardCustom.rate.default.formattedValue,
          rateValue: lenderCardCustom.rate.default.value,
          loanAmount: lenderCardCustom.loanAmount.formattedValue,
          loanAmountValue: lenderCardCustom.loanAmount.value,
          effectiveRatePercentage: lenderCardCustom.rate.effective.formattedValue,
          effectiveRateValue: lenderCardCustom.rate.effective.value,
          fees: lenderCardCustom.fees.application,
          formattedRepayments: lenderCardCustom.repayments.primary.formattedValue,
          repaymentsValue: lenderCardCustom.repayments.primary.value,
          repaymentFrequency: lenderCardCustom.repaymentFrequency,
        }
      : {
          ratePercentage: lenderCard.rate.default.formattedValue,
          rateValue: lenderCard.rate.default.value,
          loanAmount: lenderCard.loanAmount.formattedValue,
          loanAmountValue: lenderCard.loanAmount.value,
          effectiveRatePercentage: lenderCard.rate.effective.formattedValue,
          effectiveRateValue: lenderCard.rate.effective.value,
          fees: lenderCard.fees.application,
          formattedRepayments: lenderCard.repayments.monthly.formattedValue,
          repaymentsValue: lenderCard.repayments.monthly.value,
          repaymentFrequency: RepaymentFrequency.Monthly,
        }),
  };

  const handleSendDisclosure = () => {
    dispatch(
      sendDisclosure({
        applicationId: application._id,
        rate: disclosureInfo.rateValue,
        loanAmount: disclosureInfo.loanAmountValue,
        repayments: disclosureInfo.repaymentsValue,
        repaymentFrequency: disclosureInfo.repaymentFrequency,
        effectiveRate: disclosureInfo.effectiveRateValue,
        fees: disclosureInfo.fees,
        referrer: disclosureInfo.referrer,
        lenderType: product?.lender ?? "",
      }),
      // @ts-expect-error: existing dispatch
    ).then(({ error, payload }) => {
      setShowDisclosureStatement(false);
      if (error) {
        dispatch(
          openSnackbar({
            message: payload.data.status_message || ERRORS_MESSAGE.fetchErrorMsg,
            intent: "danger",
          }),
        );
      } else {
        dispatch(
          openSnackbar({
            message: SUCCESS_MESSAGE.disclosure,
            intent: "success",
          }),
        );
      }
    });
  };

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={!!showDisclosureStatement}
      onClose={() => {
        setShowDisclosureStatement(false);
      }}
    >
      <ModalOverflow>
        <ModalDialog minWidth={modalWidth} maxWidth={modalWidth}>
          <Stack direction="column" rowGap={6}>
            <Stack direction="column" rowGap={2}>
              <Typography component="h3" level="title-md" id="modal-title">
                Send Proposal Disclosure Statement
              </Typography>
              <Typography component="p" id="modal-desc">
                Please review the details below
              </Typography>
              <Divider />
            </Stack>
            <Stack flexDirection="column" rowGap={2} flexGrow={1}>
              <Stack flexDirection="row">
                <Typography component="span" level="body-sm" bold>
                  Loan Amount:&nbsp;
                </Typography>
                <Typography component="span" level="body-sm">
                  {disclosureInfo.loanAmount}
                </Typography>
              </Stack>
              <Stack flexDirection="row">
                <Typography component="span" level="body-sm" bold>
                  Repayment term:&nbsp;
                </Typography>
                <Typography component="span" level="body-sm">
                  {application.loanDetails.term} months
                </Typography>
              </Stack>
              <Stack flexDirection="row">
                <Typography component="span" level="body-sm" bold>
                  Rate:&nbsp;
                </Typography>
                <Typography component="span" level="body-sm">
                  {disclosureInfo.ratePercentage}
                </Typography>
              </Stack>
              <Stack flexDirection="row">
                <Typography component="span" level="body-sm" bold>
                  Effective Rate:&nbsp;
                </Typography>
                <Typography component="span" level="body-sm">
                  {disclosureInfo.effectiveRatePercentage}
                </Typography>
              </Stack>
              {disclosureInfo.fees.map(({ name, formattedValue, key }) => {
                const isReferrer = key === "referralFee";
                return (
                  <Fragment key={key}>
                    <Stack flexDirection="row">
                      <Typography component="span" level="body-sm" bold>
                        {startCase(name)}:&nbsp;
                      </Typography>
                      <Typography component="span" level="body-sm">
                        {formattedValue}
                      </Typography>
                    </Stack>
                    {isReferrer && disclosureInfo.referrer && (
                      <Stack flexDirection="row">
                        <Typography component="span" level="body-sm" bold>
                          Referrer:&nbsp;
                        </Typography>
                        <Typography component="span" level="body-sm">
                          {disclosureInfo.referrer}
                        </Typography>
                      </Stack>
                    )}
                  </Fragment>
                );
              })}
              <Stack flexDirection="row">
                <Typography component="span" level="body-sm" bold>
                  In Arrears:&nbsp;
                </Typography>
                <Typography component="span" level="body-sm">
                  {disclosureInfo.inArrears}
                </Typography>
              </Stack>
              <Stack flexDirection="row">
                <Typography component="span" level="body-sm" bold>
                  Repayments:&nbsp;
                </Typography>
                <Typography component="span" level="body-sm">
                  {disclosureInfo.formattedRepayments}
                </Typography>
              </Stack>
            </Stack>
            <Stack direction="row" columnGap={2} width="100%" justifyContent="space-between">
              <Stack direction="row" columnGap={2}>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setShowDisclosureStatement(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setShowDisclosureStatement(false);
                    setShowCriteria(false);
                    setShowQuoteCalculator(true);
                  }}
                >
                  Customise
                </Button>
              </Stack>
              <Button variant="primary" onClick={handleSendDisclosure} loading={disclosureLoader}>
                Send Disclosure
              </Button>
            </Stack>
          </Stack>
        </ModalDialog>
      </ModalOverflow>
    </Modal>
  );
};

export { DisclosureStatementModal };
