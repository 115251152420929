interface ApplicationFormSlice {
  userDetails: UserDetailsState;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
interface ActivityLog {
  _id: string;
  activityType: string;
  name: string;
  description: string;
  values: any | null;
  customer?: string;
  application: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

interface IsManualAddress {
  show: boolean;
  customerId: string | null;
}

interface PersonalFinLoaders {
  id: string;
  addAssetLoader: boolean;
  removeAssetLoader: boolean;
  addLiabilityLoader: boolean;
  removeLiabilityLoader: boolean;
  addIncomeLoader: boolean;
  removeIncomeLoader: boolean;
  addExpenseLoader: boolean;
  removeExpenseLoader: boolean;
}

interface CreditScoreType {
  label: string;
  value: string;
  permissionType: string;
  productDataLevel: string;
}

interface UserDetailsState {
  application: ApplicationState;
  singleEntityElement: Record<string, any>;
  gettingQuote: boolean;
  confirmCustomerDataLoader: boolean;
  gettingClonedApplication: boolean;
  startApplicationBtnLoader: boolean;
  gettingCalculator: boolean;
  loadingSubmitApplication: boolean;
  verifiedDialogBoxIsOpen: boolean;
  customerPrivacyRequest: boolean;
  idVerificationRequest: boolean;
  bankStatementsRequest: boolean;
  linkSent: boolean;
  sendingIDLink: null | boolean;
  ApplicationBtn: boolean;
  gettingASICData: null | boolean;
  gettingASICPPSRData: null | boolean;
  gotASICData: null | boolean;
  gettingEntityDetails: boolean;
  gettingCreditProfile: boolean;
  gettingEquifaxCreditFile: boolean;
  gettingEquifaxCreditCompanyFile: boolean;
  canCalculateQuote: boolean;
  gettingAddressDetails: boolean;
  isManualAddress: IsManualAddress;
  currentProductLenderId: string;
  directors: any[];
  shareHolders: any[];
  beneficiaries: any[];
  shares: any[];
  businessAddresses: any[];
  applicants: any[];
  entityId: string;
  entity: Record<string, any>;
  identityVerification: any[];
  identityDocument: any[];
  address: Record<string, any>;
  customer: Record<string, any>;
  document: Record<string, any>;
  creditProfile: Record<string, any>;
  open: boolean;
  uploadPrivacyLoading: string;
  uploadIDLoading: string;
  documentUploadLoding: string;
  isUpdatedLoanDetails: null | boolean;
  gettingApplication: boolean;
  downloadApplicationLoader: boolean;
  disclosureLoader: boolean;
  activityLog: ActivityLog[];
  applicationServiceLog: any[];
  creditScoreTypes: CreditScoreType[];
  applicationServiceLogLoader: boolean;
  openManualQuote: boolean;
  removeEmployerLoader: boolean;
  openConnectiveContactModel: boolean;
  connectiveContactList: any[];
  personalFinLoaders: PersonalFinLoaders;
  requireFieldErrors: Record<string, any>;
  validationRequireFieldError: Record<string, any>;
  lenderRequiredFieldsError: Record<string, any>;
  calculatorFieldsErrors: Record<string, any>;
  gettingactivityLog: boolean;
  isQuoteOpen?: boolean;
}

interface Email {
  status: string;
  _id: string;
  address: string;
  emailType: string;
  user: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

interface LenderSetting {
  _id: string;
  organisationId?: string;
  lender: string;
  user: string;
  lenderEmail?: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

interface Organisation {
  businessNames: any[];
  users: string[];
  organisations: any[];
  transactions: any[];
  services: any[];
  _id: string;
  workOSId: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
  brandId: string;
}

interface User {
  isStripeCustomer: boolean;
  isBillable: boolean;
  lenderSettings: LenderSetting[];
  transactions: any[];
  services: any[];
  bankDetails: any[];
  status: string;
  service_invoice_paid_by: string;
  lenders: any[];
  _id: string;
  email: Email;
  terms: boolean;
  firstName: string;
  lastName: string;
  workOSId: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
  organisation: Organisation;
  userType: string;
}

interface BlueflagPPSR {
  search: any[];
  nevdisData: Record<string, any>;
}

interface Asset {
  nvicLoaderData: boolean;
  makeLoader: boolean;
  modelLoader: boolean;
  variantLoader: boolean;
  NVICLoader: boolean;
  searchVehicleLoader: boolean;
  searchPPSRLoader: boolean;
  blueflagPPSR: BlueflagPPSR;
  documents: Document[];
  ageOfAssetAtEnd: string;
  assetValue: number;
  netAssetValue: number;
  additionalFees: number;
  additionalTaxes: number;
  isManualAssets: boolean;
  _id: string;
  application: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
  supplierABN: string;
  supplierAddress: string;
  supplierEmail: string;
  supplierName: string;
  supplierPhone: string;
}

enum FeeFrequency {
  Monthly = "monthly",
  Upfront = "upfront",
  Financed = "financed",
}

interface Fee {
  key?: string;
  name: string;
  value?: number;
  capitalised?: boolean; // ## TODO: This type is being deprecated.
  frequency: FeeFrequency;
}

enum InArrears {
  Yes = "Yes",
  No = "No",
}

interface LoanDetails {
  repayments: number;
  loanAmount: number;
  requestedLoanAmount: number;
  term: number;
  brokerage: number;
  brokerageAmount: number;
  brokerageAdjustment: number;
  balloon: number;
  balloonAmount: number;
  deposit: number;
  tradeIn: number;
  originationFee: number;
  payout: number;
  fees: Fee[];
  rate: number;
  rateAdjustment: number;
  cash: number;
  lender: string;
  inArrears?: InArrears;
  _id: string;
  application: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

interface PhoneNumber {
  _id: string;
  number: string;
  status: string;
  customer: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

interface CustomerProfile {
  numberOfDependants: number;
  _id: string;
  customer: string;
  __v: number;
  createdAt: string;
  maritalStatus: string;
  updatedAt: string;
}

interface CustomerDisclaimer {
  _id: string;
  customer: string;
  application: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

interface Customer {
  isCustomerDetailsSaved: boolean;
  isDetailsLinkSend: boolean;
  documents: Document[];
  addresses: any[];
  emails: any[];
  phoneNumbers: PhoneNumber[];
  applications: string[];
  entities: any[];
  employers: any[];
  income: Array<Record<string, any>>;
  expenses: Array<Record<string, any>>;
  customerAssets: Array<Record<string, any>>;
  liabilities: Array<Record<string, any>>;
  users: any[];
  bankDetails: any[];
  _id: string;
  customerDisclaimer: CustomerDisclaimer;
  createdAt: string;
  updatedAt: string;
  __v: number;
  customerTitle: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  IDNumber: string;
  IDCardNumber: string;
  IDExpiryDate: string;
  customerProfile: CustomerProfile;
}

interface SubmissionStep {
  type: "acknowledgement" | "documents" | "confirmation" | "mfa";
  label: string;
  body?: string | null;
  stepper: string;
  isCompleted?: boolean;
}

interface SubmissionModal {
  steps: SubmissionStep[];
  isOpen?: boolean;
  submissionType?: "email" | "upload";
  lender: string | undefined;
  productDetails?: {
    productName: string;
    productType: string;
  };
  acknowledgement?: "pending" | "fulfilled" | "rejected" | undefined;
  submission: {
    status: "pending" | "fulfilled" | "rejected" | undefined;
    message?: Array<Record<string, string>> | string;
  };
  selectedDocuments?: string[];
}

interface Document {
  _id: string;
  name: string;
  documentType: string;
  details: null | any;
  customer?: string;
  application: string;
  ext: string;
  fileName: string;
  url: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

interface ApplicationState {
  asset: Asset;
  loanDetails: LoanDetails;
  customer_acknowledgement: {
    is_acknowledgement_accept: boolean;
  };
  customers: Customer[];
  entities: any[];
  transactions: any[];
  activities: string[];
  comments: any[];
  _id: string;
  humanId: string;
  status: string;
  user: User;
  applicationType: ApplicationType;
  createdAt: string;
  updatedAt: string;
  submissionModal: SubmissionModal;
  documents?: Document[];
  __v: number;
}
/* eslint-enable @typescript-eslint/no-explicit-any */

enum ApplicationType {
  Consumer = "consumer",
  Commercial = "commercial",
  Personal = "personal",
}

export type {
  ApplicationFormSlice,
  UserDetailsState,
  ActivityLog,
  IsManualAddress,
  PersonalFinLoaders,
  CreditScoreType,
  ApplicationState,
  LoanDetails,
  Fee,
  Asset,
  SubmissionModal,
  SubmissionStep,
};

export { ApplicationType, FeeFrequency, InArrears };
