import { useSelector, useDispatch } from "react-redux";
import { Stack, Typography, Button } from "@financeable-com-au/financeable-ui";
// @ts-expect-error: ignoring as it is existing js file
import { setSubmissionModalOpen, applicationSelector } from "@/store/slices/applicationFormSlice";
import type { ApplicationFormSlice, ApplicationState } from "@/store/slices/types/applicationFormSlice";

const SubmissionFulfilled = () => {
  const { submissionModal } = useSelector<ApplicationFormSlice, ApplicationState>(applicationSelector);
  const dispatch = useDispatch();

  const handleCloseSubmissionModal = () => {
    dispatch(setSubmissionModalOpen({ isOpen: false }));
  };

  return (
    <Stack alignItems="center" rowGap={6} textAlign="center">
      <Stack rowGap={3}>
        <Typography component="h2" id="modal-title" level="title-md">
          Uploaded Successfully!
        </Typography>
        <Typography>
          Your application for{" "}
          <Typography textColor="text.primary" bold component="span">
            '{submissionModal.lender}'
          </Typography>{" "}
          was approved.
        </Typography>
      </Stack>
      <Button variant="primary" onClick={handleCloseSubmissionModal}>
        See Application
      </Button>
    </Stack>
  );
};

export { SubmissionFulfilled };
