import { useSelector, useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Snackbar as FinanceableSnackbar, IconButton } from "@financeable-com-au/financeable-ui";
import { snackbarSelector, closeSnackbar } from "@/store/slices/snackbarSlice";

const autoHideDuration = 5000;

const Snackbar = () => {
  const { snackbar } = useSelector(snackbarSelector);
  const dispatch = useDispatch();
  return (
    <FinanceableSnackbar
      autoHideDuration={autoHideDuration}
      open={snackbar.open}
      intent={snackbar.intent ?? "success"}
      startDecorator={<InfoOutlinedIcon sx={{ fontSize: "1rem" }} />}
      onClose={() => {
        dispatch(closeSnackbar());
      }}
      endDecorator={
        <IconButton
          data-testid="close-button"
          variant="inline"
          onClick={() => {
            dispatch(closeSnackbar());
          }}
          intent={snackbar.intent ?? "success"}
        >
          <CloseIcon sx={{ fontSize: "1rem" }} />
        </IconButton>
      }
    >
      {snackbar.message}
    </FinanceableSnackbar>
  );
};

export { Snackbar };
