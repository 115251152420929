import { useSelector, useDispatch } from "react-redux";
// @ts-expect-error: ignoring as it is existing js file
import { applicationSelector, setSubmissionStepCompleted } from "@/store/slices/applicationFormSlice";
import type { ApplicationFormSlice, ApplicationState, SubmissionStep } from "@/store/slices/types/applicationFormSlice";
import { useSubmission } from "@/components/ApplicationSubmissionModal/hooks/useSubmission";

const useSubmissionSteps = () => {
  const { submissionModal } = useSelector<ApplicationFormSlice, ApplicationState>(applicationSelector);
  const { handleSubmit } = useSubmission();
  const dispatch = useDispatch();

  const isSubmissionStepIncomplete = ({ stepType }: { stepType: SubmissionStep["type"] }) => {
    return submissionModal.steps.some((step) => step.type === stepType && !step.isCompleted);
  };

  const proceedSubmissionSteps = ({ activeStep }: { activeStep: SubmissionStep["type"] }) => {
    const isConfirmationStep = activeStep === "confirmation";

    dispatch(setSubmissionStepCompleted({ type: activeStep }));

    // If this is the confirmation step, then we also submit the application.
    if (isConfirmationStep) {
      handleSubmit();
    }
  };

  const constructSubmissionSteps = ({ lenderSubmissionStep }: { lenderSubmissionStep: SubmissionStep[] }) => {
    const defaultSubmissionSteps: SubmissionStep[] = [
      { type: "documents", label: "Select Documents", stepper: "Select" },
      { type: "confirmation", label: "Confirmation", stepper: "Confirm" },
    ];
    const acknowledgementStep = lenderSubmissionStep.find((step) => step.type === "acknowledgement");
    const mfaStep = lenderSubmissionStep.find((step) => step.type === "mfa");
    const submissionSteps: SubmissionStep[] = [
      ...(!!acknowledgementStep ? [{ ...acknowledgementStep, stepper: "Acknowledge" }] : []),
      ...defaultSubmissionSteps,
      ...(!!mfaStep ? [{ ...mfaStep, stepper: "Authenticate" }] : []),
    ];
    return submissionSteps;
  };

  return { isSubmissionStepIncomplete, proceedSubmissionSteps, constructSubmissionSteps };
};

export { useSubmissionSteps };
