import { useSelector } from "react-redux";
import { Modal, ModalDialog, ModalOverflow } from "@financeable-com-au/financeable-ui";
// @ts-expect-error: ignoring as it is existing js file
import { applicationSelector } from "@/store/slices/applicationFormSlice";
import type { ApplicationFormSlice, ApplicationState } from "@/store/slices/types/applicationFormSlice";
import { AcknowledgementDisclaimer } from "./AcknowledgementDisclaimer";
import { SelectDocuments } from "./SelectDocuments";
import { MFAVerification } from "./MFAVerification";
import { Confirmation } from "./Confirmation";
import { SubmissionPending } from "./SubmissionPending";
import { SubmissionFulfilled } from "./SubmissionFulfilled";
import { SubmissionDenied } from "./SubmissionDenied";
import { useSubmissionSteps } from "./hooks";

const modalWidth = "480px";

const ModalContent = () => {
  const { submissionModal } = useSelector<ApplicationFormSlice, ApplicationState>(applicationSelector);
  const { isSubmissionStepIncomplete } = useSubmissionSteps();

  if (isSubmissionStepIncomplete({ stepType: "acknowledgement" })) {
    return <AcknowledgementDisclaimer />;
  }
  if (isSubmissionStepIncomplete({ stepType: "documents" })) {
    return <SelectDocuments />;
  }
  if (isSubmissionStepIncomplete({ stepType: "confirmation" })) {
    return <Confirmation />;
  }
  if (isSubmissionStepIncomplete({ stepType: "mfa" })) {
    return <MFAVerification />;
  }
  if (submissionModal.submission.status === "pending") {
    return <SubmissionPending />;
  }
  if (submissionModal.submission.status === "fulfilled") {
    return <SubmissionFulfilled />;
  }
  if (submissionModal.submission.status === "rejected") {
    return <SubmissionDenied />;
  }
  return null;
};

const ApplicationSubmissionModal = () => {
  const { submissionModal } = useSelector<ApplicationFormSlice, ApplicationState>(applicationSelector);

  return (
    <Modal aria-labelledby="modal-title" open={!!submissionModal.isOpen}>
      <ModalOverflow>
        <ModalDialog minWidth={modalWidth} maxWidth={modalWidth}>
          <ModalContent />
        </ModalDialog>
      </ModalOverflow>
    </Modal>
  );
};

export { ApplicationSubmissionModal };
