import axios from "axios";

// import { VITE_UNAUTHORIZED_REDIRECT } from "../constants";
// axios.defaults.withCredentials = true
export const headers = (tokenOverride) => {
  const token = sessionStorage.token;
  if (token) {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-access-token": tokenOverride ?? token,
      // // ...(sessionToken && {'x-api-key': sessionToken}), // For connectives
      // ...(sessionToken && { "x-api-key": sessionToken }), // For connectives
    };
  }

  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...(tokenOverride ? { "x-access-token": tokenOverride } : {}),
  };
};

export const getNewAccessToken = async (token) => {
  console.log("got 401")
  // try {
  //   // Call Refresh token API
  //   const response = await axios({
  //     url: `${import.meta.env.VITE_API_URL}/user/refresh-token`,
  //     method: "POST",
  //     headers: headers(token),
  //     data: {},
  //   },{withCredentials: true});

  //   return response?.data?.data;
  // } catch (error) {
  //   // If error on refresh token API
  //   localStorage.setItem("tokenExpired", true);
  //   localStorage.removeItem("token");
  //   // window.location.reload();
  //   window.location.replace(VITE_UNAUTHORIZED_REDIRECT);
  // }
};

export const reusableFetch = async (url, method, data, rejectWithValue, isArrayBuffer, tokenOverride) => {
  // const token = localStorage.token;
  // const sessionToken = sessionStorage.token;
  try {
    const response = await axios(
      {
        url: `${import.meta.env.VITE_API_URL}/${url}`,
        method: method,
        responseType: isArrayBuffer ? "arraybuffer" : "json",
        headers: headers(tokenOverride),
        data: data ? data : {},
        withCredentials: true,
        Origin: window.location.href,
      },
      {
        validateStatus: () => true,
      },
    );
    return response; // Assuming the relevant data is returned from the API
  } catch (error) {
    if (error.response?.status === 302) {
      window.location.replace(error.response?.data?.redirectUrl);
      return;
    }

    if (error.response?.status === 401) {
      if (error.response?.data?.data?.redirectUrl) {
        window.location.replace(error.response?.data?.data?.redirectUrl);
      }
    }
    return rejectWithValue(error.response ? error.response.data : error);
  }
};

export const reusableWithoutTokenFetch = async (url, method, data, rejectWithValue) => {
  try {
    const response = await axios({
      url: `${import.meta.env.VITE_API_URL}/${url}`,
      method: method,
      headers: headers(),
      data: data ? data : "",
      withCredentials: true,
      Origin: window.location.href,
    });
    return response; // Assuming the relevant data is returned from the API
  } catch (error) {
    if (error.response.status === 401) {
      // localStorage.setItem("tokenExpired", true);
      // localStorage.removeItem("token");
      // window.location.reload();
      // window.location.replace(VITE_UNAUTHORIZED_REDIRECT);
    }
    // if (error.response.status === 403) {
    //     window.location.replace(`${origin}/404`);
    // }
    return rejectWithValue(error.response ? error.response.data : error);
  }
};

export const reusableFormDataFetch = async (url, method, formData, rejectWithValue) => {
  const token = localStorage.token;
  const formDataHeaders = headers(token);
  formDataHeaders["Content-Type"] = `multipart/form-data; boundary=${formData._boundary}`;
  delete formDataHeaders.Accept;

  try {
    if (formData) {
      const response = await axios({
        url: `${import.meta.env.VITE_API_URL}/${url}`,
        method: method,
        headers: formDataHeaders,
        data: formData,
      });
      return response; // Assuming the relevant data is returned from the API
    }
  } catch (error) {
    if (error.response.status === 401) {
      const newToken = await getNewAccessToken(token);

      if (newToken?.token) {
        localStorage.setItem("token", newToken.token);
        // reload page to call API again otherwise user will freeze on API/Page
        window.location.reload();
        return;
      }
    }
    // if (error.response.status === 403) {
    //   window.location.replace(`${origin}/404`);
    //   // window.history.back();
    // }
    return rejectWithValue(error.response ? error.response.data : error);
  }
};

export const getUserInfo = async ({ access_token }) => {
  try {
    const response = await axios({
      url: `${import.meta.env.VITE_CONNECTIVE_USER_INFO_URL}`,
      method: "GET",
      headers: headers(access_token),
      data: "",
    });
    console.log("getUserInfo_response ::", response);

    return response;
  } catch (error) {
    console.log("getUserInfo_error ::", error);
    if (error.response.status === 401) {
      // localStorage.setItem("tokenExpired", true);
      // localStorage.removeItem("token");
      // window.location.replace(VITE_UNAUTHORIZED_REDIRECT);

      const newToken = await getNewAccessToken(access_token);

      if (newToken?.token) {
        localStorage.setItem("token", newToken.token);
        // reload page to call API Again otherwise user will freeze on API
        window.location.reload();
        return;
      }
    }
  }
};
