import { Sheet, Stack, Typography, Button, useFinanceableTheme } from "@financeable-com-au/financeable-ui";

interface LenderCardProps {
  logo: string;
  name: string;
  isLastItem: boolean;
  onEditLender: () => void;
  onRemoveLender: () => void;
}

const LendersCard = ({ logo, name, isLastItem, onEditLender, onRemoveLender }: LenderCardProps) => {
  const theme = useFinanceableTheme();
  return (
    <Sheet
      role="listitem"
      variant="plain"
      sx={{ padding: 6, borderBottom: isLastItem ? "none" : `1px solid ${theme.palette.neutral[300]}` }}
    >
      <Stack flexDirection="row" gap={4} alignItems="center" flexWrap="wrap">
        <Stack flexDirection="row" gap={6} flexGrow={1} alignItems="center">
          <Stack minWidth={75}>
            <img src={logo} style={{ maxWidth: 75, height: "auto", width: "100%" }} alt={`${name} logo`} />
          </Stack>
          <Typography level="body-md">{name}</Typography>
        </Stack>
        <Stack flexDirection="row" gap={2}>
          <Button variant="secondary" intent="danger" onClick={onRemoveLender}>
            Remove
          </Button>
          <Button variant="secondary" onClick={onEditLender}>
            Edit
          </Button>
        </Stack>
      </Stack>
    </Sheet>
  );
};

export { LendersCard };
