import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@/store/store";
import type { SnackbarSlice, SnackbarType } from "./types/snackbar";

const initialState: SnackbarSlice = {
  snackbar: {
    open: false,
    message: "",
  },
};

export const snackbarSlice = createSlice({
  name: "snackbar",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    openSnackbar: (state, action: PayloadAction<Pick<SnackbarType, "message" | "intent">>) => {
      state.snackbar.open = true;
      state.snackbar.message = action.payload.message;
      state.snackbar.intent = action.payload.intent;
    },
    closeSnackbar: (state) => {
      state.snackbar = initialState.snackbar;
    },
  },
});

export const { openSnackbar, closeSnackbar } = snackbarSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const snackbarSelector = (state: RootState) => state.snackbar;

export default snackbarSlice.reducer;
