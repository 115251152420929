import { useSelector } from "react-redux";
import InfoIcon from "@mui/icons-material/Info";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  Stack,
  Typography,
  FormControl,
  FormLabel,
  Input,
  Select,
  Option,
  Tooltip,
  IconButton,
  FormHelperText,
} from "@financeable-com-au/financeable-ui";
// @ts-expect-error: ignoring as it is existing js file
import { applicationSelector } from "@/store/slices/applicationFormSlice";
import {
  type ApplicationFormSlice,
  type ApplicationState,
  ApplicationType,
  InArrears,
} from "@/store/slices/types/applicationFormSlice";
import { DecimalMaskFormat } from "@/utils/DecimalMaskFormat";
import { RepaymentFrequency } from "@/components/LenderProductCard/hooks/useQuoteCalculatorForm";
import { useLenderCardContext } from "@/components/LenderProductCard/LenderCardContext";

interface LoanFieldsProps {
  saveQuote: ({ actionFor, value, key }: { actionFor: string; value?: string; key?: string }) => void;
}

const LoanFields = ({ saveQuote }: LoanFieldsProps) => {
  const {
    lenderCard: { brokerage },
    quoteCalculatorFormik,
  } = useLenderCardContext();
  const { applicationType } = useSelector<ApplicationFormSlice, ApplicationState>(applicationSelector);

  return (
    <Stack direction="column" rowGap={3}>
      <FormControl error={!!quoteCalculatorFormik?.errors.rate}>
        <FormLabel>Rate</FormLabel>
        <Input
          type="text"
          endDecorator="%"
          name="rate"
          value={quoteCalculatorFormik?.values.rate}
          onChange={quoteCalculatorFormik?.handleChange}
          onBlur={(e) => {
            quoteCalculatorFormik?.handleBlur(e);
            saveQuote({ actionFor: "rate" });
          }}
          slotProps={{
            input: {
              component: DecimalMaskFormat,
            },
          }}
        />
        {!!quoteCalculatorFormik?.errors.rate && (
          <FormHelperText>
            <ErrorOutlineIcon sx={{ fontSize: "1rem" }} />
            {quoteCalculatorFormik?.errors.rate}
          </FormHelperText>
        )}
      </FormControl>
      {applicationType === ApplicationType.Commercial && (
        <FormControl error={!!quoteCalculatorFormik?.errors.brokerage}>
          <FormLabel>
            Brokerage
            {/* Tooltip to explain the max brokerage. */}
            {!!brokerage.percentage.value && !!brokerage.max.value && (
              <Tooltip
                title={
                  <Stack padding={2} flexDirection="column" rowGap={2}>
                    <Typography>
                      There is a Maximum Brokerage of <Typography bold>{brokerage.max.formattedValue}</Typography>
                    </Typography>
                  </Stack>
                }
              >
                <IconButton aria-label="Tooltip" isRounded={true} size="sm" variant="inline">
                  <InfoIcon sx={{ fontSize: "1rem" }} />
                </IconButton>
              </Tooltip>
            )}
          </FormLabel>
          <Input
            type="text"
            name="brokerage"
            endDecorator="%"
            value={quoteCalculatorFormik?.values.brokerage}
            onChange={quoteCalculatorFormik?.handleChange}
            onBlur={(e) => {
              quoteCalculatorFormik?.handleBlur(e);
              saveQuote({ actionFor: "brokerage" });
            }}
            slotProps={{
              input: {
                component: DecimalMaskFormat,
              },
            }}
          />
          {!!quoteCalculatorFormik?.errors.brokerage && (
            <FormHelperText>
              <ErrorOutlineIcon sx={{ fontSize: "1rem" }} />
              {quoteCalculatorFormik?.errors.brokerage}
            </FormHelperText>
          )}
        </FormControl>
      )}
      <FormControl>
        <FormLabel>In Arrears</FormLabel>
        <Select
          value={quoteCalculatorFormik?.values.inArrears}
          name="inArrears"
          onChange={(_e, value) => {
            quoteCalculatorFormik?.setFieldValue("inArrears", value);
            saveQuote({ actionFor: "inArrears", value: value as unknown as InArrears });
          }}
        >
          <Option value={InArrears.Yes}>Yes</Option>
          <Option value={InArrears.No}>No</Option>
        </Select>
      </FormControl>
      <FormControl>
        <FormLabel>Repayment Frequency</FormLabel>
        <Select
          value={quoteCalculatorFormik?.values.repaymentFrequency}
          name="repaymentFrequency"
          onChange={(_e, value) => quoteCalculatorFormik?.setFieldValue("repaymentFrequency", value)}
        >
          <Option value={RepaymentFrequency.Weekly}>Weekly</Option>
          <Option value={RepaymentFrequency.Fortnightly}>Fortnightly</Option>
          <Option value={RepaymentFrequency.Monthly}>Monthly</Option>
        </Select>
      </FormControl>
    </Stack>
  );
};

export { LoanFields };
