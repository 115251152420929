import { useDispatch, useSelector } from "react-redux";
import { Stack, Typography, Button } from "@financeable-com-au/financeable-ui";
// @ts-expect-error: ignoring as it is existing js file
import { setSubmissionModalOpen, applicationSelector } from "@/store/slices/applicationFormSlice";
import type { ApplicationFormSlice, ApplicationState } from "@/store/slices/types/applicationFormSlice";

const ErrorMessage = ({ message }: { message: Array<Record<string, string>> | string }) => {
  if (typeof message === "string") {
    return <Typography>{message}</Typography>;
  }
  return message.map((item) => {
    if (item.message === "Required") {
      return (
        <>
          <Typography>
            Unfortunately, your application was not approved. Please review your details and try again or contact
            support for assistance.
          </Typography>
          <Typography>The following fields are required:</Typography>
          <Typography level="body-xs">
            <code>{item.path}</code>
          </Typography>
        </>
      );
    }
    return (
      <Typography level="body-xs">
        <code>{JSON.stringify(item)}</code>
      </Typography>
    );
  });
};

const SubmissionDenied = () => {
  const { submissionModal } = useSelector<ApplicationFormSlice, ApplicationState>(applicationSelector);
  const dispatch = useDispatch();

  const handleCloseSubmissionModal = () => {
    dispatch(setSubmissionModalOpen({ isOpen: false }));
  };

  const handleContactSupport = () => {
    window.open("https://financeable.com.au/contact", "_blank");
  };

  return (
    <Stack alignItems="center" rowGap={6} textAlign="center">
      <Stack rowGap={3}>
        <Typography component="h2" id="modal-title" level="title-md">
          Application Denied
        </Typography>
        <ErrorMessage message={submissionModal.submission.message ?? ""} />
      </Stack>
      <Stack direction="row" columnGap={2} width="100%">
        <Button variant="secondary" onClick={handleContactSupport} fullWidth>
          Contact Support
        </Button>
        <Button variant="primary" onClick={handleCloseSubmissionModal} fullWidth>
          Got It, Thanks!
        </Button>
      </Stack>
    </Stack>
  );
};

export { SubmissionDenied };
