import { useFeatureFlagsWithFallback } from "./useFeatureFlagsWithFallback";

interface UseFeatureFlags {
  lenderSubmissionUplift: boolean;
  lenderCredentialsUplift: boolean;
}

const useFeatureFlags = (): UseFeatureFlags => {
  const lenderSubmissionUplift = !!useFeatureFlagsWithFallback("fwa-5-lender-submission-uplift");
  const lenderCredentialsUplift = !!useFeatureFlagsWithFallback("fwa-64-lender-credentials-uplift");

  return {
    lenderSubmissionUplift,
    lenderCredentialsUplift,
  };
};

export { useFeatureFlags };
