import { useState, useMemo } from "react";
import { useSelector } from "react-redux";
// @ts-expect-error: ignoring as it is existing js file
import { userDetailsSelector, loanDetailsSelector } from "@/store/slices/applicationFormSlice";
import type { ApplicationFormSlice, UserDetailsState, LoanDetails } from "@/store/slices/types/applicationFormSlice";
import { LenderCardCondensed } from "./LenderCardCondensed";
import { LenderCardDetailed } from "./LenderCardDetailed";
import { LenderCardContext } from "./LenderCardContext";
import { useLenderCard, useQuoteCalculatorForm, useLenderCardCustom } from "./hooks";
import type { LenderProduct, RemainingDataFields } from "./LenderCardContext/types/LenderProductTypes";

interface LenderProductCardProps {
  productDetails: LenderProduct;
  showRequiredFields: (fields: RemainingDataFields) => void;
  isSingleLenderProduct: boolean;
}

const LenderProductCard = ({ productDetails, showRequiredFields, isSingleLenderProduct }: LenderProductCardProps) => {
  const { open: isLenderProductDrawerOpen } = useSelector<ApplicationFormSlice, UserDetailsState>(userDetailsSelector);
  const loanDetails = useSelector<ApplicationFormSlice, LoanDetails>(loanDetailsSelector);
  const [showCriteria, setShowCriteria] = useState(false);
  const [showQuoteCalculator, setShowQuoteCalculator] = useState(false);
  const [showDisclosureStatement, setShowDisclosureStatement] = useState(false);
  const lenderCard = useLenderCard({ product: productDetails });
  const { quoteCalculatorFormik } = useQuoteCalculatorForm({ product: productDetails });
  const lenderCardCustom = useLenderCardCustom({ formik: quoteCalculatorFormik });

  // Is custom quote.
  const isCustomQuote = useMemo(() => {
    const isLoanDetailsFromSelectedLender = loanDetails.lender === productDetails?.lender;
    return (
      (isLoanDetailsFromSelectedLender && quoteCalculatorFormik.dirty) ||
      (isLoanDetailsFromSelectedLender && !!quoteCalculatorFormik.values.lenderDefaultFees)
    );
  }, [
    quoteCalculatorFormik.dirty,
    quoteCalculatorFormik.values.lenderDefaultFees,
    loanDetails.lender,
    productDetails?.lender,
  ]);

  return (
    <LenderCardContext.Provider
      value={{
        product: productDetails,
        lenderCard,
        lenderCardCustom,
        quoteCalculatorFormik,
        showCriteria,
        showQuoteCalculator,
        showDisclosureStatement,
        isSingleLenderProduct,
        setShowCriteria,
        setShowQuoteCalculator,
        setShowDisclosureStatement,
        showRequiredFields,
      }}
    >
      {isLenderProductDrawerOpen ? <LenderCardDetailed isCustomQuote={isCustomQuote} /> : <LenderCardCondensed />}
    </LenderCardContext.Provider>
  );
};

export { LenderProductCard };
