import { Link } from "react-router-dom";
import { Typography, Button } from "@mui/material";
import { creditCardModalInfo, payInvoiceModalInfo } from "@/constants";
import { usePaidServices } from "@/hooks";

const PaidServicesTooltip = () => {
  const { isInvoicePending, isServiceEnabled, numberOfPendingInvoices } = usePaidServices();

  if (isInvoicePending) {
    return (
      <>
        <Typography color="inherit" marginBottom="5px">
          {payInvoiceModalInfo.mainTitle}
        </Typography>
        <Typography color="inherit" fontSize="14px" marginBottom="10px">
          {payInvoiceModalInfo.SubTitle}
        </Typography>
        {numberOfPendingInvoices > 0 ? (
          <Link to={`/billing/transactions`}>
            <Button variant="outlined" size="small">
              {payInvoiceModalInfo.btnText}
            </Button>
          </Link>
        ) : (
          <Typography color="inherit" fontSize="12px" marginBottom="10px">
            Please contact to your organisation
          </Typography>
        )}
      </>
    );
  }

  if (!isServiceEnabled) {
    return (
      <>
        <Typography color="inherit" marginBottom="5px">
          {creditCardModalInfo.mainTitle}
        </Typography>
        <Typography color="inherit" fontSize="14px" marginBottom="10px">
          {creditCardModalInfo.subTitle}
        </Typography>
        <Link to={`/billing/creditCard`}>
          <Button variant="outlined" size="small">
            {creditCardModalInfo.btnText}
          </Button>
        </Link>
      </>
    );
  }

  return null;
};

export { PaidServicesTooltip };
