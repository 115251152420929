import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { Typography, Stack, Ul, UlItem, Link } from "@financeable-com-au/financeable-ui";
import { ApplicationType } from "@/store/slices/types/applicationFormSlice";
import { CreditProvidersTable } from "../CreditProvidersTable";
import { CreditReportingBodies } from "../CreditReportingBodies";
import { organisationInfo } from "../constants/organisationInfo";

interface PrivacyStatementProps {
  applicationType: ApplicationType;
}

const PrivacyStatement = ({ applicationType }: PrivacyStatementProps) => {
  return (
    <Accordion style={{ position: "static", margin: 0 }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography level="body-md">Show Privacy Statement</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack rowGap={10}>
          <Stack rowGap={2}>
            <Typography level="title-sm">Overview</Typography>
            <Typography>
              This Privacy Disclosure Statement & E-mail Communication Consent is relevant to: {organisationInfo.name},
              AFSL Number: {organisationInfo.afsl}, ABN: {organisationInfo.abn}, Address: {organisationInfo.address}{" "}
              <strong>(we, us, our)</strong>.
            </Typography>
            <Typography>
              We collect and store information about you for the purposes you agree to in this Privacy Disclosure
              Statement and E-mail Communication Consent, subject to Australia’s privacy and credit reporting laws
              including the Privacy (Credit Reporting) Code 2024 (CR Code). Your credit and personal information will be
              handled in accordance with the CR Code.
            </Typography>
            <Typography>
              When you sign below, you agree we can, consistent with Australia’s privacy and credit reporting laws,
              collect, use and exchange credit and personal information about you for those purposes.
            </Typography>
          </Stack>
          <Stack rowGap={2}>
            <Typography level="title-sm">Privacy Disclosure Statement and E-mail Communication Consent</Typography>
            <Typography>
              We are collecting credit-related personal information <strong>(information)</strong> about you, as
              applicable:
            </Typography>
            <Ul>
              <UlItem>
                To source for you, or a company of which you are a director:
                <Ul>
                  <UlItem>Consumer credit for personal, household, domestic or residential investment purposes;</UlItem>
                  <UlItem>Commercial credit for business purposes; or</UlItem>
                  <UlItem>
                    Other services stated in this Privacy Disclosure Statement and E-mail Communication Consent
                    (Consent); or
                  </UlItem>
                </Ul>
              </UlItem>
              <UlItem>To support a guarantor application you will provide.</UlItem>
            </Ul>
            <Typography>
              As your broker, we require the information we collect from you to assess your credit or guarantor
              application, or the credit application of a company of which you are a director, source a suitable credit
              provider and any required insurances, and to manage the application process, where required. If you do not
              provide the information sought, we may be unable to process your application, or the company’s
              application, or we may be limited in the other services we can offer you or the company.
            </Typography>
          </Stack>
          <Stack rowGap={2}>
            <Typography level="title-sm">
              Your Information — Collection and Credit Reporting Body (Crb) Disclosures
            </Typography>
            <Typography>
              When we collect information from you in the credit application process, we use that information in a
              number of ways to assess your credit application and to source a suitable credit provider or lessor and/
              or insurance provider.
            </Typography>
            <Typography>We may:</Typography>
            <Ul>
              <UlItem>
                Disclose your identification information to a CRB and in some cases obtain a credit report;
              </UlItem>
              <UlItem>
                Use any information the CRB provides in that report to assist us to preliminarily assess your consumer
                credit or guarantor application;
              </UlItem>
              <UlItem>
                Disclose your personal information to an insurer or insurers to source any insurances you wish to
                obtain;
              </UlItem>
              <UlItem>
                Disclose your credit information to a credit provider or credit providers to apply for finance on your
                behalf; and
              </UlItem>
              <UlItem>
                Disclosure your personal information to an aggregator to enable submission of your credit application to
                the credit provider.
              </UlItem>
            </Ul>
            <Typography>
              The information we obtain from you is used, subject to compliance with Australia’s privacy and credit
              reporting laws including the CR Code, only for the purposes listed in this Consent and is not disclosed to
              any other person except with your permission or as permitted, or required, by law.
            </Typography>
          </Stack>
          <Stack rowGap={2}>
            <Typography level="title-sm">Credit Providers</Typography>
            <Typography>
              As part of providing our services to you, we may undertake tasks for a credit provider which is reasonably
              necessary to manage the application process. When doing so, we are acting as agent for the credit
              provider, with the same privacy law requirements applying to both of us.
            </Typography>
            <Typography>
              We may submit your application to one or more credit providers. Those credit providers and their website
              addresses are set out in the Schedule 1 at the end of this document.
            </Typography>
            <Typography>
              A credit provider, to whom we submit an application, may disclose information about you to, and collect
              information about you from, from one or more CRBs. Credit providers may also engage directly with a CRB to
              make a credit enquiry and you can ascertain the details on this and how they handle personal and credit
              information via their websites which are listed in our Schedule 1 – Credit Providers.
            </Typography>
            <Typography>
              The website of each credit provider contains details of each CRB with which it deals and other detail
              about information held about you, including whether that information may be held or disclosed overseas
              and, if so, in which countries. The websites also describe your key rights. This detail may be described
              on the credit providers’ websites as ‘notifiable matters’, ‘privacy policy’, ‘credit reporting policy’ or
              ‘privacy disclosure statement and consent’, or similar.
            </Typography>
            <Typography>For each CRB the website details will include the following specific information:</Typography>
            <Ul>
              <UlItem>
                That the CRB may include information the credit provider discloses about you to other credit providers
                to assess your credit worthiness;
              </UlItem>
              <UlItem>
                That, if you become overdue in making credit payments or you commit a serious credit infringement, the
                credit provider may disclose that information to a CRB
              </UlItem>
              <UlItem>
                How you can obtain the credit provider’s and/or CRB’s policies about managing your credit information;
              </UlItem>
              <UlItem>
                Your right to access and/or correct information held about you and to complain about conduct that may
                breach the privacy and credit reporting laws;
              </UlItem>
              <UlItem>
                Your right to request a CRB not to undertake pre- screening for purposes of direct marketing by a credit
                provider; and
              </UlItem>
              <UlItem>
                Your right to request a CRB not to release information about you if you believe you are a victim of
                fraud.
              </UlItem>
            </Ul>
            <Typography>
              Credit providers may provide your personal information (including information about your driver licence or
              passport) to an organisation providing verification of your identity, (including credit reporting bodies),
              to request an assessment of whether the information matches information held by the issuer of the
              identification document via the use of third-party systems (this may also include electronic identity
              verification). Alternative means of verifying your identity may be available.
            </Typography>
            <Typography>
              This detail will also be included by the credit provider who approves your application in the privacy
              disclosure statement and consent document it will provide to you.
            </Typography>
            <Typography>
              Each credit provider website includes information on how to contact the credit provider and how to obtain
              a copy of its privacy documents in a form that suits you (e.g. hardcopy or email).
            </Typography>
          </Stack>
          <Stack rowGap={2}>
            <Typography level="title-sm">Your Rights</Typography>
            <Typography>You have the right to ask:</Typography>
            <Ul>
              <UlItem>Us to provide you with all the information we hold about you;</UlItem>
              <UlItem>Us to correct the information we hold if it is inaccurate, incomplete and/or incorrect;</UlItem>
              <UlItem>
                Us for copies of our privacy policy and this document, in a form that suits you (e.g. hardcopy or
                email);
              </UlItem>
              <UlItem>
                The CRB not to use your information for direct marketing assessment purposes, including pre-screening;
                and
              </UlItem>
              <UlItem>The CRB to provide you with a copy of the information it holds about you.</UlItem>
            </Ul>
            <Typography>
              You can gain access to the information we hold about you by contacting our Privacy Officer at the address
              above or by telephone on 1300 887 754 or email at{" "}
              <Link href="mailto:compliance@cogfs.com.au">compliance@cogfs.com.au</Link> in some cases an administration
              fee may be charged to cover the cost of providing the information.
            </Typography>
            <Typography>You also agree and consent to, as appropriate:</Typography>
            <Ul>
              <UlItem>
                A CRB disclosing consumer credit information to one or more credit providers specified in the Schedule 1
                below for the purpose of assessing your application for consumer or commercial credit or your guarantor
                application, and/or assessing a credit application by a company of which you are a director;
              </UlItem>
              <UlItem>
                When you are a prospective guarantor, a credit provider using that information to assess your
                suitability as a guarantor;
              </UlItem>
              <UlItem>
                A credit provider disclosing your credit information (including information obtained by it from a CRB)
                to a guarantor, or a prospective guarantor; and
              </UlItem>
              <UlItem>
                A credit provider disclosing to another credit provider, to your agent, such as us as your broker, or to
                a servicer, for a particular purpose, information it holds about you.
              </UlItem>
            </Ul>
            <Typography>
              Our Privacy Policy is available on our website at{" "}
              <Link href="https://static1.squarespace.com/static/663c4907b3e3797d82082260/t/66d668f34daa0e68dec6cd25/1725327603877/MMP+Privacy+Policy+June+2024.pdf">
                Privacy Policy
              </Link>{" "}
              or we will provide you with a copy if you ask us. Our Privacy Policy contains information about how you
              may complain about a breach of your privacy and how that complaint will be dealt with. Schedule 2 at the
              end of this document sets out the contact details for each CRB service.
            </Typography>
            <Typography>
              In the event of a data breach involving your information, we will notify you as soon as practicable in
              accordance with our obligations under the CR Code.
            </Typography>
          </Stack>
          <Stack rowGap={2}>
            <Typography level="title-sm">Disclosure and Consent</Typography>
            <Typography>By signing below, you agree we may:</Typography>
            <Ul>
              <UlItem>
                Use your personal and credit information:
                <Ul>
                  <UlItem>
                    To assess your consumer or commercial credit and/or guarantee application and/or to assess a credit
                    application by a company of which you are a director;
                  </UlItem>
                  <UlItem>To source any finances you required;</UlItem>
                  <UlItem>To source any insurances you require; and</UlItem>
                  <UlItem>As the law authorises or requires.</UlItem>
                </Ul>
              </UlItem>
              <UlItem>
                Disclose to, and obtain from, any prospective credit provider or insurer, information about you that is
                reasonably necessary to obtain the finance and insurances you require;
              </UlItem>
              <UlItem>
                Check your information with the document issuer, such as an employer or accountant or official record
                holder for the purpose of confirming your identity and information you have provided;
              </UlItem>
              <UlItem>
                Obtain from, and disclose to, any third party, information about you, the applicant(s) or guarantor(s)
                that is reasonably necessary to assist you to obtain the finance and insurances required;
              </UlItem>
              <UlItem>
                Provide your information, including your credit report(s), to one or more of the credit providers
                specified in Schedule 1 so they can assess your application, or the application of a company of which
                you are a director, or your suitability as a guarantor;
              </UlItem>
              <UlItem>Provide credit information about you to a guarantor, or prospective guarantor;</UlItem>
              <UlItem>
                Provide you, or the company of which you are a director, with offers or information of other goods or
                services we, or any of our associated entities, may be able to provide to you or the company, unless you
                tell us not to;
              </UlItem>
              <UlItem>
                Disclose your personal and credit information to the extent permitted by law to other organisations that
                provide us with services, such as contractors, agents, printers, mail houses, lawyers, document
                custodians, securitisers and computer systems consultants or providers, so they can perform those
                services for us. This includes service providers located overseas. It is not practical to list every
                country in which recipients will be located, however it is likely to include, Germany, New Zealand,
                United Kingdom, Philippines, Singapore, Malaysia, Vietnam & United States of America; and
              </UlItem>
              <UlItem>
                Disclose your personal information to any other organisation that may wish to acquire, or has acquired,
                an interest in our business or any rights under your contract with us, or the contract with us of a
                company of which you are a director.
              </UlItem>
            </Ul>
          </Stack>
          <Stack rowGap={2}>
            <Typography level="title-sm">Extended Effectiveness for Commercial Credit</Typography>
            <Typography>
              Your agreement and consent to the disclosures and consents in this document will be effective for a period
              of 12 months, but only in relation to commercial credit. Your agreement to this ceases when you either
              withdraw it by contacting us using our details above or 12 months after you sign below, whichever first
              occurs. This will allow us to continue to provide our services to you without the need to ask you to sign
              a new privacy statement and consent each time you require commercial credit within a 12-month period. The
              extended effectiveness does not apply in relation to consumer credit. Where the applicant, or guarantor,
              is a company of which you are a director, you consent to the use of your information, in addition to the
              company’s information, in each of the ways listed above.
            </Typography>
          </Stack>
          <Stack rowGap={2}>
            <Typography level="title-sm">Effectiveness for Consumer Credit</Typography>
            <Typography>
              This authorisation ceases when we undertake a task on behalf of the credit provider.
            </Typography>
          </Stack>
          <Stack rowGap={2}>
            <Typography level="title-sm">South Australia</Typography>
            <Typography>
              For specific finance transactions we may arrange for you, we will tell you details of the amounts we
              receive or pay or are likely to receive or pay, or if not known at the time, how these amounts are
              calculated and/or reasonable estimate of the amounts, as well as who pays and receives these amounts.
            </Typography>
          </Stack>
          <Stack rowGap={2}>
            <Typography level="title-sm">Apppointment to Act</Typography>
            <Typography>
              I hereby appoint the above-named credit representative to act as my/our finance broker. This mandate
              confirms that the above-named credit representative has been appointed to collect, collate, and prepare
              relevant documentation and forward to potential lenders and/ or financial intermediaries.
            </Typography>
          </Stack>
          <Stack rowGap={2}>
            <Typography level="title-sm">Electronic Communication Consent</Typography>
            <Typography>By signing the below you acknowledge that:</Typography>
            <Ul>
              <UlItem>paper documents may no longer be given;</UlItem>
              <UlItem>
                you authorise us to provide you with documents, such as a Credit Guide, Quote for providing credit
                assistance, Credit Proposal Disclosure Document, this Privacy Disclosure Statement & E-mail
                Communication Consent and other information, by electronic communication to the e-mail address set out
                below (see the Authorisation section of this Privacy Disclosure Statement and E-mail Communication
                Consent);
              </UlItem>
              <UlItem>
                you agree to regularly check your e-mail address for documents we may have sent you by electronic
                communication; and
              </UlItem>
              <UlItem>
                consent to the provision of documents by electronic communication may be withdrawn at any time.
              </UlItem>
            </Ul>
            <Typography>
              You can cancel your consent to receive electronic communication at any time, by contacting us via e-mail
              at: <Link href="mailto:info@platform.com.au">info@platform.com.au</Link> or by phone 1300 730 856.
            </Typography>
          </Stack>
          <Stack rowGap={2}>
            <Typography level="title-sm">Authorisation</Typography>
            <Typography>
              By signing the below you also authorise us to make a request on your behalf to obtain credit reporting
              information about your consumer and commercial credit worthiness from a CRB. That information will assist
              us in providing our services to you. You give us your voluntary and explicit consent for the collection,
              use, and disclosure of your credit-related personal information in accordance with the CR Code.
            </Typography>
          </Stack>
          <CreditProvidersTable applicationType={applicationType} />
          <CreditReportingBodies />
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export { PrivacyStatement };
