import { combineReducers, configureStore } from "@reduxjs/toolkit";
// eslint-disable-next-line import/no-extraneous-dependencies
import createStore from "react-auth-kit/createStore";
// @ts-expect-error: existing js file
import storage from "redux-persist-indexeddb-storage";
import { createReduxEnhancer } from "@sentry/react";
// import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";
// @ts-expect-error: existing js file
import { formdataSlice } from "./slices/applicationFormSlice";
// @ts-expect-error: existing js file
import { applicationsSlice } from "./slices/applicationsSlice";
// @ts-expect-error: existing js file
import { customerSlice } from "./slices/customerSlice";
// @ts-expect-error: existing js file
import { userSlice } from "./slices/userSlice";
// @ts-expect-error: existing js file
import { usersSlice } from "./slices/usersSlice";
// @ts-expect-error: existing js file
import { organisationSlice } from "./slices/organisationSlice";
// @ts-expect-error: existing js file
import { applicationsAssetSlice } from "./slices/applicationAssetSlice";
// @ts-expect-error: existing js file
import { quoteSlice } from "./slices/quoteSlice";
// @ts-expect-error: existing js file
import { calculatorSlice } from "./slices/calculatorSlice";
// @ts-expect-error: existing js file
import { templatesSlice } from "./slices/templatesSlice";
// @ts-expect-error: existing js file
import { lenderApi } from "./slices/apiSlice";
// import { lenderChunksSlice } from "./slices/lenderChunkSlice";
// @ts-expect-error: existing js file
import { expirationMiddleware } from "./middleware/expirationMiddleware";
import { snackbarSlice } from "./slices/snackbarSlice";

const sentryReduxEnhancer = createReduxEnhancer();

const persistConfig = {
  key: "root",
  storage: storage("financeableDB"),
  // storage,
  whitelist: ["lenderApi"], // Add the slices you want to persist
  transforms: [
    {
      // @ts-expect-error: existing js file
      in: (state, key) => ({ ...state, timestamp: Date.now() }),
      // @ts-expect-error: existing js file
      out: (state, key) => state,
    },
  ],
};

const rootReducer = combineReducers({
  userDetails: formdataSlice.reducer,
  applications: applicationsSlice.reducer,
  assert: applicationsAssetSlice.reducer,
  user: userSlice.reducer,
  Quotes: quoteSlice.reducer,
  users: usersSlice.reducer,
  customer: customerSlice.reducer,
  organisation: organisationSlice.reducer,
  calculator: calculatorSlice.reducer,
  templates: templatesSlice.reducer,
  [lenderApi.reducerPath]: lenderApi.reducer,
  snackbar: snackbarSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const getStore = (isPublicRoute?: boolean) => {
  return configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => {
      if (isPublicRoute) {
        return getDefaultMiddleware({ serializableCheck: false }).concat();
      }
      return getDefaultMiddleware({ serializableCheck: false }).concat(lenderApi.middleware, expirationMiddleware);
    },
    enhancers: [sentryReduxEnhancer],
  });
};

const authStore = createStore({
  authName: "_auth",
  authType: "cookie",
  cookieDomain: window.location.hostname,
  cookieSecure: window.location.protocol === "https:",
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<ReturnType<typeof getStore>["getState"]>;
export type AppDispatch = ReturnType<typeof getStore>["dispatch"];

export { authStore, getStore };
