import { useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import InfoIcon from "@mui/icons-material/Info";
import { startCase, lowerFirst } from "lodash";
import {
  Stack,
  Typography,
  FormControl,
  FormLabel,
  Input,
  Select,
  Option,
  Button,
  Tooltip,
  IconButton,
} from "@financeable-com-au/financeable-ui";
import { type Fee, FeeFrequency } from "@/store/slices/types/applicationFormSlice";
import { DecimalMaskFormat } from "@/utils/DecimalMaskFormat";
import { useLenderCardContext } from "@/components/LenderProductCard/LenderCardContext";

interface FeeFieldsProps {
  saveQuote: ({ actionFor, value, key }: { actionFor: string; value?: Fee[] | FeeFrequency; key?: string }) => void;
}

const FeeFields = ({ saveQuote }: FeeFieldsProps) => {
  const {
    lenderCard: {
      fees: { maxOriginationFeeSet },
    },
    quoteCalculatorFormik,
  } = useLenderCardContext();

  // Save quote when the fees are added, removed or the frequency has changed.
  useEffect(() => {
    if (quoteCalculatorFormik?.values.lenderDefaultFees === false) {
      saveQuote({ actionFor: "fees" });
    }
  }, [
    quoteCalculatorFormik?.values.fees.length,
    quoteCalculatorFormik?.values.fees.map((fee) => fee.frequency).join(","),
  ]);

  return (
    <Stack direction="column" rowGap={3}>
      {quoteCalculatorFormik?.values.fees.map(({ name, value, key }, index) => {
        const isOriginationFee = key === "originationFee";
        const isReferralFee = key === "referralFee";
        return (
          <Stack direction="column" rowGap={0.5} key={key}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <FormLabel>
                {startCase(name)}
                {/* Tooltip to explain the maximum origination fee. */}
                {isOriginationFee && !!maxOriginationFeeSet && (
                  <Tooltip
                    title={
                      <Stack padding={2} flexDirection="column" rowGap={2}>
                        <Typography>
                          There is a Maximum Origination Fee of <Typography bold>{maxOriginationFeeSet}</Typography>
                        </Typography>
                      </Stack>
                    }
                  >
                    <IconButton aria-label="Tooltip" isRounded={true} size="sm" variant="inline">
                      <InfoIcon sx={{ fontSize: "1rem" }} />
                    </IconButton>
                  </Tooltip>
                )}
              </FormLabel>
              <Button
                variant="inline"
                size="sm"
                onClick={() => {
                  const selectedFee = quoteCalculatorFormik?.values.fees.find((fee) => fee.key === key);
                  const fees = quoteCalculatorFormik?.values.fees.filter((fee) => fee.key !== key);
                  const selectableFees = [...quoteCalculatorFormik?.values.selectableFeesList, selectedFee];
                  quoteCalculatorFormik?.setFieldValue("fees", fees);
                  quoteCalculatorFormik?.setFieldValue("selectableFeesList", selectableFees);
                  quoteCalculatorFormik?.setFieldValue("lenderDefaultFees", false);
                }}
              >
                Remove
              </Button>
            </Stack>
            <Stack direction="row" justifyContent="space-between" alignItems="center" columnGap={2}>
              <Input
                type="text"
                name={`fees.${index}.value`}
                startDecorator="$"
                endDecorator={
                  <Select
                    value={quoteCalculatorFormik?.values.fees[index].frequency}
                    variant="plain"
                    size="sm"
                    name={`fees.${index}.frequency`}
                    onChange={(_e, value) => {
                      if (!!value) {
                        quoteCalculatorFormik?.setFieldValue(`fees.${index}.frequency`, value);
                        quoteCalculatorFormik?.setFieldValue("lenderDefaultFees", false);
                      }
                    }}
                  >
                    <Option value={FeeFrequency.Upfront}>Upfront</Option>
                    <Option value={FeeFrequency.Financed}>Financed</Option>
                    <Option value={FeeFrequency.Monthly}>Monthly</Option>
                  </Select>
                }
                value={value}
                onChange={quoteCalculatorFormik?.handleChange}
                onBlur={(e) => {
                  quoteCalculatorFormik?.handleBlur(e);
                  saveQuote({ actionFor: "fees" });
                }}
                slotProps={{
                  input: {
                    component: DecimalMaskFormat,
                  },
                }}
                fullWidth
              />
              {isReferralFee && (
                <Input
                  placeholder="Referrer"
                  type="text"
                  name="referrer"
                  value={quoteCalculatorFormik?.values.referrer}
                  onChange={quoteCalculatorFormik?.handleChange}
                  onBlur={(e) => {
                    quoteCalculatorFormik?.handleBlur(e);
                  }}
                  fullWidth
                />
              )}
            </Stack>
          </Stack>
        );
      })}
      {(quoteCalculatorFormik?.values.selectableFeesList ?? []).length > 0 &&
        (quoteCalculatorFormik?.values.selectFee === "customFee" ? (
          <FormControl>
            <FormLabel>Custom Fee</FormLabel>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="stretch"
              alignContent="stretch"
              columnGap={3}
            >
              <Stack flexGrow={2}>
                <Input
                  type="text"
                  name="customFee"
                  value={quoteCalculatorFormik?.values.customFee}
                  onChange={quoteCalculatorFormik?.handleChange}
                  onBlur={quoteCalculatorFormik?.handleBlur}
                />
              </Stack>
              <Button
                disabled={!quoteCalculatorFormik?.values.customFee}
                variant="primary"
                intent="neutral"
                endDecorator={<AddIcon sx={{ fontSize: "1rem" }} />}
                onClick={() => {
                  const cutomFeeKey = lowerFirst(startCase(quoteCalculatorFormik?.values.customFee).replace(/\s/g, ""));
                  // Just in case the custom fee is already in the list, we remove it.
                  const selectableFees = quoteCalculatorFormik?.values.selectableFeesList.filter(
                    ({ key }) => key !== cutomFeeKey,
                  );
                  const customFee = {
                    name: quoteCalculatorFormik?.values.customFee ?? "",
                    key: cutomFeeKey,
                    value: 0,
                    frequency: FeeFrequency.Upfront,
                  };
                  const fees = [...quoteCalculatorFormik?.values.fees, customFee];
                  quoteCalculatorFormik?.setFieldValue("fees", fees);
                  quoteCalculatorFormik?.setFieldValue("selectableFeesList", selectableFees);
                  quoteCalculatorFormik?.setFieldValue("selectFee", "");
                  quoteCalculatorFormik?.setFieldValue("lenderDefaultFees", false);
                }}
              >
                Add Fee
              </Button>
            </Stack>
          </FormControl>
        ) : (
          <FormControl>
            <FormLabel>Select Fee</FormLabel>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="stretch"
              alignContent="stretch"
              columnGap={2}
            >
              <Stack flexGrow={2}>
                <Select
                  placeholder="Please choose one of the following"
                  value={quoteCalculatorFormik?.values.selectFee}
                  name="selectFee"
                  onChange={(_e, value) => {
                    if (!!value) {
                      quoteCalculatorFormik?.setFieldValue("selectFee", value);
                    }
                  }}
                >
                  {quoteCalculatorFormik?.values.selectableFeesList.map(({ name, key }) => (
                    <Option value={key} key={key}>
                      {startCase(name)}
                    </Option>
                  ))}
                  <Option value="customFee">
                    <Typography level="body-md" textColor="primary.200">
                      Add Custom Fee
                    </Typography>
                  </Option>
                </Select>
              </Stack>
              <Button
                disabled={!quoteCalculatorFormik?.values.selectFee}
                variant="primary"
                intent="neutral"
                endDecorator={<AddIcon sx={{ fontSize: "1rem" }} />}
                onClick={() => {
                  const selectedFee = quoteCalculatorFormik?.values.selectableFeesList.find(
                    ({ key }) => key === quoteCalculatorFormik?.values.selectFee,
                  );
                  const selectableFees = quoteCalculatorFormik?.values.selectableFeesList.filter(
                    ({ key }) => key !== quoteCalculatorFormik?.values.selectFee,
                  );
                  const fees = selectedFee
                    ? [...(quoteCalculatorFormik?.values.fees ?? []), selectedFee]
                    : quoteCalculatorFormik?.values.fees;
                  quoteCalculatorFormik?.setFieldValue("fees", fees);
                  quoteCalculatorFormik?.setFieldValue("selectableFeesList", selectableFees);
                  quoteCalculatorFormik?.setFieldValue("lenderDefaultFees", false);
                }}
              >
                Add Fee
              </Button>
            </Stack>
          </FormControl>
        ))}
    </Stack>
  );
};

export { FeeFields };
