import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import InfoIcon from "@mui/icons-material/Info";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import SimCardDownloadOutlinedIcon from "@mui/icons-material/SimCardDownloadOutlined";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import { merge, round } from "lodash";
import {
  Stack,
  Button,
  StatusExpandCard,
  useBreakpoints,
  Tooltip,
  Typography,
  IconButton,
  Divider,
} from "@financeable-com-au/financeable-ui";
import { SUCCESS_MESSAGE, ERRORS_MESSAGE } from "@/constants";
import {
  applicationSelector,
  getCurrentProductLenderId,
  getApplicationDownloadUrl,
  userDetailsSelector,
  loanDetailsSelector,
  updateLoanDetails,
  saveValue,
  setSubmissionModal,
  getApplicationDocuments,
  // @ts-expect-error: ignoring as it is existing js file
} from "@/store/slices/applicationFormSlice";
import {
  type ApplicationFormSlice,
  type ApplicationState,
  type UserDetailsState,
  type LoanDetails,
  type SubmissionModal,
  ApplicationType,
  InArrears,
} from "@/store/slices/types/applicationFormSlice";
// @ts-expect-error: ignoring as it is existing js file
import { userPermissionsSelector, userSelector } from "@/store/slices/userSlice";
import type { UserSlice, UserPermissionsDocument, UserState } from "@/store/slices/types/userSlice";
import { openSnackbar } from "@/store/slices/snackbarSlice";
import { useSubmissionSteps } from "@/components/ApplicationSubmissionModal/hooks/useSubmissionSteps";
import { LenderCardInfo } from "./LenderCardInfo";
import { CriteriaExpanded } from "./CriteriaExpanded";
import { QuoteCalculatorExpanded } from "./QuoteCalculatorExpanded";
import { useLenderCardCriteria, useLenderCardRequiredFields, useQuoteCalculatorForm } from "../hooks";
import { useLenderCardContext } from "../LenderCardContext";
import { formatFeesForSaveQuote } from "../utils";
import { DisclosureStatementModal } from "../DisclosureStatementModal";

interface LenderDetailForSubmission {
  submitType: SubmissionModal["submissionType"];
  lender: string;
  productDetails: {
    productName: string;
    productType: string;
  };
}

interface LenderCardDetailedProps {
  isCustomQuote: boolean;
}

const LenderCardDetailed = ({ isCustomQuote }: LenderCardDetailedProps) => {
  const { product, isSingleLenderProduct, lenderCard } = useLenderCardContext();
  const { showCriteria, setShowCriteria, showQuoteCalculator, setShowQuoteCalculator, setShowDisclosureStatement } =
    useLenderCardContext();
  const { smUp } = useBreakpoints();
  const { criteriaStatus } = useLenderCardCriteria();
  const permissions = useSelector<UserSlice, UserPermissionsDocument>(userPermissionsSelector);
  const application = useSelector<ApplicationFormSlice, ApplicationState>(applicationSelector);
  const loanDetails = useSelector<ApplicationFormSlice, LoanDetails>(loanDetailsSelector);
  // Use isQuoteOpen to determine if this is part of a quote flow.
  const { currentProductLenderId, downloadApplicationLoader, isQuoteOpen } = useSelector<
    ApplicationFormSlice,
    UserDetailsState
  >(userDetailsSelector);
  const isApplication = !isQuoteOpen;
  const { fieldsCheckedLength, fieldsTotalLength } = useLenderCardRequiredFields();
  const dispatch = useDispatch();
  const { lenderSettings } = useSelector<UserSlice, UserState>(userSelector);
  const isConfiguredForSubmit = lenderSettings?.some((setting) => setting.lender === product?.lender);

  const { constructSubmissionSteps } = useSubmissionSteps();
  const defaultInArrearsValue =
    application.applicationType === ApplicationType.Commercial ? InArrears.No : InArrears.Yes;

  useEffect(() => {
    if (isSingleLenderProduct) {
      setShowQuoteCalculator(false);
      setShowCriteria(true);
    }
  }, [isSingleLenderProduct]);

  const handleShowCriteria = () => {
    setShowQuoteCalculator(false);
    setShowCriteria(!showCriteria);
  };

  const handleShowQuoteCalculator = () => {
    setShowCriteria(false);
    setShowQuoteCalculator(!showQuoteCalculator);
  };

  const handleDownloadApplication = async ({ applicationId, lender }: { applicationId: string; lender: string }) => {
    dispatch(getCurrentProductLenderId(lender));
    dispatch(
      getApplicationDownloadUrl({
        applicationId,
      }),
      // @ts-expect-error: existing dispatch
    ).then(({ payload, error }) => {
      if (error) {
        dispatch(
          openSnackbar({
            message: payload.status_message || ERRORS_MESSAGE.fetchErrorMsg,
            intent: "danger",
          }),
        );
      } else {
        dispatch(
          openSnackbar({
            message: SUCCESS_MESSAGE.generatePdf,
            intent: "success",
          }),
        );
      }
    });
  };

  const handleApplicationSubmit = async ({ lender, submitType, productDetails }: LenderDetailForSubmission) => {
    // Save quote and update loan details.
    // Check if the custom quote's lender is the same as the lender that is being submitted.
    // If they are not the same then patch loan details with the lender product details.
    if (loanDetails?._id && loanDetails.lender !== product?.lender) {
      await dispatch(
        updateLoanDetails(
          merge({
            ...loanDetails,
            ...{
              lender: product?.lender,
              loanAmount: round(lenderCard.loanAmount.value ?? 0, 2),
              rate: lenderCard.rate.default.value,
              brokerage: lenderCard.brokerage.percentage.value,
              brokerageAmount: round(lenderCard.brokerage.amount.value ?? 0, 2),
              inArrears: defaultInArrearsValue,
              fees: formatFeesForSaveQuote(lenderCard.fees.application),
              repayments: round(lenderCard.repayments.monthly.value ?? 0, 2),
            },
          }),
        ),
      ).unwrap();
    }
    dispatch(saveValue({ applyApplication: null }));
    dispatch(getCurrentProductLenderId(lender));
    dispatch(getApplicationDocuments({ applicationId: application._id }));
    const submissionSteps = constructSubmissionSteps({ lenderSubmissionStep: product?.submissionSteps ?? [] });
    const submissionModal: SubmissionModal = {
      steps: submissionSteps,
      isOpen: true,
      submissionType: submitType,
      lender,
      productDetails,
      submission: {
        status: undefined,
        message: undefined,
      },
    };
    dispatch(setSubmissionModal(submissionModal));
  };

  const handleUploadApplication = ({ submitType }: { submitType: SubmissionModal["submissionType"] }) => {
    const lenderDetail: LenderDetailForSubmission = {
      submitType,
      lender: product?.lender ?? "",
      productDetails: {
        productName: product?.productName ?? "",
        productType: product?.productType ?? "",
      },
    };
    handleApplicationSubmit(lenderDetail);
  };

  const isSendDisclosureDisabled =
    !application.customers?.[0]?.emails?.find((email) => email.status === "current")?.address ||
    (application.applicationType !== ApplicationType.Personal && !application.asset?.assetValue) ||
    !permissions?.application?.edit;

  return (
    <>
      <StatusExpandCard status={criteriaStatus}>
        <LenderCardInfo isCustomQuote={isCustomQuote} />
        <Stack
          marginTop={5}
          alignItems={smUp ? "center" : "flex-start"}
          flexDirection={{ xs: "column-reverse", sm: "row" }}
          rowGap={smUp ? 0 : 4}
          justifyContent="space-between"
        >
          <Stack flexDirection="row" flexWrap="wrap" columnGap={3} rowGap={1}>
            <Button
              onClick={handleShowCriteria}
              variant="inline"
              endDecorator={
                <ExpandMoreIcon
                  sx={{
                    fontSize: "1rem",
                    transition: "transform 0.2s ease",
                    ...(showCriteria && { transform: "rotate(180deg)" }),
                  }}
                />
              }
            >
              Show Details
            </Button>
            {isApplication && (
              <Button
                onClick={handleShowQuoteCalculator}
                variant="inline"
                endDecorator={
                  <ExpandMoreIcon
                    sx={{
                      fontSize: "1rem",
                      transition: "transform 0.2s ease",
                      ...(showQuoteCalculator && { transform: "rotate(180deg)" }),
                    }}
                  />
                }
              >
                Customise Quote
              </Button>
            )}
          </Stack>
          {isApplication && (
            <Stack flexDirection="row" flexWrap="wrap" columnGap={3} rowGap={1}>
              <Stack flexDirection="row" alignItems="center">
                {isSendDisclosureDisabled && (
                  <Tooltip
                    title={
                      <Stack padding={2} flexDirection="column" rowGap={2}>
                        <Typography level="body-sm" bold>
                          Sending disclosure requires the following
                        </Typography>
                        <Divider />
                        <Stack flexDirection="column" rowGap={1}>
                          <Typography>
                            {application.applicationType === ApplicationType.Commercial ? "Guarantor" : "Borrower"}{" "}
                            email
                          </Typography>
                          {application.applicationType !== ApplicationType.Personal &&
                            !application.asset?.assetValue && <Typography>Asset value</Typography>}
                        </Stack>
                      </Stack>
                    }
                  >
                    <IconButton aria-label="Tooltip" isRounded={true} size="sm" variant="inline">
                      <InfoIcon sx={{ fontSize: "1rem" }} />
                    </IconButton>
                  </Tooltip>
                )}
                <Button
                  variant="secondary"
                  intent="neutral"
                  size="sm"
                  disabled={isSendDisclosureDisabled}
                  endDecorator={<SendOutlinedIcon sx={{ fontSize: "1rem" }} />}
                  onClick={() => {
                    setShowDisclosureStatement(true);
                  }}
                >
                  Send Disclosure
                </Button>
              </Stack>
              <Button
                variant="secondary"
                intent="neutral"
                size="sm"
                disabled={
                  !permissions?.application?.edit ||
                  (downloadApplicationLoader && currentProductLenderId === product?.lender) ||
                  fieldsCheckedLength !== fieldsTotalLength
                }
                endDecorator={<SimCardDownloadOutlinedIcon sx={{ fontSize: "1rem" }} />}
                onClick={() => {
                  handleDownloadApplication({ applicationId: application._id, lender: product?.lender || "" });
                }}
              >
                {downloadApplicationLoader && currentProductLenderId === product?.lender
                  ? "Downloading"
                  : "Download Application"}
              </Button>
              {product?.actions?.includes("email") ||
                (product?.actions?.includes("upload") && (
                  <Stack flexDirection="row" alignItems="center">
                    <Stack flexDirection="row" flexWrap="wrap" columnGap={3} rowGap={1}>
                      {product?.actions?.includes("email") && (
                        <Button
                          variant="primary"
                          intent="neutral"
                          size="sm"
                          disabled={fieldsCheckedLength !== fieldsTotalLength}
                          endDecorator={<UploadFileOutlinedIcon sx={{ fontSize: "1rem" }} />}
                          onClick={() => {
                            handleUploadApplication({ submitType: "email" });
                          }}
                        >
                          Email Application
                        </Button>
                      )}
                      {product?.actions?.includes("upload") && (
                        <Button
                          variant="primary"
                          intent="neutral"
                          size="sm"
                          disabled={fieldsCheckedLength !== fieldsTotalLength}
                          endDecorator={<UploadFileOutlinedIcon sx={{ fontSize: "1rem" }} />}
                          onClick={() => {
                            handleUploadApplication({ submitType: "upload" });
                          }}
                        >
                          Upload Application
                        </Button>
                      )}
                    </Stack>
                    {!isConfiguredForSubmit && (
                      <Tooltip
                        title={
                          <Stack padding={2} flexDirection="column" rowGap={2}>
                            <Typography level="body-sm" bold>
                              Looks like you're not setup with {product?.lender}.
                            </Typography>
                            <Typography mb={2}>Click on the link below to add your credentials.</Typography>
                            <Link to="/settings/lenders">
                              <Button variant="secondary" size="sm">
                                Add Credentials
                              </Button>
                            </Link>
                          </Stack>
                        }
                      >
                        <IconButton aria-label="Tooltip" isRounded={true} size="sm" variant="inline">
                          <InfoIcon sx={{ fontSize: "1rem" }} />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Stack>
                ))}
            </Stack>
          )}
        </Stack>
        <CriteriaExpanded />
        <QuoteCalculatorExpanded />
      </StatusExpandCard>
      <DisclosureStatementModal isCustomQuote={isCustomQuote} />
    </>
  );
};

export { LenderCardDetailed };
