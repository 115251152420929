import { Stack, Typography } from "@financeable-com-au/financeable-ui";
import { useLenderCardContext } from "@/components/LenderProductCard/LenderCardContext";

const DefaultRepayments = () => {
  const {
    product,
    lenderCard: { repayments, fees },
  } = useLenderCardContext();
  const { lender, productName } = product ?? {};

  return (
    <>
      <Stack flexDirection="row" columnGap={1} alignItems="center">
        <Typography level="body-md" bold component="h3" textColor="neutral.900">
          {lender}
        </Typography>
        <Typography level="body-sm">({productName})</Typography>
      </Stack>
      <Stack>
        <Typography level="title-md" component="h4">
          {repayments.monthly.formattedValue}
        </Typography>
        <Typography level="body-sm">({repayments.weekly.formattedValue})</Typography>
      </Stack>
      {fees.totalMonthly.formattedValue && (
        <Typography level="body-xs" textColor="neutral.500">
          Includes {fees.totalMonthly.formattedValue} monthly fees
        </Typography>
      )}
    </>
  );
};

export { DefaultRepayments };
