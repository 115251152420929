import { useState, useRef, MutableRefObject } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
// @ts-expect-error: ignoring as it is existing js file
import ReactSignatureCanvas from "react-signature-canvas";
import { Typography, Stack, Button } from "@financeable-com-au/financeable-ui";
// @ts-expect-error: ignoring as it is existing js file
import { submitPrivacy } from "@/store/slices/applicationFormSlice";
// @ts-expect-error: ignoring as it is existing js file
import getDeviceDetails from "@/utils/getDeviceDetails";

interface SignatureProps {
  setSubmitted: (submitted: boolean) => void;
}

const Signature = ({ setSubmitted }: SignatureProps) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  let sigCanvas = useRef();
  const [signature, setSignature] = useState(null);
  const [canSubmit, setCanSubmit] = useState(true);
  const urlSearchParams = new URLSearchParams(search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const customerId = params.cid;
  const applicationId = params.aid;
  const applicationType = params.applicationType;
  const jwt = params.jwt;
  const userId = params.uid;

  function trimSignature() {
    // @ts-expect-error: ignoring as it is existing js file
    setSignature(sigCanvas.getTrimmedCanvas().toDataURL("image/jpg"));
  }

  const handleSubmitPrivacy = async () => {
    const deviceDetails = btoa(JSON.stringify(getDeviceDetails()));
    setCanSubmit(false);
    await dispatch(
      submitPrivacy({
        customerId,
        applicationId,
        buffer: signature,
        applicationType,
        deviceDetails,
        originatorUserId: userId,
        jwt,
      }),
    ).unwrap();
    setCanSubmit(true);
    setSubmitted(true);
  };

  return (
    <Stack rowGap={4}>
      <Typography level="body-md" bold>
        I confirm that I am authorised to provide the personal details presented and I consent to my information being
        checked with the document issuer or official record holder for the purpose of confirming my identity.
      </Typography>
      <Typography level="body-md">Sign using the pad below:</Typography>
      <Stack direction="column" alignItems="flex-end" rowGap={4}>
        <ReactSignatureCanvas
          ref={(ref: MutableRefObject<undefined>) => (sigCanvas = ref)}
          penColor="#2946bb"
          clearOnResize={false}
          onEnd={() => trimSignature()}
          canvasProps={{
            className: "sigCanvas",
            style: {
              width: "100%",
              aspectRatio: "2/1",
              position: "relative",
              backgroundColor: "rgba(255, 255, 255, 1)",
              borderRadius: "8px",
              boxShadow: `0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)`,
            },
          }}
        />
        <Button
          variant="inline"
          size="lg"
          intent="neutral"
          onClick={() => {
            // @ts-expect-error: ignoring as it is existing js file
            sigCanvas.clear();
            setSignature(null);
          }}
        >
          Clear Signature
        </Button>
      </Stack>
      <Button disabled={!signature || !canSubmit} onClick={() => handleSubmitPrivacy()}>
        {!canSubmit ? "Submitting..." : "Accept Agreement"}
      </Button>
    </Stack>
  );
};

export { Signature };
