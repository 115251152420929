import type { ProgressBarStatus } from "@financeable-com-au/financeable-ui";
import type { RemainingDataFields } from "../LenderCardContext/types/LenderProductTypes";
import { useLenderCardContext } from "../LenderCardContext";

interface UseLenderCardRequiredFieldsResult {
  fieldsCheckedPercentage: number;
  fieldsCheckedLength: number;
  fieldsTotalLength: number;
  fieldsStatus: ProgressBarStatus;
  remainingDataFields?: RemainingDataFields;
}

const getRequiredFieldsStatus = (fieldsPercentage: number): ProgressBarStatus => {
  switch (true) {
    case fieldsPercentage >= 75:
      return "green";
    case fieldsPercentage >= 50:
      return "yellow";
    case fieldsPercentage >= 25:
      return "orange";
    case fieldsPercentage >= 0:
    default:
      return "red";
  }
};

const useLenderCardRequiredFields = (): UseLenderCardRequiredFieldsResult => {
  const { product } = useLenderCardContext();

  // If product is undefined, return default values.
  if (!product) {
    return {
      fieldsCheckedPercentage: 0,
      fieldsCheckedLength: 0,
      fieldsTotalLength: 0,
      fieldsStatus: getRequiredFieldsStatus(0),
    };
  }

  // Else get the required fields data.
  const { availableDataLength, totalLength, remainingDataFields } = product.requiredFieldsData;
  const fieldsCheckedPercentage = (availableDataLength / totalLength) * 100;

  return {
    fieldsCheckedPercentage: Number(fieldsCheckedPercentage.toFixed(2)),
    fieldsCheckedLength: availableDataLength,
    fieldsTotalLength: totalLength,
    fieldsStatus: getRequiredFieldsStatus(fieldsCheckedPercentage),
    remainingDataFields,
  };
};

export { useLenderCardRequiredFields };
