import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const lenderApi = createApi({
  reducerPath: "lenderApi",
  baseQuery: fetchBaseQuery({ baseUrl: import.meta.env.VITE_API_URL || "", credentials: 'include' }),
  tagTypes: ["commercial", "consumer", "personal"],
  endpoints: (builder) => ({
    getCommercialLenders: builder.query({
      query: () => ({
        url: "/lender",
        method: "POST",
        body: { type: "commercial" },
      }),
      providesTags: ["commercial"],
    }),
    getConsumerLenders: builder.query({
      query: () => ({
        url: "/lender",
        method: "POST",
        body: { type: "consumer" },
      }),
      providesTags: ["consumer"],
    }),
    getPersonalLenders: builder.query({
      query: () => ({
        url: "/lender",
        method: "POST",
        body: { type: "personal" },
      }),
      providesTags: ["personal"],
    }),
    getAllLenders: builder.query({
      query: () => ({
        url: "/lender",
        method: "POST",
        body: { limit: 10 },
      }),
    }),
  }),
});

export const {
  useGetCommercialLendersQuery,
  useGetConsumerLendersQuery,
  useGetPersonalLendersQuery,
  useGetAllLendersQuery,
} = lenderApi;
