import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Provider } from "react-redux";
import { setupListeners } from "@reduxjs/toolkit/query";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { getStore } from "@/store/store";
import { publicRoutes } from "@/constants";

interface MiddlewareProviderProps {
  children: React.ReactNode;
}

const MiddlewareProvider = ({ children }: MiddlewareProviderProps) => {
  const location = useLocation();

  const store = useMemo(() => {
    const isPublicRoute = publicRoutes.some((route) => location.pathname.startsWith(route));

    // Create new store instance with appropriate middleware
    return getStore(isPublicRoute);
  }, []);

  const persistor = persistStore(store);

  useEffect(() => {
    setupListeners(store.dispatch);
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {children}
      </PersistGate>
    </Provider>
  );
};

export { MiddlewareProvider };
