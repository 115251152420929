import { createContext, useContext } from "react";
import { defaultLenderCard } from "@/components/LenderProductCard/hooks/useLenderCard";
import { defaultLenderCardCustom } from "@/components/LenderProductCard/hooks/useLenderCardCustom";
import type { LenderProductContextType } from "./types/LenderProductTypes";

const LenderCardContext = createContext<LenderProductContextType>({
  product: undefined,
  lenderCard: defaultLenderCard,
  lenderCardCustom: defaultLenderCardCustom,
  quoteCalculatorFormik: undefined,
  showCriteria: false,
  showQuoteCalculator: false,
  isSingleLenderProduct: false,
  showDisclosureStatement: false,
  setShowCriteria: () => {},
  setShowQuoteCalculator: () => {},
  setShowDisclosureStatement: () => {},
  showRequiredFields: () => {},
});

const useLenderCardContext = () => {
  const context = useContext(LenderCardContext);
  if (!context) {
    throw new Error("useLenderContext must be used within LenderCardContext");
  }
  return context;
};

export { LenderCardContext, useLenderCardContext };
