import { useSelector, useDispatch } from "react-redux";
import ReplayIcon from "@mui/icons-material/Replay";
import { round } from "lodash";
import { Grid, Stack, Typography, StatusExpandContent, Button } from "@financeable-com-au/financeable-ui";
// @ts-expect-error: ignoring as it is existing js file
import { applicationSelector, updateLoanDetails } from "@/store/slices/applicationFormSlice";
import {
  type ApplicationFormSlice,
  type ApplicationState,
  type Fee,
  ApplicationType,
  FeeFrequency,
  InArrears,
} from "@/store/slices/types/applicationFormSlice";
import { useLenderCardContext } from "@/components/LenderProductCard/LenderCardContext";
import { RepaymentFrequency } from "@/components/LenderProductCard/hooks/useQuoteCalculatorForm";
import { formatFeesForSaveQuote, getSelectableFeesList } from "@/components/LenderProductCard/utils";
import { FeeFields } from "./FeeFields";
import { LoanFields } from "./LoanFields";

const QuoteCalculatorExpanded = () => {
  const { showQuoteCalculator, product, lenderCard, lenderCardCustom, quoteCalculatorFormik } = useLenderCardContext();
  const { lender } = product ?? {};
  const { applicationType, loanDetails } = useSelector<ApplicationFormSlice, ApplicationState>(applicationSelector);
  const dispatch = useDispatch();
  const defaultInArrearsValue = applicationType === ApplicationType.Commercial ? InArrears.No : InArrears.Yes;

  const saveQuote = ({ actionFor, value }: { actionFor: string; value?: string | Fee[] | FeeFrequency }) => {
    const defaultFields = {
      _id: loanDetails._id,
      lender,
      loanAmount: round(lenderCardCustom.loanAmount.value ?? 0, 2),
      repayments: round(lenderCardCustom.repayments.monthly.value ?? 0, 2),
    };
    if (quoteCalculatorFormik?.isValid) {
      switch (actionFor) {
        case "rate":
          dispatch(updateLoanDetails({ ...defaultFields, rate: lenderCardCustom.rate.default.value }));
          break;
        case "brokerage":
          dispatch(
            updateLoanDetails({
              ...defaultFields,
              brokerage: lenderCardCustom.brokerage.percentage.value,
              brokerageAmount: round(lenderCardCustom.brokerage.amount.value ?? 0, 2),
            }),
          );
          break;
        case "inArrears":
          dispatch(updateLoanDetails({ ...defaultFields, inArrears: value }));
          break;
        case "fees":
          dispatch(
            updateLoanDetails({
              ...defaultFields,
              fees: formatFeesForSaveQuote(lenderCardCustom.fees.application),
            }),
          );
          break;
        case "useLenderDefault":
          dispatch(
            updateLoanDetails({
              ...defaultFields,
              loanAmount: round(lenderCard.loanAmount.value ?? 0, 2),
              rate: lenderCard.rate.default.value,
              brokerage: lenderCard.brokerage.percentage.value,
              brokerageAmount: round(lenderCard.brokerage.amount.value ?? 0, 2),
              inArrears: defaultInArrearsValue,
              fees: formatFeesForSaveQuote(lenderCard.fees.application),
              repayments: round(lenderCard.repayments.monthly.value ?? 0, 2),
            }),
          );
          break;
        default:
          break;
      }
    }
  };

  const handleUseLenderDefault = () => {
    if (quoteCalculatorFormik) {
      quoteCalculatorFormik.setValues({
        rate: lenderCard.rate.default.value,
        brokerage: lenderCard.brokerage.percentage.value,
        inArrears: defaultInArrearsValue,
        repaymentFrequency: RepaymentFrequency.Monthly,
        fees: lenderCard.fees.application,
        selectFee: "",
        selectableFeesList: getSelectableFeesList(lenderCard.fees.application),
        referrer: "",
        lenderDefaultFees: true,
      });
    }
    saveQuote({ actionFor: "useLenderDefault" });
  };

  return (
    <StatusExpandContent expanded={showQuoteCalculator}>
      <Stack flexDirection="row" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography level="title-md" component="h5" mb={3}>
          Quote Calculator
        </Typography>
        <Button
          variant="secondary"
          intent="neutral"
          size="sm"
          endDecorator={<ReplayIcon sx={{ fontSize: "1rem" }} />}
          onClick={() => {
            handleUseLenderDefault();
          }}
        >
          Use Lender Default
        </Button>
      </Stack>
      <form onSubmit={quoteCalculatorFormik?.handleSubmit}>
        <Grid container columnSpacing={{ xs: 0, sm: 12 }} rowSpacing={{ xs: 4, sm: 0 }}>
          <Grid xs={12} sm={6}>
            <LoanFields saveQuote={saveQuote} />
          </Grid>
          <Grid xs={12} sm={6}>
            <FeeFields saveQuote={saveQuote} />
          </Grid>
        </Grid>
      </form>
    </StatusExpandContent>
  );
};

export { QuoteCalculatorExpanded };
