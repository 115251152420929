import { useSelector } from "react-redux";
import type { FormikProps } from "formik";
import { fCurrencyDecimal, fPercent } from "@/utils/formatNumber";
import { FeeFrequency, ApplicationType, InArrears } from "@/store/slices/types/applicationFormSlice";
import type {
  ApplicationFormSlice,
  Asset,
  LoanDetails,
  ApplicationState,
} from "@/store/slices/types/applicationFormSlice";
import {
  assetSelector,
  loanDetailsSelector,
  applicationSelector,
  // @ts-expect-error: ignoring as it is existing js file
} from "@/store/slices/applicationFormSlice";
import {
  type QuoteCalculatorFormFields,
  RepaymentFrequency,
} from "@/components/LenderProductCard/hooks/useQuoteCalculatorForm";
// @ts-expect-error: ignoring as it is existing js file
import calculateEffectiveRate from "@/utils/calculateEffectiveRate";
import type { UseLenderCardResult, CommonValue } from "./useLenderCard";
import {
  getTotalFeesByFrequency,
  calculateQuoteRepayments,
  getLoanAmount,
  getFinanceAmount,
  getRepaymentsByFrequency,
  getRepaymentFrequencyLabel,
  getFeesList,
  getFormattedWeeklyRepayments,
  getFormattedMonthlyRepayments,
} from "../utils";

interface UseLenderCardCustomProps {
  formik: FormikProps<QuoteCalculatorFormFields>;
}

type UseLenderCardResultOmitted = Omit<UseLenderCardResult, "repayments" | "brokerage" | "loadingList" | "infoList">;

interface UseLenderCardCustomResult extends UseLenderCardResultOmitted {
  repayments: {
    primary: CommonValue;
    secondary: CommonValue;
    monthly: CommonValue;
  };
  repaymentFrequency: RepaymentFrequency;
  brokerage: {
    percentage: CommonValue;
    amount: CommonValue;
  };
  referrer: string;
  inArrears: InArrears | undefined;
}

const defaultCommonValue: CommonValue = {
  value: undefined,
  formattedValue: "",
};

const defaultResult: UseLenderCardCustomResult = {
  repayments: {
    primary: defaultCommonValue,
    secondary: defaultCommonValue,
    monthly: defaultCommonValue,
  },
  rate: {
    default: defaultCommonValue,
    effective: defaultCommonValue,
    comparison: defaultCommonValue,
  },
  fees: {
    totalMonthly: defaultCommonValue,
    totalUpfront: defaultCommonValue,
    totalFinanced: defaultCommonValue,
    lenders: [],
    application: [],
    maxOriginationFeeSet: undefined,
  },
  loanAmount: defaultCommonValue,
  financeAmount: defaultCommonValue,
  brokerage: {
    percentage: defaultCommonValue,
    amount: defaultCommonValue,
  },
  documents: [],
  referrer: "",
  inArrears: undefined,
  repaymentFrequency: RepaymentFrequency.Monthly,
};

const useLenderCardCustom = ({ formik }: UseLenderCardCustomProps): UseLenderCardCustomResult => {
  const { assetValue } = useSelector<ApplicationFormSlice, Asset>(assetSelector);
  const {
    deposit,
    tradeIn,
    payout,
    balloon,
    term,
    requestedLoanAmount,
  } = useSelector<ApplicationFormSlice, LoanDetails>(loanDetailsSelector);
  const { applicationType } = useSelector<ApplicationFormSlice, ApplicationState>(applicationSelector);
  const netAssetValue = assetValue - deposit - tradeIn + payout;
  const {
    values: { fees, brokerage, rate, inArrears, repaymentFrequency, referrer },
  } = formik;
  const rateValue = Number(rate);
  const isInArrears = inArrears === InArrears.Yes;

  const isCommercialApplication = applicationType === ApplicationType.Commercial;

  // Fees.
  const feesList = getFeesList(fees);
  // Monthly fees.
  const totalMonthlyFees = getTotalFeesByFrequency({ fees, frequency: FeeFrequency.Monthly });
  const formattedTotalMonthlyFees = totalMonthlyFees ? fCurrencyDecimal(totalMonthlyFees) : undefined;
  // Upfront fees.
  const totalUpfrontFees = getTotalFeesByFrequency({ fees, frequency: FeeFrequency.Upfront });
  const formattedTotalUpfrontFees = fCurrencyDecimal(totalUpfrontFees);
  // Financed fees.
  const totalFinancedFees = getTotalFeesByFrequency({ fees, frequency: FeeFrequency.Financed });
  const formattedTotalFinancedFees = fCurrencyDecimal(totalFinancedFees);
  // Brokerage.
  const brokerageValue = Number(brokerage || 0);
  const formattedBrokerage = fPercent(brokerageValue);
  const brokerageAmount = netAssetValue * (brokerageValue / 100);
  const formattedBrokerageAmount = fCurrencyDecimal(brokerageAmount);
  // Loan amount.
  const loanAmount = getLoanAmount(applicationType, { totalFinancedFees, netAssetValue, requestedLoanAmount });
  const formattedLoanAmount = fCurrencyDecimal(loanAmount);
  // Finance amount.
  const financeAmount = getFinanceAmount(applicationType, {
    totalFinancedFees,
    netAssetValue,
    brokerageAmount,
    requestedLoanAmount,
  });
  const formattedFinanceAmount = fCurrencyDecimal(financeAmount);

  // Repayments.
  const monthlyRepayments = calculateQuoteRepayments({
    financeAmount,
    balloon,
    term,
    rate: rateValue,
    netAssetValue,
    isInArrears,
  });
  const monthlyRepaymentsWithMonthlyFees = monthlyRepayments + totalMonthlyFees;
  const repaymentsValue = getRepaymentsByFrequency({
    monthlyRepayments: monthlyRepaymentsWithMonthlyFees,
    repaymentFrequency,
  });
  const repaymentFrequencyLabel = getRepaymentFrequencyLabel(repaymentFrequency);
  const formattedRepayments = `${fCurrencyDecimal(repaymentsValue)}${repaymentFrequencyLabel}`;

  // Rate.
  const ratePercentage = fPercent(rateValue);
  const balloonAmount = (balloon / 100) * netAssetValue;
  // Effective rate.
  const effectiveRate = calculateEffectiveRate(
    term,
    monthlyRepayments,
    -(netAssetValue + totalFinancedFees),
    balloonAmount,
    isInArrears ? 0 : 1,
    rateValue / 100,
  );
  const effectiveRateValue = Number(effectiveRate.toFixed(2));
  const formattedEffectiveRate = fPercent(effectiveRateValue);
  // Present value.
  const presentValue = balloonAmount / Math.pow(1 + rateValue, term);
  // True loan amount.
  const trueLoanAmount = financeAmount - presentValue;
  // Comparison rate.
  const comparisonRate = calculateEffectiveRate(
    term,
    monthlyRepayments,
    -(trueLoanAmount - totalUpfrontFees - (isCommercialApplication ? brokerageAmount : 0)),
    balloonAmount,
    isInArrears ? 0 : 1,
    rateValue / 100,
  );
  const formattedComparisonRate = fPercent(Number(comparisonRate.toFixed(2)));

  const secondaryRepayments =
    repaymentFrequency === RepaymentFrequency.Monthly
      ? getFormattedWeeklyRepayments({
          repaymentsValue,
          repaymentFrequency,
        })
      : getFormattedMonthlyRepayments({
          repaymentsValue,
          repaymentFrequency,
        });

  return {
    repayments: {
      primary: {
        value: Number(repaymentsValue.toFixed(2)),
        formattedValue: formattedRepayments,
      },
      secondary: {
        value: undefined,
        formattedValue: secondaryRepayments,
      },
      monthly: {
        value: monthlyRepaymentsWithMonthlyFees,
        formattedValue: undefined,
      },
    },
    rate: {
      default: {
        value: rateValue,
        formattedValue: ratePercentage,
      },
      effective: {
        value: effectiveRateValue,
        formattedValue: formattedEffectiveRate,
      },
      comparison: {
        value: Number(comparisonRate.toFixed(2)),
        formattedValue: formattedComparisonRate,
      },
    },
    fees: {
      totalMonthly: {
        value: totalMonthlyFees,
        formattedValue: formattedTotalMonthlyFees,
      },
      totalUpfront: {
        value: totalUpfrontFees,
        formattedValue: formattedTotalUpfrontFees,
      },
      totalFinanced: {
        value: totalFinancedFees,
        formattedValue: formattedTotalFinancedFees,
      },
      lenders: undefined,
      application: feesList,
      maxOriginationFeeSet: undefined,
    },
    loanAmount: {
      value: loanAmount,
      formattedValue: formattedLoanAmount,
    },
    financeAmount: {
      value: financeAmount,
      formattedValue: formattedFinanceAmount,
    },
    repaymentFrequency,
    brokerage: {
      percentage: {
        value: brokerageValue,
        formattedValue: formattedBrokerage,
      },
      amount: {
        value: brokerageAmount,
        formattedValue: formattedBrokerageAmount,
      },
    },
    referrer,
    inArrears,
  };
};

export { useLenderCardCustom, defaultResult as defaultLenderCardCustom };
export type { UseLenderCardCustomResult };
