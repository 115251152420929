import { useSelector } from "react-redux";
import InfoIcon from "@mui/icons-material/Info";
import {
  Stack,
  Typography,
  ProgressBar,
  Tooltip,
  IconButton,
  Button,
  Divider,
} from "@financeable-com-au/financeable-ui";
import { upperFirst, lowerCase } from "lodash";
// @ts-expect-error: ignoring as it is existing js file
import { applicationSelector } from "@/store/slices/applicationFormSlice";
import {
  type ApplicationFormSlice,
  type ApplicationState,
  ApplicationType,
} from "@/store/slices/types/applicationFormSlice";
// @ts-expect-error: ignoring as it is existing js file
import { userPermissionsSelector } from "@/store/slices/userSlice";
import type { UserSlice, UserPermissionsDocument } from "@/store/slices/types/userSlice";
import { useLenderCardRequiredFields } from "../hooks";
import { useLenderCardContext } from "../LenderCardContext";

/** This is existing logic that was abstracted to a hook. It was previously in the ProductSelectr/Product.jsx file. */
const requiredFieldLabel = (fieldLabel: string[]) => {
  const isNumeric = (s: string) => /^[+-]?\d+(\.\d+)?$/.test(s);
  const isAlpha = (s: string) => /^[a-zA-Z]+$/.test(s);
  const map1 = fieldLabel.map((x) => {
    let temp = "";
    if (x.includes(".")) {
      const value = x.split(".")[1];
      if (value === "[0]") {
        temp = x.split(".")[2];
      } else {
        temp = value;
      }
    } else {
      temp = x;
    }
    if (temp.includes("_")) {
      const undrscrArray = temp.split("_");
      if (undrscrArray.length == 2) temp = undrscrArray[0];
      if (undrscrArray.length > 2) {
        let undrscrArray1 = undrscrArray.map((val) => {
          let resVal = "";
          if (isNumeric(val) || !isAlpha(val) || val.includes("customError")) {
            resVal = resVal;
          } else {
            if (val === "timeOfEmployment") {
              resVal = `${val} > 24`;
            } else {
              resVal = val;
            }
          }
          return resVal;
        });
        undrscrArray1 = undrscrArray1.filter((x) => x !== "");
        undrscrArray1 = undrscrArray1.reverse();
        temp = undrscrArray1.join(" ");
      }
    }
    return temp;
  });
  const map2: string[] = [];
  map1.forEach((x) => {
    if (!map2.includes(x)) map2.push(x);
    return x;
  });
  return map2;
};

const FieldProgress = () => {
  const { showRequiredFields } = useLenderCardContext();
  const { fieldsCheckedPercentage, fieldsCheckedLength, fieldsTotalLength, fieldsStatus, remainingDataFields } =
    useLenderCardRequiredFields();
  const permissions = useSelector<UserSlice, UserPermissionsDocument>(userPermissionsSelector);
  const { customers, applicationType, entities } = useSelector<ApplicationFormSlice, ApplicationState>(
    applicationSelector,
  );
  // ## TODO: Look into why this needs to be flattened, not sure about the interface here.
  const remainingDataFieldsEntity = remainingDataFields?.entity.flat() ?? [];
  const requiredFieldsCompleted = fieldsCheckedLength === fieldsTotalLength;

  return (
    <Stack direction="row" alignItems="center" columnGap={2} maxWidth="350px">
      <Typography level="body-xs">Fields</Typography>
      <ProgressBar value={fieldsCheckedPercentage} status={fieldsStatus} />
      <Typography level="body-xs" bold={true}>
        {fieldsCheckedLength}/{fieldsTotalLength}
      </Typography>
      {/* Tooltip of remaining required fields. */}
      <Tooltip
        title={
          <Stack padding={2} flexDirection="column" rowGap={2}>
            <Typography level="body-sm" bold>
              Required fields remaining
            </Typography>
            <Divider />
            {remainingDataFields && !requiredFieldsCompleted ? (
              <>
                <Stack sx={{ overflow: "auto", paddingRight: 2 }} maxHeight="200px" flexDirection="column" rowGap={3}>
                  <>
                    {(remainingDataFields.asset ?? []).length > 0 && (
                      <div>
                        <>
                          <Typography bold>Assets:</Typography>
                          {(remainingDataFields.asset ?? []).map((field) => (
                            <Typography key={field}>{upperFirst(lowerCase(field))}</Typography>
                          ))}
                        </>
                      </div>
                    )}
                    <div>
                      {customers?.length > 0 && remainingDataFields.customer.length > 0 && (
                        <>
                          <Typography bold>
                            {applicationType === ApplicationType.Commercial ? "Guarantor" : "Borrower"}:
                          </Typography>
                          {requiredFieldLabel(remainingDataFields.customer)?.map((field) => (
                            <Typography key={field}>{field}</Typography>
                          ))}
                        </>
                      )}
                    </div>
                    <div>
                      {entities?.length > 0 && remainingDataFieldsEntity.length > 0 && (
                        <>
                          <Typography bold>Entity:</Typography>
                          {requiredFieldLabel(remainingDataFieldsEntity).map((field) => (
                            <Typography key={field}>{field}</Typography>
                          ))}
                        </>
                      )}
                    </div>
                    <div>
                      {remainingDataFields.agreement?.length > 0 && (
                        <>
                          <Typography bold>Agreement:</Typography>
                          {remainingDataFields.agreement?.map((field) => (
                            <Typography key={field}>{upperFirst(lowerCase(field))}</Typography>
                          ))}
                        </>
                      )}
                    </div>
                  </>
                </Stack>
                <Button
                  onClick={() => showRequiredFields(remainingDataFields)}
                  disabled={!permissions?.application?.edit}
                  variant="secondary"
                  fullWidth
                >
                  Show Fields
                </Button>
              </>
            ) : (
              <Typography level="body-sm">Well done, you have completed all required fields!</Typography>
            )}
          </Stack>
        }
      >
        <IconButton aria-label="Tooltip" isRounded={true} size="sm" variant="inline">
          <InfoIcon sx={{ fontSize: "1rem" }} />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

export { FieldProgress };
