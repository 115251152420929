import React, { useState } from "react";
import { useDispatch } from "react-redux";
import InputMask from "react-input-mask";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
} from "@mui/material";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import { formatToISODate } from "@/utils/formatDate.ts";
import { ERRORS_MESSAGE } from "../../constants";
import regex from "../../utils/regex";
import { saveValue} from "../../store/slices/customerSlice";
import { customerLogin } from "../../store/slices/applicationFormSlice";


const CustomerLoginModel = ({
  customerId,
  applicationId,
  workOSOrgId,
  openCustomerLoginModel,
  handleCloseCustomerLoginModel,
}) => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const [allValues, setAllValues] = useState({
    email: "",
    dateOfBirth: "", // dob
  });

  const [fieldsError, setFieldsError] = useState({
    email: "",
    dateOfBirth: "", // dob
  });


  const isDisabled = Object.values(allValues).some(value => !value);
  const IsFieldsError = Object.values(fieldsError).some(value => value);

  const handle = {
    login: async () => {
      if (customerId && applicationId && workOSOrgId && !isDisabled && !IsFieldsError) {
        let data = {
          customerId,
          applicationId,
          workOSOrgId,
          email: allValues.email,
          dateOfBirth: formatToISODate(allValues.dateOfBirth),
        };
        dispatch(customerLogin(data)).then((res) => {
          if (res.payload?.data?.data?.token) {
            sessionStorage.setItem("token", res.payload?.data?.data?.token);
            // localStorage.removeItem("tokenExpired");
            // Close model when login success
            handleCloseCustomerLoginModel()
            dispatch(saveValue({isCustomerLogin: true}))
          } else {
            sessionStorage.removeItem("token");
            if (res.payload?.response_error) {
              enqueueSnackbar(res.payload?.status_message || ERRORS_MESSAGE.fetchErrorMsg, {
                variant: "error",
                autoHideDuration: 5000,
              });
            }
          }
        })
      }
    },
    onChangeFn: (value, name) => {
      if (name === "email") {
        if (regex.email.test(value.toLowerCase())) setFieldsError({
          ...fieldsError,
          email: '',
        });
        else {
          setFieldsError({
            ...fieldsError,
            email: 'Invalid email',
          })
        }
      }
      // else if (name === "dateOfBirth") {
      //   console.log("regex==>>", regex.customerDateOfBirthRegexOptional.test(value))
      //   if (regex.customerDateOfBirthRegexOptional.test(value)) setFieldsError({
      //     ...fieldsError,
      //     dateOfBirth: false,
      //   });
      //   else {
      //     setFieldsError({
      //       ...fieldsError,
      //       dateOfBirth: true,
      //     });
      //   }
      // }
      setAllValues({
        ...allValues,
        [name]: value,
      });
    },
    blurFn: (fieldName, value) => {

      let fieldErr = fieldsError[fieldName];

      if (fieldName === "dateOfBirth") {
        let isValid = dayjs(value, 'DD-MM-YYYY', true).isValid();

        if (isValid) {
          fieldErr = ""
        } else {
          fieldErr = "Invalid Date";
        }
      }
      setFieldsError((pre) => ({
        ...pre,
        [fieldName]: fieldErr,
      }));
    },
  };

  return (
    <div>
      <Dialog open={openCustomerLoginModel} onClose={handleCloseCustomerLoginModel} fullWidth maxWidth={"sm"} disableEscapeKeyDown={true}>

        <DialogTitle style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}>
          <Typography fontWeight="400" style={{ marginBottom: "10px" }}>
            Customer Sign In.
          </Typography>
        </DialogTitle>

        <DialogContent>
          {/* <DialogContentText>Please select contact</DialogContentText> */}

          <TextField
            fullWidth
            type="email"
            name="email"
            label="Email"
            margin="dense"
            variant="filled"
            style={{ marginBottom: '20px' }}
            value={allValues?.email}
            error={fieldsError?.email ? true : false}
            helperText={fieldsError?.email}
            onChange={(event) => handle.onChangeFn(event.target.value, "email")}
          />

          <InputMask
            mask="99-99-9999"
            value={allValues?.dateOfBirth || null}
            disabled={false}
            maskChar={null}
            onChange={(event) => {
              handle.onChangeFn(event?.target.value || null, "dateOfBirth");
            }}
            onBlur={(e) => handle.blurFn("dateOfBirth", e.target.value)}
          >
            {() => (
              <TextField
                label="Date of birth"
                size="small"
                fullWidth
                placeholder="DD-MM-YYYY"
                variant="filled"
                name="dateOfBirth"
                style={{ background: "#ffffff" }}
                error={fieldsError?.dateOfBirth ? true : false}
                helperText={fieldsError?.dateOfBirth}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          </InputMask>
        </DialogContent>
        <DialogActions style={{ padding: '0 20px 20px 0' }}>
          <Button
            disabled={isDisabled}
            onClick={handle.login}
            variant="contained"
          >
            Login
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default CustomerLoginModel