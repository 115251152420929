import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Stack, Typography, Grid, Skeleton, Card, CardActionArea, Divider, Button } from "@mui/material";
import { isEqual } from "lodash";
import { useSnackbar } from "notistack";
import { usePaidServices } from "@/hooks";
import { PaidServicesTooltip } from "@/components/PaidServicesTooltip";
import Document from "./Document";
import CustomCardSending from "./CustomCardSending";
import CreditScoreTypesSelector from "./CreditScoreTypesSelector";
import CreditScoreGradientGraph from "../customComponents/CreditScoreGradientGraph";
import setTicksAndCrosses from "../../utils/setTicksAndCrosses";
import { userSelector } from "../../store/slices/userSlice";
import {
  // getCreditProfile,
  getCreditFile,
  saveValue,
  userDetailsSelector,
  applicationSelector,
} from "../../store/slices/applicationFormSlice";
import {
  APPLICATION_TYPES,
  CLIENT_NAMES,
  ERRORS_MESSAGE,
  FILE_AWAITING_MESSAGE,
  VITE_PLATFORM_NAME,
  SUCCESS_MESSAGE,
  creditCardModalInfo,
  payInvoiceModalInfo,
} from "../../constants";
import { HtmlTooltip } from "../ProductSelector/Product";

const dollarStringOptions = {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
};

const CreditProfile = React.memo(({ customer, applicationId }) => {
  const { canUsePaidServices, isInvoicePending, isServiceEnabled } = usePaidServices();
  const dispatch = useDispatch();
  // const { permissions } = useSelector(userSelector);
  // const isDisable = permissions?.application?.edit;
  const applicationState = useSelector(applicationSelector);
  const userDetails = useSelector(userSelector);

  const { enqueueSnackbar } = useSnackbar();
  const creditProfile = customer?.creditProfile;
  const { gettingCreditProfile, creditScoreTypes, gettingEquifaxCreditFile } = useSelector(
    userDetailsSelector,
    isEqual,
  );

  // console.log("customer", customer?.creditProfile);
  // const licenceNumber = customer?.documents?.find((doc) =>
  //   doc.details?.licence_number ? doc.details?.licence_number : null,
  // )?.details?.licence_number;

  const disableButton =
    (!customer?.documents?.filter((doc) => doc.name === "Privacy agreement")?.[0] &&
      !customer?.documents?.filter(
        (doc) => doc.name === "SIGNED Credit Guide and Privacy Consent - Consumer Asset Finance and Personal Loans.pdf",
      )?.[0] &&
      !customer?.documents?.filter((doc) => doc.name === "SIGNED Privacy Consent - Asset Finance.pdf")?.[0]) ||
    !customer?.customerTitle ||
    !customer?.firstName ||
    !customer?.lastName ||
    (VITE_PLATFORM_NAME !== CLIENT_NAMES.BMA && !customer?.IDNumber) ||
    (VITE_PLATFORM_NAME !== CLIENT_NAMES.BMA &&
      applicationState?.applicationType !== APPLICATION_TYPES.PERSONAL &&
      !applicationState?.asset?.assetValue) ||
    creditScoreTypes?.length <= 0 ||
    !customer?.addresses?.[0] ||
    gettingCreditProfile;

  // const handleGetCreditProfile = () => {
  //   dispatch(
  //     getCreditProfile({
  //       customerId: customer._id,
  //       applicationId,
  //     }),
  //   );
  //   // console.log("identityVerification", identityVerification);
  // };

  const handleGetCreditFile = () => {
    // const creditScoreList = creditScoreTypes?.map(item => item.value)

    dispatch(saveValue({ gettingCreditProfile: true }));
    dispatch(
      getCreditFile({
        data: {
          customerId: customer._id,
          applicationId,
          creditScoreTypes,
        },
      }),
    ).then((res) => {
      if (res?.payload?.data && Object.keys(res?.payload?.data).length > 0) {
        enqueueSnackbar(SUCCESS_MESSAGE.creditFile, {
          variant: "success",
          autoHideDuration: 5000,
        });
      } else {
        enqueueSnackbar(res?.payload?.status_message || ERRORS_MESSAGE.fetchErrorMsg, {
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    });
  };

  // function getRiskFactorColor(text) {
  //   // console.log(text);
  //   if (text === "Greatly Increases Risk") return "error";
  //   if (text === "Moderately Increases Risk") return orange[700];
  //   if (text === "Marginally Increases Risk") return lime[800];
  // }

  function totalLimit(creditProfile) {
    const limit = [
      creditProfile?.accounts?.limitsOfAllConsumerAccounts,
      creditProfile?.accounts?.limitsOfCreditCards,
      creditProfile?.accounts?.limitsOfPersonalLoans,
      creditProfile?.accounts?.limitsOfMortgages,
      creditProfile?.accounts?.limitsOfRental,
      creditProfile?.accounts?.limitsOfOverdraft,
      creditProfile?.accounts?.limitsOfCardCards,
      creditProfile?.accounts?.limitsOfAuto,
      creditProfile?.accounts?.limitsOfTelco,
      creditProfile?.accounts?.limitsOfUtilities,
      creditProfile?.accounts?.limitsOfBNPLTransaction,
      creditProfile?.accounts?.limitsOfBNPLFacility,
    ].reduce((acc, limit) => {
      if (limit * 1 < 0) return acc;

      return limit * 1 + acc;
    }, 0);

    // console.log(limit);

    return limit;
  }

  useEffect(() => {
    if (customer?._id) {
      // console.log("getting credit profile");
      // handleGetCreditProfile(customer?._id);
    }
  }, []);

  const getScoreTitle = (scoreData) => {
    const scoreDetails = scoreData?.["vs:scorecard"];

    // Sample: Equifax One Score 1.0 Comprehensive Consumer
    const creditTitle =
      scoreDetails?.["vs:name"]?.["_text"] +
      " " +
      scoreDetails?.["vs:version"]?.["_text"] +
      " " +
      scoreDetails?.["vs:data-level"]?.["_text"] +
      " " +
      scoreDetails?.["vs:type"]?.["_text"];
    return creditTitle || "";
  };

  return (
    <>
      <Grid
        item
        xs={12}
        style={{
          margin: "0 0 30px",
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
          paddingBottom: "30px",
        }}
      >
        <Typography fontWeight={600} style={{ marginBottom: "15px" }}>
          Credit Profile
        </Typography>
        {/* <Button
          color="secondary"
          style={{ marginBottom: "5px" }}
          variant="outlined"
          disabled={
            (!licenceNumber || !customer.IDNumber) &&
            !customer.documents.find(
              (doc) => doc.name === "Privacy agreement"
            )
          }
          onClick={() => {
            handleGetCreditFile();
          }}
        >
          Get credit file
        </Button> */}
        {/* <br /> */}
        {creditProfile !== undefined && (!creditProfile?._id || !creditProfile?.isValidConsumerCreditReport) && (
          <Typography
            variant="subtitle1"
            fontWeight={600}
            textAlign={"center"}
            sx={{ padding: "20px", marginBottom: "20px" }}
          >
            No Equifax data available
          </Typography>
        )}

        {((creditProfile && !creditProfile?.isValidConsumerCreditReport) || !creditProfile) && (
          <Grid container spacing={2} mb={"20px"}>
            <Grid item xs={12}>
              {/* Equifax score id selector */}
              <CreditScoreTypesSelector customer={customer} application={applicationState} />
            </Grid>

            <Grid item xs={12} sm={6}>
              {/* Equifax Request Card */}
              <HtmlTooltip tooltipPadding={"20px"} title={canUsePaidServices ? null : <PaidServicesTooltip />}>
                <Card>
                  <CardActionArea
                    disableRipple={!canUsePaidServices || disableButton}
                    onClick={() => {
                      if (!canUsePaidServices || disableButton) return;
                      handleGetCreditFile();
                    }}
                  >
                    <CustomCardSending
                      title={"Get Credit file"}
                      disabled={!canUsePaidServices || disableButton}
                      description={` Get ${customer.firstName}'s credit report using Equifax Access Seeker.`}
                      imagePath={"/static/equifax-logo.png"}
                      loadingState={false}
                      financialState={false}
                      icon={false}
                      lastTitle={"Sending SMS link..."}
                    />
                  </CardActionArea>
                </Card>

                <Typography variant="caption">
                  {disableButton &&
                    `${!gettingEquifaxCreditFile && "Requires"}  
                ${!customer?.firstName && !customer?.lastName ? `${applicationState?.applicationType === APPLICATION_TYPES.COMMERCIAL ? "guarantor" : "borrower"} full name, ` : ""}
                ${VITE_PLATFORM_NAME !== CLIENT_NAMES.BMA && applicationState?.applicationType !== APPLICATION_TYPES.PERSONAL && !applicationState?.asset?.assetValue ? "Purchase price," : ""}
                ${!customer?.dateOfBirth ? `DOB, ` : ""}
                ${creditScoreTypes?.length <= 0 ? `Credit score type, ` : ""}
                ${!customer?.customerTitle ? `Title, ` : ""}
                ${!customer?.addresses?.[0] ? `address, ` : ""}
                ${VITE_PLATFORM_NAME !== CLIENT_NAMES.BMA && !customer?.IDNumber ? `licence number, ` : ""}
                ${
                  !customer?.documents?.filter((doc) => doc.name === "Privacy agreement")?.[0] &&
                  !customer?.documents?.filter(
                    (doc) =>
                      doc.name ===
                      "SIGNED Credit Guide and Privacy Consent - Consumer Asset Finance and Personal Loans.pdf",
                  )?.[0] &&
                  !customer?.documents?.filter((doc) => doc.name === "SIGNED Privacy Consent - Asset Finance.pdf")?.[0]
                    ? `signed privacy statement.`
                    : ""
                }`}
                </Typography>
              </HtmlTooltip>
            </Grid>
          </Grid>
        )}

        {gettingCreditProfile && (
          <>
            <Grid item container spacing={2} style={{ marginBottom: "10px", marginTop: "10px" }}>
              <Grid item sm={4}>
                <Skeleton variant="text" />
                <Skeleton variant="text" />
              </Grid>
              <Grid item sm={4}>
                <Skeleton variant="text" />
                <Skeleton variant="text" />
              </Grid>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item container>
                <Grid item sm={4}>
                  <Skeleton variant="text" />
                </Grid>
                <Grid item container>
                  <Grid item sm={7}>
                    <Skeleton variant="text" />
                  </Grid>
                  <Grid item sm={8}>
                    <Skeleton variant="text" />
                  </Grid>
                  <Grid item sm={9}>
                    <Skeleton variant="text" />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container>
                <Grid item sm={4}>
                  <Skeleton variant="text" />
                </Grid>
                <Grid item container>
                  <Grid item sm={7}>
                    <Skeleton variant="text" />
                  </Grid>
                  <Grid item sm={8}>
                    <Skeleton variant="text" />
                  </Grid>
                  <Grid item sm={10}>
                    <Skeleton variant="text" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}

        {creditProfile && creditProfile?.isValidConsumerCreditReport && (
          <>
            <Stack direction="row" spacing={3} divider={<Divider orientation="vertical" flexItem />}>
              <Grid item sm={7} md={6} xs={12}>
                {creditProfile?.scoreData?.[0] &&
                creditProfile?.scoreData?.[0]?.["vs:key-contributing-factors"]?.["vs:contributing-factor"]?.length >
                  0 ? (
                  <Grid item sm={12} xs={12}>
                    <Typography variant="subtitle2" fontWeight={600} style={{ marginBottom: 5 }}>
                      {/* Equifax One score */}
                      {getScoreTitle(creditProfile?.scoreData?.[0])}
                    </Typography>

                    <Grid container item md={12} sm={12}>
                      {creditProfile?.score && <CreditScoreGradientGraph score={creditProfile.score} />}
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container item md={12} sm={12}>
                    <Typography variant="h6" fontWeight={600}>
                      {creditProfile?.scoreData?.[0] &&
                        creditProfile?.scoreData?.[0]?.["vs:key-contributing-factors"]?.["vs:contributing-factor"]?.[
                          "vs:name"
                        ]?._text}
                    </Typography>
                  </Grid>
                )}

                {creditProfile?.scoreData?.[1] && (
                  <Grid item sm={12} xs={12} style={{ margin: "20px 0 10px" }}>
                    <Stack spacing={1} direction="row" justifyContent="space-between">
                      <Typography
                        variant="subtitle2"
                        fontWeight={600}
                        style={{
                          fontSize: "14px",
                        }}
                      >
                        {/* Comprehensive score: */}
                        {getScoreTitle(creditProfile?.scoreData?.[1])}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        fontWeight={600}
                        style={{
                          fontSize: "16px",
                          marginRight: "3px",
                        }}
                      >
                        {
                          // creditProfile?.scoreData?.filter(
                          //   (score) => score["vs:scorecard"]["_attributes"]["id"] === "VSA_2.0_XY_CR",
                          // )?.[0]?.["vs:score-masterscale"]["_text"]
                          creditProfile?.scoreData?.[1]?.["vs:score-masterscale"]["_text"]
                        }
                      </Typography>
                    </Stack>
                  </Grid>
                )}

                {creditProfile?.scoreData?.[2] && (
                  <Grid item sm={12} xs={12} margin={"10px 0 10px"}>
                    <Stack spacing={1} direction="row" justifyContent="space-between">
                      <Typography
                        variant="subtitle2"
                        fontWeight={600}
                        style={{
                          fontSize: "14px",
                        }}
                      >
                        {/* VedaScore 1.1: */}
                        {getScoreTitle(creditProfile?.scoreData?.[2])}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        fontWeight={600}
                        style={{
                          fontSize: "16px",
                          marginRight: "3px",
                        }}
                      >
                        {
                          // creditProfile?.scoreData?.filter(
                          //   (score) => score["vs:scorecard"]["_attributes"]["id"] === "VS_1.1_XY_NR",
                          // )?.[0]?.["vs:score-masterscale"]["_text"]
                          creditProfile?.scoreData?.[2]?.["vs:score-masterscale"]["_text"]
                        }
                      </Typography>
                    </Stack>
                  </Grid>
                )}

                <Grid
                  container
                  sm={12}
                  // spacing={2}
                  margin={"15px 0"}
                >
                  <Grid container item sm={12}>
                    <Grid item sm={12} xs={12}>
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="subtitle2" fontWeight={600}>
                          Age of file (years)
                        </Typography>
                        <Typography variant="body2">{(creditProfile?.ageOfFile / 12).toFixed(2) || "0"}</Typography>
                      </Stack>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="subtitle2" fontWeight={600}>
                          Adverse on file
                        </Typography>
                        <Typography variant="body2">
                          {creditProfile?.consumerAdverse === "Y" && "Yes"}
                          {creditProfile?.consumerAdverse === "N" && "No"}
                          {!creditProfile?.consumerAdverse && "N/A"}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="subtitle2" fontWeight={600}>
                          Credit enquiries
                        </Typography>
                        <Typography variant="body2">
                          {creditProfile?.enquiries?.["vs:credit-enquiry"]?.length || "0"}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="subtitle2" fontWeight={600}>
                          Accounts
                        </Typography>
                        <Typography variant="body2">{creditProfile?.accounts?.numberOfOpenAccounts || "0"}</Typography>
                      </Stack>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="subtitle2" fontWeight={600}>
                          Defaults
                        </Typography>
                        <Typography variant="body2">{creditProfile?.numberOfDefaults || "0"}</Typography>
                      </Stack>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="subtitle2" fontWeight={600}>
                          Total limit
                        </Typography>
                        <Typography variant="body2">
                          {totalLimit(creditProfile)?.toLocaleString("en-US", dollarStringOptions)}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>

                  {/* <Grid container item sm={6}>
                    <Grid item sm={12} xs={12}>
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="subtitle2" fontWeight={600}>
                          Age of file (years)
                        </Typography>
                        <Typography variant="body2">
                          {(creditProfile?.ageOfFile / 12).toFixed(2)}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="subtitle2" fontWeight={600}>
                          Adverse on file
                        </Typography>
                        <Typography variant="body2">
                          {creditProfile?.consumerAdverse === "Y" && "Yes"}
                          {creditProfile?.consumerAdverse === "N" && "No"}
                          {!creditProfile?.consumerAdverse && "N/A"}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="subtitle2" fontWeight={600}>
                          Credit enquiries
                        </Typography>
                        <Typography variant="body2">
                          {creditProfile?.enquiries.length}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="subtitle2" fontWeight={600}>
                          Accounts
                        </Typography>
                        <Typography variant="body2">
                          {creditProfile?.accounts.length}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item sm={12} xs={12} >
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="subtitle2" fontWeight={600}>
                          Defaults
                        </Typography>
                        <Typography variant="body2">
                          {creditProfile?.numberOfDefaults || "0"}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item sm={12} xs={12} >
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="subtitle2" fontWeight={600}>
                          Total limit
                        </Typography>
                        <Typography variant="body2">
                          {totalLimit(creditProfile)?.toLocaleString(
                            "en-US",
                            dollarStringOptions
                          )}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid> */}
                </Grid>
              </Grid>

              {creditProfile?.scoreData?.[0] &&
                creditProfile?.scoreData?.[0]?.["vs:key-contributing-factors"]?.["vs:contributing-factor"]?.length >
                  0 && (
                  <Grid item sm={5} md={6} xs={12}>
                    <Grid item xs={12} style={{ marginBottom: "15px" }}>
                      <Typography variant="subtitle2" fontWeight={600}>
                        Score key contributing factors
                      </Typography>
                    </Grid>
                    <Grid container item spacing={1}>
                      {creditProfile?.scoreData?.[0] &&
                        creditProfile?.scoreData?.[0]?.["vs:key-contributing-factors"]?.["vs:contributing-factor"]?.map(
                          (factor, index) => (
                            <Grid key={factor?.["vs:score-impactor"]?._text || index} container item md={12} sm={12}>
                              <Grid item sm={2} direction="row" style={{ paddingTop: "4px" }}>
                                <Typography variant="subtitle2">
                                  {factor?.["vs:score-impactor"]?._text
                                    ? setTicksAndCrosses(factor["vs:score-impactor"]._text)
                                    : ""}
                                </Typography>
                              </Grid>
                              <Grid item sm={10}>
                                <Stack>
                                  <Typography variant="subtitle2">{factor?.["vs:name"]?._text || ""}</Typography>
                                  <Typography variant="body2">{factor?.["vs:description"]?._text || ""}</Typography>
                                </Stack>
                              </Grid>

                              {/* <Typography
                            variant="body2"
                          color={getRiskFactorColor(
                            factor["vs:score-impactor"]._text
                          )}  
                          >
                          </Typography> */}
                            </Grid>
                          ),
                        )}
                    </Grid>
                  </Grid>
                )}
            </Stack>
          </>
        )}

        {customer?.documents?.filter((doc) => doc.name === "Credit file" || doc.name === "No record credit file")
          ?.length <= 0 &&
          gettingEquifaxCreditFile && (
            <Stack spacing={1} direction="row" margin={"20px 0 0 0"}>
              <Typography variant="caption">{FILE_AWAITING_MESSAGE.gettingEquifaxCreditPDFMsg}</Typography>
            </Stack>
          )}

        <Stack
          spacing={1}
          direction="row"
          style={{ margin: "0 0 20px" }}
          // divider={<Divider orientation="vertical" flexItem />}
        >
          {customer?.documents?.length > 0 &&
            customer?.documents
              ?.filter((doc) => doc.name === "Credit file" || doc.name === "No record credit file")
              .map((doc) => <Document document={doc} customer={customer} name="creditProfile" />)}
        </Stack>
      </Grid>
    </>
  );
});

export default CreditProfile;
