import { Stack, CircularProgress } from "@financeable-com-au/financeable-ui";

interface LoaderProps {
  text?: string;
}

const Loader = ({ text }: LoaderProps) => (
  <Stack alignItems="center" justifyContent="center" height="100vh" rowGap={2}>
    <CircularProgress />
    <span>{text ?? "Loading"}</span>
  </Stack>
);

export { Loader };
