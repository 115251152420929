import { useSelector } from "react-redux";
// @ts-expect-error: ignoring as it is existing js file
import { userSelector } from "@/store/slices/userSlice";
import type { UserSlice, UserState } from "@/store/slices/types/userSlice";

const usePaidServices = () => {
  const {
    is_invoice_pending: isInvoicePending,
    is_service_enable: isServiceEnabled,
    pending_invoices: numberOfPendingInvoices,
  } = useSelector<UserSlice, UserState>(userSelector);

  // If no invoice pending and service is enabled then we can use paid services.
  const canUsePaidServices = !isInvoicePending && isServiceEnabled;

  return {
    canUsePaidServices,
    isInvoicePending,
    isServiceEnabled,
    numberOfPendingInvoices,
  };
};

export { usePaidServices };
