import { useDispatch, useSelector } from "react-redux";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useFormik } from "formik";
import {
  Stack,
  Button,
  Typography,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
} from "@financeable-com-au/financeable-ui";
import * as yup from "yup";
// @ts-expect-error: ignoring as it is existing js file
import { setSubmissionModalOpen, set2faCode, applicationSelector } from "@/store/slices/applicationFormSlice";
import type { ApplicationFormSlice, ApplicationState } from "@/store/slices/types/applicationFormSlice";
// @ts-expect-error: ignoring as it is existing js file
import { updateLenderName } from "@/components/Utils/helperFunction";
import { SubmissionStepper } from "@/components/ApplicationSubmissionModal/SubmissionStepper";
import { useSubmissionSteps } from "@/components/ApplicationSubmissionModal/hooks";

const MFAVerification = () => {
  // MFA step from submission modal state.
  const { _id, submissionModal } = useSelector<ApplicationFormSlice, ApplicationState>(applicationSelector);
  const mfaStep = submissionModal.steps.find((step) => step.type === "mfa");
  const dispatch = useDispatch();
  const { proceedSubmissionSteps } = useSubmissionSteps();

  const handleCloseSubmissionModal = () => {
    dispatch(setSubmissionModalOpen({ isOpen: false }));
  };

  const handleVerify = ({ mfaCode }: { mfaCode: string }) => {
    dispatch(
      set2faCode({
        applicationId: _id,
        lenderType: updateLenderName(submissionModal.lender),
        lenderName: updateLenderName(submissionModal.lender).toLowerCase(),
        code: mfaCode,
      }),
    );
    proceedSubmissionSteps({ activeStep: "mfa" });
  };

  const formik = useFormik({
    initialValues: {
      mfaCode: "",
    },
    onSubmit: (values) => {
      handleVerify(values);
    },
    validationSchema: yup.object({
      mfaCode: yup.string().required("Please enter a code."),
    }),
    validateOnChange: false,
    validateOnBlur: false,
  });

  return (
    <Stack rowGap={10}>
      <SubmissionStepper activeStep="mfa" />
      <form onSubmit={formik.handleSubmit}>
        <Stack rowGap={6}>
          {mfaStep?.label && (
            <Typography component="h2" id="modal-title" level="title-md">
              {mfaStep.label}
            </Typography>
          )}
          <FormControl error={!!formik.errors.mfaCode}>
            <FormLabel>Lender verify multi-factor authentication code</FormLabel>
            <Input
              placeholder="Verify code"
              type="text"
              name="mfaCode"
              value={formik.values.mfaCode ?? ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {!!formik.errors.mfaCode && (
              <FormHelperText>
                <ErrorOutlineIcon sx={{ fontSize: "1rem" }} />
                {formik.errors.mfaCode}
              </FormHelperText>
            )}
          </FormControl>
          <Stack direction="row" columnGap={2}>
            <Button variant="secondary" onClick={handleCloseSubmissionModal} fullWidth>
              Cancel
            </Button>
            <Button variant="primary" fullWidth type="submit">
              Submit
            </Button>
          </Stack>
        </Stack>
      </form>
    </Stack>
  );
};

export { MFAVerification };
