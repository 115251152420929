import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { createTheme, ThemeProvider, StyledEngineProvider, responsiveFontSizes } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { FinanceableThemeProvider } from "@financeable-com-au/financeable-ui";
import { SnackbarProvider } from "notistack";
import { Snackbar } from "@/components/Snackbar";
import { MiddlewareProvider } from "@/store/MiddlewareContext";
import Router from "./routes";
import { Observability } from "./components/Observability";
import { useCustomTheme } from "./hooks";

// ----------------------------------------------------------------------
export default function App() {
  const { customPrimary } = useCustomTheme();
  const financeableMUITheme = createTheme({
    palette: {
      type: "light",
      primary: {
        main: customPrimary ? customPrimary["200"] : "#6A0BE3",
      },
      secondary: {
        main: "#111111",
      },
      background: {
        default: "rgb(251, 251, 251)",
        paper: "#ffffff",
      },
    },
    typography: {
      body1: {
        letterSpacing: "0.5px",
      },
      h1: {
        fontFamily: [
          "Cabin",
          "-apple-system",
          "BlinkMacSystemFont",
          '"Segoe UI"',
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
      },
      h2: {
        fontFamily: [
          "Cabin",
          "-apple-system",
          "BlinkMacSystemFont",
          '"Segoe UI"',
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
      },
      h3: {
        fontFamily: [
          "Cabin",
          "-apple-system",
          "BlinkMacSystemFont",
          '"Segoe UI"',
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
      },
      h4: {
        fontFamily: [
          "Cabin",
          "-apple-system",
          "BlinkMacSystemFont",
          '"Segoe UI"',
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
      },
      h5: {
        fontFamily: [
          "Cabin",
          "-apple-system",
          "BlinkMacSystemFont",
          '"Segoe UI"',
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
      },
      h6: {
        fontFamily: [
          "Cabin",
          "-apple-system",
          "BlinkMacSystemFont",
          '"Segoe UI"',
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
      },
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
    components: {
      MuiAccordion: {
        styleOverrides: {
          root: {
            boxShadow: "none",
            background: "none",
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            "&$expanded": {
              minHeight: 0,
            },
            background: "#f3f3f3",
            margin: 0,
          },
          expanded: {},
          content: {
            "&$expanded": {
              margin: 0,
              minHeight: 0,
            },
          },
        },
      },
    },
  });
  const theme = responsiveFontSizes(financeableMUITheme);
  return (
    <MiddlewareProvider>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <FinanceableThemeProvider themePrimary={customPrimary}>
            <SnackbarProvider maxSnack={3} style={{ height: "150px" }}>
              <CssBaseline />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Router />
                <Snackbar />
              </LocalizationProvider>
            </SnackbarProvider>
          </FinanceableThemeProvider>
        </ThemeProvider>
      </StyledEngineProvider>
      <Observability />
    </MiddlewareProvider>
  );
}
