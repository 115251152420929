import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Autocomplete, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useSnackbar } from "notistack";
import commercialProducts from "../../products/commercial";
import consumerProducts from "../../products/consumer";
import {
  applicationSelector,
  getApplicationDownloadUrl,
  sendDisclosure,
  updateLoanDetails,
  userDetailsSelector,
} from "../../store/slices/applicationFormSlice";
import {
  APPLICATION_TYPES,
  ERRORS_MESSAGE,
  VITE_ALLOWED_LENDER_LIST,
  SUCCESS_MESSAGE,
  dollarStringOptions,
  frequencyList,
  frequencyTypes,
} from "../../constants";

const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  (props) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#ffffff",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 400,
      fontSize: props.theme.typography.pxToRem(10),
      border: "1px solid #dadde9",
      borderRadius: "10px",
      padding: props.tooltipPadding,
    },
  }),
);

const RepaymentSection = React.memo(({ evaluatedQuoteFees }) => {
  const dispatch = useDispatch();
  const application = useSelector(applicationSelector);
  const { enqueueSnackbar } = useSnackbar();
  const [searchTerm, setSearchTerm] = useState(application?.loanDetails?.lender || "");
  const [repaymentData, setRepaymentData] = useState({
    frequency: frequencyTypes.Monthly,
    amount: evaluatedQuoteFees?.repayments || 0,
    // effectiveRate: evaluatedQuoteFees?.effectiveRate || 0,
    // brokerageAmount: evaluatedQuoteFees?.brokerageAmount || 0
  });

  useEffect(() => {
    setRepaymentData({
      frequency: frequencyTypes.Monthly,
      amount: evaluatedQuoteFees?.repayments || 0,
    });
  }, [evaluatedQuoteFees]);

  let lendersList = [];

  if (application?.applicationType === "commercial") {
    lendersList = [...new Map([...commercialProducts].map((l) => [l.lender, l])).values()];
  } else {
    lendersList = [...new Map([...consumerProducts].map((l) => [l.lender, l])).values()];
    lendersList.push({ lender: "AFS" });
    lendersList.push({ lender: "Metro" });
    lendersList.push({ lender: "Azora Asset Finance" });
    // lendersList.push({ lender: "Latitude" });
  }

  const filteredLenderList =
    lendersList?.length > 0
      ? lendersList.flat().filter((product) => VITE_ALLOWED_LENDER_LIST.includes(product.lender))
      : [];

  const { url, downloadApplicationLoader, disclosureLoader } = useSelector(userDetailsSelector);

  const handle = {
    searchOnChange: (event, value, reason) => {
      if (reason === "clear") {
        setSearchTerm("");
        return;
      }
      setSearchTerm(value?.lender);
      dispatch(updateLoanDetails({ _id: application?.loanDetails?._id, lender: value?.lender }));
    },
    onApplicationDownload: async ({ applicationId }) => {
      dispatch(
        getApplicationDownloadUrl({
          applicationId: applicationId,
        }),
      ).then((res) => {
        if (res?.payload?.data?.data) {
          enqueueSnackbar(SUCCESS_MESSAGE.generatePdf, {
            variant: "success",
            autoHideDuration: 5000,
          });
        } else {
          enqueueSnackbar(ERRORS_MESSAGE.fetchErrorMsg, {
            variant: "error",
            autoHideDuration: 5000,
          });
        }
      });
    },
    submitDisclosure: async ({ applicationId, lenderType }) => {
      const { repayments, financeAmount, brokerageAmount, feesTotal, quoteCalculatorField, effectiveRate } =
        evaluatedQuoteFees;
      const { fees, brokerage } = quoteCalculatorField;

      // Helper function to find a fee value by key
      const getFeeValue = (key) => +fees?.find((feeObj) => feeObj?.key === key)?.value || 0;

      const customQuoteFields = {
        rate: quoteCalculatorField?.rate,
        // repayments,
        repayments: repaymentData?.amount || 0,
        repaymentFrequency: repaymentData?.frequency || 0,
        financeAmount,
        originationFee: getFeeValue("originationFee"),
        inspectionFee: getFeeValue("inspectionFee"),
        applicationFee: getFeeValue("documentFee"),
        effectiveRate: +effectiveRate || 0,
        registrationFee: getFeeValue("registrationFee"),
        establishmentFee: getFeeValue("establishmentFee"),
        accountFee: getFeeValue("accountFee"),
        referralFee: getFeeValue("referralFee"),
        monthlyFee: getFeeValue("monthlyFee"),
        quotesData: {
          brokerage: brokerage || 0,
          brokerageAmount,
          feesTotal,
        },
      };

      await dispatch(
        updateLoanDetails({
          _id: application?.loanDetails?._id,
          brokerage: brokerage || 0,
          repayments,
        }),
      ).unwrap();

      dispatch(
        sendDisclosure({
          ...customQuoteFields,
          ...{
            applicationId: applicationId,
            lenderType: lenderType,
          },
        }),
      ).then((res) => {
        if (res?.payload?.data) {
          enqueueSnackbar(res?.payload?.data?.status_message, {
            variant: "success",
            autoHideDuration: 5000,
          });
        } else {
          enqueueSnackbar(ERRORS_MESSAGE.fetchErrorMsg, {
            variant: "error",
            autoHideDuration: 5000,
          });
        }
      });
    },
    updateRepaymentFrequency: (type) => {
      let repaymentAmount = evaluatedQuoteFees?.repayments;
      let annualRepaymentAmount = +repaymentAmount * 12;

      switch (type) {
        case frequencyTypes.Weekly:
          repaymentAmount = annualRepaymentAmount / 52;
          break;
        case frequencyTypes.Fortnightly:
          repaymentAmount = annualRepaymentAmount / 26;
          break;
        case frequencyTypes.Monthly:
          repaymentAmount = repaymentAmount * 1;
          break;
        case frequencyTypes.Annually:
          repaymentAmount = annualRepaymentAmount;
          break;

        default:
          break;
      }

      setRepaymentData({
        frequency: type,
        amount: repaymentAmount,
      });
    },
  };

  const disableDisclosureBtn =
    !application?.customers?.[0]?.emails?.find((email) => email.status === "current")?.address ||
    (application?.applicationType !== APPLICATION_TYPES.PERSONAL && !application?.asset?.assetValue) ||
    !searchTerm ||
    disclosureLoader;

  return (
    <div>
      <Grid container spacing={1} sm={12} direction="row" style={{ margin: "10px 0px 7px 0px" }}>
        {/* Lender List */}
        <Grid item sm={12} spacing={1} direction="row">
          <Autocomplete
            fullWidth
            sx={{ margin: "0 0 10px 0" }}
            options={filteredLenderList || []}
            value={searchTerm}
            onChange={handle.searchOnChange}
            getOptionLabel={(lenderItem) => lenderItem?.lender || ""}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                label="Search lender"
                variant="filled"
                inputProps={{
                  ...params.inputProps,
                  value: searchTerm,
                }}
                onChange={handle.searchOnChange}
              />
            )}
          />
        </Grid>

        {/* Select Repayment frequency */}
        <Grid item sm={12} spacing={1} direction="row" style={{ margin: "0px 0px 7px 0px" }}>
          <FormControl fullWidth variant="filled">
            <InputLabel fullWidth id="freq-label">
              Repayment frequency
            </InputLabel>
            <Select
              fullWidth
              size="small"
              labelId="freq-label"
              id="freq-select"
              value={repaymentData?.frequency}
              label="Repayment frequency"
              onChange={(event) => {
                handle.updateRepaymentFrequency(event.target.value);
              }}
            >
              {frequencyList.map((i) => (
                <MenuItem value={i.value}>{i.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Calculations */}
        <Grid item sm={12} spacing={1} direction="row" style={{ margin: "0px 0px 7px 0px" }}>
          <Typography
            sx={{ display: "inline" }}
            variant="body2"
            style={{
              marginRight: "5px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="body2" fontWeight={"bold"}>
              Repayments:
            </Typography>
            {/* <span>{evaluatedQuoteFees?.repayments?.toLocaleString("en-US", dollarStringOptions) || "0"}/Mth</span> */}
            <span>{repaymentData?.amount?.toLocaleString("en-US", dollarStringOptions) || "0"}</span>
          </Typography>
        </Grid>
        {application?.applicationType === APPLICATION_TYPES.COMMERCIAL && (
          <Grid item sm={12} spacing={1} direction="row" style={{ margin: "0px 0px 7px 0px" }}>
            <Typography
              sx={{ display: "inline" }}
              variant="body2"
              style={{
                marginRight: "5px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="body2" fontWeight={"bold"}>
                Effective Rate:
              </Typography>
              <span>{(evaluatedQuoteFees?.effectiveRate).toFixed(2)}%</span>
            </Typography>
          </Grid>
        )}
        {application?.applicationType === APPLICATION_TYPES.COMMERCIAL && (
          <Grid item sm={12} spacing={1} direction="row" style={{ margin: "0px 0px 7px 0px" }}>
            <Typography
              sx={{ display: "inline" }}
              variant="body2"
              style={{
                marginRight: "5px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="body2" fontWeight={"bold"}>
                Brokerage:
              </Typography>

              <span>{evaluatedQuoteFees?.brokerageAmount?.toLocaleString("en-US", dollarStringOptions) || "0"}</span>
            </Typography>
          </Grid>
        )}
        <Grid item sm={12} spacing={1} direction="row" style={{ margin: "0px 0px 7px 0px" }}>
          <Typography
            sx={{ display: "inline" }}
            variant="body2"
            style={{
              marginRight: "5px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="body2" fontWeight={"bold"}>
              Finance amount:
            </Typography>

            <span>{evaluatedQuoteFees?.financeAmount?.toLocaleString("en-US", dollarStringOptions) || "0"}</span>
          </Typography>
          <Typography variant="caption">(included financed fees)</Typography>
        </Grid>
        <Grid item sm={12} spacing={1} direction="row" style={{ margin: "0px 0px 7px 0px" }}>
          <Typography
            sx={{ display: "inline" }}
            variant="body2"
            style={{
              marginRight: "5px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="body2" fontWeight={"bold"}>
              Total amount payable:
            </Typography>

            <span>{evaluatedQuoteFees?.loanPayableAmount?.toLocaleString("en-US", dollarStringOptions) || "0"}</span>
          </Typography>
        </Grid>
        <Grid item sm={12} spacing={1} direction="row" style={{ margin: "0px 0px 7px 0px" }}>
          {/* <Typography variant="caption">Total fees(excl. monthly)</Typography> */}
          <Typography
            sx={{ display: "inline" }}
            variant="body2"
            style={{
              marginRight: "5px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="body2" fontWeight={"bold"}>
              Total financed fees:
            </Typography>

            <span>{evaluatedQuoteFees?.feesTotal?.toLocaleString("en-US", dollarStringOptions) || "0"}</span>
          </Typography>
        </Grid>
        {/* Download Application and Send disclosure */}
        <Grid item sm={12} xs={12} spacing={1} direction="row" style={{ margin: "10px 0px 7px 0px" }}>
          <Grid item sm={12} xs={12} spacing={1}>
            <HtmlTooltip
              tooltipPadding={"14px"}
              disableHoverListener={
                application?.customers?.length > 0 &&
                searchTerm &&
                application?.customers?.[0]?.emails?.[0]?.address &&
                application?.asset?.assetValue
              }
              title={
                <React.Fragment>
                  {(application?.customers?.length <= 0 || !application?.customers?.[0]?.emails?.[0]?._id) && (
                    <Typography color="inherit" marginBottom="5px">
                      Please add{" "}
                      {application?.applicationType === APPLICATION_TYPES.COMMERCIAL ? "guarantor" : "borrower"} email.
                    </Typography>
                  )}
                  {!searchTerm && (
                    <Typography color="inherit" marginBottom="5px">
                      Please select lender.
                    </Typography>
                  )}
                  {application?.applicationType !== APPLICATION_TYPES.PERSONAL && !application?.asset?.assetValue && (
                    <Typography color="inherit" marginBottom="5px">
                      Please add asset value.
                    </Typography>
                  )}
                </React.Fragment>
              }
            >
              <span>
                <LoadingButton
                  fullWidth
                  color="secondary"
                  loadingPosition="end"
                  variant="contained"
                  size="large"
                  style={{
                    padding: "0 10px",
                    minHeight: "auto",
                  }}
                  disabled={disableDisclosureBtn}
                  onClick={async () => {
                    handle.submitDisclosure({ applicationId: application._id, lenderType: searchTerm });
                  }}
                >
                  {disclosureLoader ? "Sending Disclosure ..." : "Send Disclosure"}
                </LoadingButton>
              </span>
            </HtmlTooltip>
          </Grid>
          <Grid item sm={12} xs={12} spacing={1} style={{ margin: "10px 0px" }}>
            <LoadingButton
              fullWidth
              color="secondary"
              loadingPosition="end"
              variant="contained"
              size="large"
              style={{
                padding: "0 10px",
                minHeight: "auto",
              }}
              disabled={
                downloadApplicationLoader
                // || availableDataLength !== totalLength
              }
              onClick={async () => {
                handle.onApplicationDownload({
                  applicationId: application._id,
                });
              }}
              href={url}
            >
              {downloadApplicationLoader ? "Downloading ..." : "Download application"}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
});

export default RepaymentSection;
