import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DateField } from "@mui/x-date-pickers";
import moment from "moment";
import dayjs from "dayjs";
import { formatToReadableDate, formatToISODate } from "@/utils/formatDate";
import {
  customersSelector,
  saveCustomer,
  updateCustomer,
} from "../../../store/slices/applicationFormSlice";

const QuoteDateOfBirth = ({ disabled }) => {
  const dispatch = useDispatch();
  const { applicationId } = useParams();
  const customers = useSelector(customersSelector);
  const [dateOfBirth, setDateOfBirth] = useState(null);

  function updateDateOfBirth(value) {
    dispatch(saveCustomer({
      customerId: customers[0]?._id,
      dateOfBirth: formatToISODate(value),
    }))

    dispatch(updateCustomer({
      data: {
        customerId: customers[0]?._id,
        data: {
          dateOfBirth: formatToISODate(value),
        }
      }
    }))
  }

  useEffect(() => {
    if (customers?.[0]?.dateOfBirth) {
      setDateOfBirth(formatToReadableDate(customers?.[0]?.dateOfBirth))
    }
  }, [customers?.[0]?.dateOfBirth]);

  return (
    <>
      <DateField
        label="Date of birth"
        size="small"
        format="DD-MM-YYYY"
        variant="filled"
        value={dateOfBirth || null}
        style={{ background: "#ffffff" }}
        onChange={(event) => {
          setDateOfBirth(event?.$d || null)
        }}
        onBlur={() => updateDateOfBirth(dateOfBirth)}
        disabled={disabled}
      />
    </>
  )
}

export default QuoteDateOfBirth 