import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import type { ProgressBarStatus } from "@financeable-com-au/financeable-ui";
import type { CriteriaResult } from "../LenderCardContext/types/LenderProductTypes";
import { useLenderCardContext } from "../LenderCardContext";

/**
 * Types.
 */
interface CriteriaResultWithIcon extends CriteriaResult {
  icon: React.ReactNode;
}

interface UseLenderCardCriteriaResult {
  criteriaCheckedPercentage: number;
  criteriaCheckedLength: number;
  criteriaTotalLength: number;
  criteriaStatus: ProgressBarStatus;
  criteraList: CriteriaResultWithIcon[];
}

const getCriteriaStatus = (criteriaRemaining?: number): ProgressBarStatus => {
  switch (criteriaRemaining) {
    case 0:
      return "green";
    case 1:
    case 2:
      return "yellow";
    case 3:
    case 4:
      return "orange";
    case 5:
    default:
      return "red";
  }
};

const getCriteriaIcon = (result: boolean | undefined) => {
  if (result === true) {
    return <CheckCircleIcon sx={{ fontSize: "1rem", color: "var(--joy-palette-success-200)" }} />;
  }
  if (result === false) {
    return <CancelIcon sx={{ fontSize: "1rem", color: "var(--joy-palette-danger-200)" }} />;
  }
  return <RemoveCircleIcon sx={{ fontSize: "1rem", color: "var(--joy-palette-neutral-500)" }} />;
};

const sortCriteriaList = (criteriaResults: CriteriaResult[]) => {
  return [...criteriaResults].sort((firstItem, secondItem) => {
    if (firstItem.result && firstItem.result !== secondItem.result) return -1;
    if (firstItem.result === undefined && secondItem.result) return 1;
    if (secondItem.result === undefined && !firstItem.result) return 1;
    if (firstItem.result === secondItem.result) return 0;
    if (!secondItem.result) return -1;
    return 0;
  });
};

const getCriteriaList = (criteriaResults: CriteriaResult[]) => {
  return criteriaResults.map((criteria) => {
    return {
      ...criteria,
      icon: getCriteriaIcon(criteria.result),
    };
  });
};

const useLenderCardCriteria = (): UseLenderCardCriteriaResult => {
  const { product } = useLenderCardContext();

  // If product is undefined, return default values.
  if (!product) {
    return {
      criteriaCheckedPercentage: 0,
      criteriaCheckedLength: 0,
      criteriaTotalLength: 0,
      criteriaStatus: getCriteriaStatus(),
      criteraList: [],
    };
  }

  // Else, get the criteria results and calculate the percentage of criteria checked.
  const { criteriaResults } = product;

  const criteriaChecked = criteriaResults.filter(({ result }) => !!result);
  const criteriaCheckedPercentage = (criteriaChecked.length / criteriaResults.length) * 100;
  const criteriaRemaining = criteriaResults.length - criteriaChecked.length;
  const criteriaStatus = getCriteriaStatus(criteriaRemaining);
  const criteraList = getCriteriaList(sortCriteriaList(criteriaResults));

  return {
    criteriaCheckedPercentage: Number(criteriaCheckedPercentage.toFixed(2)),
    criteriaCheckedLength: criteriaChecked.length,
    criteriaTotalLength: criteriaResults.length,
    criteriaStatus,
    criteraList,
  };
};

export { useLenderCardCriteria };
