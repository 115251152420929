import { useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import ReactSignatureCanvas from "react-signature-canvas";
import LoadingButton from "@mui/lab/LoadingButton";
import { Accordion, Box, AccordionDetails, AccordionSummary, Button, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Grid } from "@financeable-com-au/financeable-ui";
import { useFeatureFlags } from "@/utils/featureFlags/useFeatureFlags";
import { PrivacyCreditGuide } from "@/components/PrivacyCreditGuide";
import { APPLICATION_TYPES } from "../constants";
import { submitPrivacy } from "../store/slices/applicationFormSlice";
import getDeviceDetails from "../utils/getDeviceDetails";

const lenderList = [
  { name: 'Advantdge Financial Services', link: 'www.advantedge.com.au' },
  { name: 'La Trobe Financial', link: 'www.latrobefinancial.com.au' },
  { name: 'Affordable Car Loans', link: 'www.affordablecarloans.com.au' },
  { name: 'Latitude Financial', link: 'www.latitudefinancial.com.au/privacy/' },
  { name: 'Angle Finance', link: 'www.anglefinance.com.au' },
  { name: 'Liberty Financial', link: 'www.liberty.com.au' },
  { name: 'ANZ Bank', link: 'www.anz.com.au' },
  { name: 'Macquarie Bank', link: 'www.macquarie.com.au' },
  { name: 'Australian Motorcycle & Marine Finance', link: 'www.ammf.com.au' },
  { name: 'Australian Premier Finance', link: 'www.australianpremierfinance.com.au' },
  { name: 'Medfin', link: 'www.medfin.com.au' },
  { name: 'Metro CF Pty Ltd', link: 'www.metrofin.com.au' },
  { name: 'Automotive Financial Services', link: 'www.afs.com.au' },
  { name: 'Money 3', link: 'www.money3.com.au' },
  { name: 'Azora Finance', link: 'www.azorafinance.com' },
  { name: 'MoneyMe Pty Ltd t/as Auto Pay', link: 'www.autopay.com.au' },
  { name: 'Banjo Loans', link: 'www.banjoloans.com' },
  { name: 'MoneyPlace', link: 'www.moneyplace.com.au' },
  { name: 'Bank of Queensland', link: 'www.boq.com.au' },
  { name: 'Morris Finance', link: 'www.morrisfinance.com.au' },
  { name: 'Bendigo & Adelaide Bank', link: 'www.bendigoadelaide.com.au' },
  { name: 'Metro', link: 'www.metrofin.com.au' },
  { name: 'Better Choice Home Loans', link: 'www.betterchoice.com.au' },
  { name: 'Moula', link: 'www.moula.com.au' },
  { name: 'Branded Financial Services', link: 'www.brandedfinancial.com.au' },
  { name: 'Multipli', link: 'www.multipli.com' },
  { name: 'Better Mortgage Management', link: 'www.bettermm.com.au' },
  { name: 'NAB', link: 'www.nab.com.au' },
  { name: 'Capital Finance', link: 'www.capitalfinance.com.au' },
  { name: 'Now Finance', link: 'nowfinance.com.au' },
  { name: 'Credit Corp Financial Services t/as CarStart Finance', link: 'www.carstart.com.au' },
  { name: 'Ondeck', link: 'www.ondeck.com.au' },
  { name: 'CommBank', link: 'www.commbank.com.au' },
  { name: 'Pepper Money', link: 'www.pepper.com.au' },
  { name: 'DOX AI', link: 'http://accounts.doxai.co' },
  { name: 'Plenti', link: 'www.plenti.com.au' },
  { name: 'Earlypay', link: 'www.earlypay.com.au' },
  { name: 'Prospa', link: 'www.prospa.com' },
  { name: 'Equifax', link: 'www.equifax.com.au' },
  { name: 'Rapid Loans Pty Ltd', link: 'www.rapidloans.com.au' },
  { name: 'FASTLend', link: 'www.fastlend.com.au' },
  { name: 'RAMS', link: 'www.rams.com.au' },
  { name: 'Financeable', link: 'https://financeable.com.au' },
  { name: 'Redbook Inspect', link: 'www.redbookinspect.com.au' },
  { name: 'Finance One', link: 'www.financeone.com.au' },
  { name: 'Redzed', link: 'www.redzed.com' },
  { name: 'Firstmac', link: 'www.firstmac.com.au' },
  { name: 'Resimac Asset Finance', link: 'www.resimacassetfinance.com.au' },
  { name: 'Flexfleet', link: 'www.flexfleet.com.au' },
  { name: 'Saleskey', link: 'www.saleskey.com.au' },
  { name: 'Flexi commercial pty ltd', link: 'www.flexicommercial.com.au' },
  { name: 'Scottish Pacific', link: 'www.scottishpacific.com' },
  { name: 'Get Capital', link: 'www.getcapital.com.au' },
  { name: 'Selfco', link: 'www.selfco.com.au' },
  { name: 'Greenlight Auto', link: 'www.greenlightauto.finance' },
  { name: 'SocietyOne', link: 'www.societyone.com.au' },
  { name: 'Group and General Finance', link: 'www.alto.com.au' },
  { name: 'Westpac Bank', link: 'www.westpac.com.au' },
  { name: 'Grow Finance', link: 'www.growfinance.com.au' },
  { name: 'Wisr', link: 'www.wisr.com.au' },
  { name: 'Heritage Bank', link: 'www.heritage.com.au' },
  { name: 'Volkswagen Financial Services', link: 'www.vwfs.com.au' },
  { name: 'Illion', link: 'www.illion.com.au' },
  { name: 'Integrity Car Care', link: 'www.iwarranty.com.au' },
  { name: 'Judobank:', link: 'www.judo.bank' }
]

const brokerMutualDetails = [
  { key: 'Credit Licence', value: 'Brokers Mutual Acceptance Pty Ltd' },
  { key: 'ABN', value: '20 121 876 953' },
  { key: 'ACN', value: '121 876 953' },
  { key: 'Credit Licence Number', value: '388249' },
  { key: 'Trading Address', value: '5/6 Somerset Ave Narellan NSW 2567' },
  { key: 'Mailing Address', value: 'PO Box 680 Narellan NSW 2567' },
  { key: 'Phone', value: '02 4647 5666' },
  { key: 'Email', value: 'info@brokersmutual.com.au' },
]

export default function Privacy() {
  const { lenderSubmissionUplift } = useFeatureFlags();
  const dispatch = useDispatch();
  const { search } = useLocation();
  let sigCanvas = useRef();
  const [signature, setSignature] = useState();
  const [submitted, setSubmitted] = useState(false);
  const [canSubmit, setCanSubmit] = useState(true);

  const urlSearchParams = new URLSearchParams(search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const customerId = params.cid;
  const applicationId = params.aid;
  // const entityName = params.en;
  // const abn = params.abn;
  // const acl = params.acl;
  const applicationType = params.applicationType;
  const jwt = params.jwt;
  const userId = params.uid;

  function trimSignature() {
    setSignature(sigCanvas.getTrimmedCanvas().toDataURL("image/jpg"));
  }

  async function handleSubmitPrivacy() {
    const deviceDetails = btoa(JSON.stringify(getDeviceDetails()));
    setCanSubmit(false);
    await dispatch(
      submitPrivacy({
        customerId,
        applicationId,
        buffer: signature,
        applicationType,
        deviceDetails,
        originatorUserId: userId,
        jwt,
      }),
    ).unwrap();
    setCanSubmit(true);
    setSubmitted(true);
  }
  return (
    <Grid
      container
      item
      xs={12}
      style={{
        padding: "30px 30px 0",
        background: "#efefef",
        minHeight: "calc(100vh - 64px)",
      }}
    >
      <Grid container item xs={12} justifyContent="center">
        <Grid item direction="column" xs={12} sm={12} md={10} lg={6} xl={6}>
          {!submitted && !lenderSubmissionUplift && (
            <>
              <Typography
                variant="h1"
                style={{
                  fontSize: "24px",
                  marginBottom: "20px",
                }}
              >
                Privacy Disclosure Statement
                {applicationType === "Consumer" && `& Credit Guide`}
              </Typography>
              <Typography
                variant="h2"
                style={{
                  fontSize: "18px",
                  marginBottom: "30px",
                }}
              >
                Please read the privacy statement
                {applicationType === "Consumer" && `and credit guide`}
                then sign at the bottom.
              </Typography>

              <Accordion style={{ marginBottom: "40px" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={{ padding: "5px 30px" }}
                >
                  <Typography>Show privacy statement</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ padding: "20px 30px" }}>
                  <Typography>
                    Application for Credit – Privacy Consents/ Acknowledgments Privacy Act 1988 (Cth) and all related
                    rules and regulations (“Act”)
                  </Typography>
                  <Typography style={{ padding: "20px 0" }}>
                    To:
                    <br />
                    Brokers Mutual Acceptance Pty Ltd  ABN: 20 121 876 953 (“You”)
                  </Typography>
                  <Typography style={{ padding: "20px 0" }}>
                    <span style={{ fontWeight: 800 }}> WHO ARE WE </span><br />
                    ‘We’, ‘us’ and ‘our’ refer to BROKERS MUTUAL ACCEPTANCE PTY LTD, ACN 121876953, Australian Credit Licence 388249 and
                    our related businesses.
                  </Typography>
                  <Typography style={{ padding: "20px 0" }}>
                    <span style={{ fontWeight: 800 }}>
                      OUR COMMITMENT TO PROTECT YOUR PRIVACY
                    </span><br />
                    We understand how important it is to protect your personal information. This document sets out our privacy policy
                    commitment in respect of personal information we hold about you and what we do with that information. We recognise that
                    any personal information we collect about you will only be used for the purposes we have collected it or as allow ed under the
                    law. It is important to us that you are confident that any personal information we hold about you will be treated in a way
                    which ensures protection of your personal information. Our commitment in respect of personal information is to abide by the
                    Australian Privacy Principles for the protection of personal information, as set out in the Privacy Act and any other relevant
                    law.
                  </Typography>

                  <Typography style={{ padding: "20px 0" }}>
                    <span style={{ fontWeight: 800 }}>
                      PERSONAL INFORMATION
                    </span><br />
                    When we refer to personal information we mean information from which your identity is reasonably apparent. This
                    information may include information or an opinion about you. The personal information we hold about you may also include
                    credit information. Credit information is information which is used to assess your eligibility to be provided with finance and
                    may include any finance that you have outstanding, your repayment history in respect of those loans, and any defaults.Usually, credit information is exchanged betw een credit and finance providers and credit reporting bodies. The kinds of
                    personal information we may collect about you include your name, date of birth, address, account details, occupation, and
                    any other information we made need to identify you. If you are applying for finance we may also collect the ages and number
                    of your dependants and cohabitants, the length of time at your current address, your employment details and proof of
                    earnings and expenses. If you apply for any insurance product through us we may also collect your health information. We
                    will only collect health information from you with your consent.
                  </Typography>
                  <Typography style={{ padding: "20px 0" }}>
                    <span style={{ fontWeight: 800 }}>
                      WHY WE COLLECT YOUR PERSONAL INFORMATION
                    </span><br />
                    We collect personal information for the purposes of assessing your application for finance and managing that finance. We
                    may also collect your personal information for the purposes of direct marketing and managing our relationship with you. From
                    time to time we may offer you other products and services. To enable us to maintain a successful business relationship with
                    you, we may disclose your personal information to other organisations that provide products or services used or marketed by
                    us. We may also disclose your personal information to any other organisation that may have or is considering having an
                    interest in your finance, or in our business.
                  </Typography>
                  <Typography style={{ padding: "20px 0" }}>
                    <span style={{ fontWeight: 800 }}>
                      HOW DO WE COLLECT YOUR PERSONAL INFORMATION
                    </span><br />
                    Where reasonable and practical we will collect your personal information directly from you. We may also collect your personal
                    information from finance brokers and other people such as accountants and lawyers.
                  </Typography>
                  <Typography style={{ padding: "20px 0" }}>
                    <span style={{ fontWeight: 800 }}>
                      DO WE DISCLOSE YOUR PERSONAL INFORMATION
                    </span><br />
                    <ul>
                      <li>to prospective funders or other intermediaries in relation to your finance requirements;</li>
                      <li>to other organisations that are involved in managing or administering your finance such as third party suppliers, printing and postal services, call centres;</li>
                      <li>to associated businesses that may want to market products to you;</li>
                      <li>to companies that provide information and infrastructure systems to us;</li>
                      <li>to anybody who represents you, such as finance brokers, lawyers and accountants;</li>
                      <li>to anyone, where you have provided us consent;</li>
                      <li>where we are required to do so by law, such as under the Anti-Money Laundering and Counter-Terrorism Financing Act 2006 (Cth);</li>
                      <li>to investors, agents or advisers, or any entity that has an interest in our business;</li>
                      <li>to your employer, referees or identity verification services.</li>
                    </ul><br />
                    Prior to disclosing any of your personal information to another person or organisation, we will take all reasonable steps to
                    satisfy ourselves that:<br />
                    <ol style={{ 'listStyleType': 'lower-alpha' }}>
                      <li>the person or organisation has a commitment to protecting your personal information at least equal to our commitment,
                        or</li>
                      <li>you have consented to us making the disclosure.</li>
                    </ol><br />
                    We may use cloud storage to store the personal information we hold about you. The cloud storage and the IT servers may be
                    located outside Australia. We may disclose your personal information to overseas entities (including but not limited to; India,
                    Philippines & New Zealand) that provide support functions to us. You may obtain more information about these entities by
                    contacting us.
                  </Typography>
                  <Typography style={{ padding: "20px 0" }}>
                    <span style={{ fontWeight: 800 }}>
                      DIRECT MARKETING
                    </span><br />
                    From time to time we may use your personal information to provide you with current information about finance, offers youmay find of interest, changes to our organisation, or new products or services being offered by us or any company with whom
                    we are associated. If you do not wish to receive marketing information, you may at any time decline to receive such
                    information by telephoning us on 02 4647 5666 or by writing to us at P.O. Box 680 Narellan NSW 2567. If the direct marketing
                    is by email you may also use the unsubscribe function. We will not charge you for giving effect to your request and will take
                    all reasonable steps to meet your request at the earliest possible opportunity. It is important to us that the personal
                    information we hold about you is accurate and up to date. During the course of our relationship with you we may ask you to
                    inform us if any of your personal information has changed.
                  </Typography>
                  <Typography style={{ padding: "20px 0" }}>
                    <span style={{ fontWeight: 800 }}>
                      UPDATING YOUR PERSONAL INFORMATION
                    </span><br />
                    If you wish to make any changes to your personal information, you may contact us. We will generally rely on you to ensure
                    the information we hold about you is accurate or complete.
                  </Typography>
                  <Typography style={{ padding: "20px 0" }}>
                    <span style={{ fontWeight: 800 }}>
                      ACCESS AND CORRECTION TO YOUR PERSONAL INFORMATION
                    </span><br />
                    We will provide you with access to the personal information we hold about you. You may request access to any of the personal
                    information we hold about you at any time. We may charge a fee for our costs of retrieving and supplying the information to
                    you. Depending on the type of request that you make we may respond to your request immediately, otherw ise we usually
                    respond to you within seven days of receiving your request. We may need to contact other entities to properly investigate
                    your request. There may be situations where we are not required to provide you with access to your personal information, for
                    example, if the information relates to existing or anticipated legal proceedings, or if your request is vexatious. An explanation
                    will be provided to you if we deny you access to the personal information we hold about you. If any of the personal
                    information w e hold about you is incorrect, inaccurate or out of date you may request that we correct the information. If
                    appropriate we will correct the personal information. At the time of the request, otherw ise, we will provide an initial response
                    to you within seven days of receiving your request. Where reasonable, and after our investigation, we will provide you with
                    details about w hether we have corrected the personal information within 30 days. We may need to consult with other entities
                    as part of our investigation. If we refuse to correct personal information w e will provide you with our reasons for not
                    correcting the information.
                  </Typography>
                  <Typography style={{ padding: "20px 0" }}>
                    <span style={{ fontWeight: 800 }}>
                      USING GOVERNMENT IDENTIFIERS
                    </span><br />
                    If we collect government identifiers, such as your tax file number, we do not use or disclose this information other than
                    required by law. We will never use a government identifier in order to identify you.
                  </Typography>
                  <Typography style={{ padding: "20px 0" }}>
                    <span style={{ fontWeight: 800 }}>
                      BUSINESS WITHOUT IDENTIFYING YOU
                    </span>I<br />
                    In most circumstances it will be necessary for us to identify you in order to successfully do business with you, how ever,
                    where it is law ful and practicable to do so, we will offer you the opportunity of doing business with us without providing us
                    with personal information, for example, if you make general inquiries about interest rates or current promotional offers.
                  </Typography>
                  <Typography style={{ padding: "20px 0" }}>
                    <span style={{ fontWeight: 800 }}>
                      SENSITIVE INFORMATION
                    </span> <br />
                    We will only collect sensitive information about you with your consent. Sensitive information is personal information that
                    includes information relating to your racial or ethnic origin, political persuasion, memberships in trade or professional
                    associations or trade unions, sexual preferences, criminal record, or health.
                  </Typography>
                  <Typography style={{ padding: "20px 0" }}>
                    <span style={{ fontWeight: 800 }}>
                      HOW SAFE AND SECURE IS YOUR PERSONAL INFORMATION THAT WE HOLD
                    </span> <br />
                    We will take reasonable steps to protect your personal information by storing it in a secure environment. We may store your
                    personal information in paper and electronic form. We will also take reasonable steps to protect any personal information
                    from misuse, loss and unauthorised access, modification or disclosure.
                  </Typography>
                  <Typography style={{ padding: "20px 0" }}>
                    <span style={{ fontWeight: 800 }}>
                      COMPLAINTS
                    </span> <br />
                    If you are dissatisfied with how we have dealt w ith your personal information, or you have a complaint about our compliance
                    with the Privacy Act, you may contact our complaints officer on 02 4647 5666. We will acknow ledge your complaint within
                    seven days. We will provide you with a decision on your complaint within 30 days. If you are dissatisfied with the response of
                    our complaints officer, you may make a complaint to the Privacy Commissioner which can be contacted on either ww
                    w.oaic.gov.au or 1300 363 992.
                  </Typography>
                  <Typography style={{ padding: "20px 0" }}>
                    <span style={{ fontWeight: 800 }}>
                      FURTHER INFORMATION
                    </span> <br />
                    You may request further information about the way we manage your personal information by contacting us.
                  </Typography>
                  <Typography style={{ padding: "20px 0" }}>
                    <span style={{ fontWeight: 800 }}>
                      CHANGE IN OUR PRIVACY POLICY
                    </span> <br />
                    We are constantly review ing all of our policies and attempt to keep up to date with market expectations. Technology is
                    constantly changing, as is the law and market place practices. As a consequence, we may change this privacy policy from
                    time to time or as the need arises. You may request this privacy policy in an alternative form. This Privacy Policy came into
                    existence on 01-01-2024.
                  </Typography>
                  {/* <<<<<<<<<<==========  Lenders table ==========>>>>>>>>>>> */}
                  <Typography style={{ padding: "20px 0" }}>
                    <table>
                      <tbody>
                        {lenderList?.map((lender) => (
                          <tr>
                            <td style={{ border: '1px solid black', padding: '8px' }}>
                              {lender.name}
                            </td>
                            <td style={{ border: '1px solid black', padding: '8px' }}>
                              <a href={`https://${lender.link}`} target="_blank" rel="noreferrer" >
                                {lender.link}
                              </a>
                              </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Typography>
                  <Typography style={{ marginBottom: "3px" }}>
                    <strong>CONSENT AND ACKNOWLEDGEMENT</strong>
                  </Typography>
                  <Typography style={{ padding: "20px 0" }}>
                    By signing this document, I/We:
                    <ul>
                      <li>Accept the quote provided for providing credit assistance;</li>
                      <li>Confirm the appointment of the Credit Licensee to provide credit assistance and to arrange credit on my/our behalf;</li>
                      <li>Declare that the personal information provided is true and correct and acknowledge that you may have to disclose our personal information to other parties to assist you in obtaining credit or where you are required by law to do so;</li>
                      <li>Authorise you to obtain credit reports and any other information relating to my/our credit worthiness, including conducting an Australian or New Zealand credit check;</li>
                      <li>Authorise you to validate personal information provided by me/us;</li>
                      <li>Authorise verification of credit balances or outstanding balances provided to you and consent to you requesting copies of loan statements and payout figures; and</li>
                      <li>Acknowledge that I/we have received a copy of your Credit Guide, Quote & Privacy.</li>
                    </ul>
                  </Typography>
                  {/* <<<<<<<<<<==================  Need to remove below points ================================>>>>>>>>>>>> */}

                  {/* <Typography style={{ padding: "20px 0" }}>
                    I acknowledge that where I have provided You with personal information about someone else that I
                    have obtained their consent to provide their personal information based on this Privacy Consent.
                  </Typography>
                  <Typography style={{ padding: "20px 0" }}>
                    I acknowledge that the third parties with whom You exchange personal information whilst conducting
                    ordinary business activities, may operate outside of Australia. I understand and agree that while
                    the third parties may be subject to confidentiality or privacy obligations, in relation to personal
                    information that is not ‘credit eligibility information’ (certain personal information from (or
                    based on information from) credit reporting bodies) they may not always follow the particular
                    requirements of Australian privacy laws.
                  </Typography>
                  <Typography style={{ padding: "20px 0" }}>
                    You, your related companies and any third parties involved in the introduction or referral of this
                    application (including any dealers or suppliers of items the subject of my application) or with whom
                    we have white label arrangements may exchange and use my personal information to contact me on an
                    ongoing basis by telephone, electronic communications (like email), online and other means to offer
                    products or services that may be of interest to me, including offers of banking, financial,
                    advisory, investment, insurance and funds management services, and assets suited to leasing or
                    finance (including vehicles, medical equipment, computers, machinery and manufacturing equipment).
                  </Typography>
                  <Typography style={{ padding: "20px 0" }}>
                    In accordance with the Privacy Act, requests for access to and correction of personal information
                    can be made using the contact details below:
                  </Typography>
                  <Typography style={{ padding: "20px 0" }}>
                    Addressee: Privacy Officer Postal Address: PO Box 680 Narellan NSW 2567
                  </Typography>
                  <Typography style={{ padding: "20px 0" }}>
                    By requesting a copy of Privacy Policies and (where applicable) credit reporting policies, further
                    information can be obtained regarding the handling of personal information, access or correction of
                    personal information, how privacy concerns are dealt with, website privacy, the credit reporting
                    bodies used and how to obtain free copies of your credit reporting information from those bodies.
                    Brokers Mutual Acceptance Pty Ltd.’s Privacy Policy and Credit Reporting Policy can be found at via
                    financeable.com.au. Further, Brokers Mutual Acceptance Pty Ltd.’s (Privacy Officer) can be contacted on
                    info@brokersmutual.com.au
                  </Typography> */}
                  {/* <<<<<<<<<<==================  Need to remove above points ================================>>>>>>>>>>>> */}
                </AccordionDetails>
              </Accordion>

              {applicationType === APPLICATION_TYPES.CONSUMER && (
                <Accordion style={{ marginBottom: "40px" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{ padding: "5px 30px" }}
                  >
                    <Typography>Show credit guide</Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ padding: "20px 30px" }}>
                    <Typography>
                      This Credit Guide & Quote is provided by Brokers Mutual Acceptance Pty Ltd (ACN. 121 876 953),
                      Credit Licence number 388249. Our address is Suite 5/6 Somerset Ave Narellan NSW 2567
                      and our contact details are phone - (02) 4647 5666 and email - info@brokersmutual.com.au
                    </Typography><br />

                    <Typography style={{ marginBottom: "20px" }}>
                      <span style={{ fontWeight: 800 }}> ABOUT US ("we, us, our") </span><br />
                      We are an authorised Credit Representative of Brokers Mutual Acceptance Pty Ltd. Our details are as follows:
                      {/* <<<<<<<<<<<=========  Broker table will add here  ===========>>>>>>>>>>> */}
                    </Typography>
                    {/* <<<<<<<<<<<=========  BMA table will add here  ===========>>>>>>>>>>> */}
                    <Typography style={{ padding: "20px 0" }}>
                      <span> Broker's Mutual Acceptance Pty Ltd's details are as follows: </span><br />
                      <table>
                        <tbody>
                          {brokerMutualDetails?.map((detail) => (
                            <tr>
                              <td style={{ border: '1px solid black', padding: '8px' }}>
                                {detail.key}
                              </td>
                              <td style={{ border: '1px solid black', padding: '8px' }}>
                                {detail.value}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Typography>
                    <Typography style={{ marginBottom: "5px" }}>
                      <strong>INTERNAL COMPLAINTS OFFICER</strong>
                    </Typography>
                    <Typography style={{ marginBottom: "20px" }}>
                      Phone: 02 4647 5666 or Email: complaints@brokersmutual.com.au.
                    </Typography>
                    <Typography style={{ marginBottom: "5px" }}>
                      <strong>EXTERNAL COMPLAINTS</strong>
                    </Typography>
                    <Typography style={{ marginBottom: "20px" }}>
                      Australian Financial Complaints Authority - Phone: 1800 931 678 or Web: ww w.afca.org.au.
                    </Typography>

                    <Typography style={{ marginBottom: "5px" }}>
                      <strong>SERVICES WE PROVIDE</strong>
                    </Typography>
                    <Typography style={{ marginBottom: "20px" }}>
                      We will help you to choose a loan or lease w hich is not unsuitable for your purposes from our panel of lenders. The lenders
                      we most commonly use are Pepper Money, Secure Funding (Liberty), Latitude Financial Services, Finance One, Money 3 and
                      Now Finance, although we do use others.<br />
                      <ul>
                        <li>We will source the best priced product available to you from our panel of lenders</li>
                        <li>We will help you obtain a credit approval</li>
                        <li>We will prepare all necessary loan documentation</li>
                      </ul>
                    </Typography>

                    <Typography style={{ marginBottom: "5px" }}>
                      <strong>INFORMATION WE NEED FROM YOU</strong>
                    </Typography>
                    <Typography style={{ marginBottom: "20px" }}>
                      Under the NCCP Act, we are obliged to ensure that any loan or principal increase to a loan that we help you to obtain is NOT
                      unsuitable for you. To decide this, we may need to ask you some questions in order to assess whether the loan or lease is not
                      unsuitable. The law requires us to;

                      <ul>
                        <li>make reasonable inquiries about your requirements and objectives;</li>
                        <li>make reasonable inquiries about your financial situation;</li>
                        <li>take reasonable steps to verify that the information provided is accurate.</li>
                        <li>Credit is deemed unsuitable if at the time of the assessment and at time of providing credit if:
                          <li>you could not pay or could only pay with substantial hardship</li>
                          <li>the credit will not meet your requirements and objectives</li>
                        </li>
                      </ul>
                    </Typography>

                    <Typography style={{ marginBottom: "5px" }}>
                      <strong>FEES PAYABLE BY YOU</strong>
                    </Typography>
                    <Typography style={{ marginBottom: "20px" }}>
                      We sometimes charge a fee for our services. The fee ranges from $0 to $2,750 including GST. The fee is payable once only
                      and can be included in your finance contract. The exact fee applicable to you will be disclosed before we submit your loan
                      documents to the lender. There are no other fees and charges payable by you to us how ever you may be liable to pay fees to
                      the financier. This fee is not payable if finance is not approved. You may obtain from us information about how these fees and
                      charges are worked out and a reasonable estimate of those fees.
                    </Typography>

                    <Typography style={{ marginBottom: "5px" }}>
                      <strong>COMMISSIONS RECEIVED BY US</strong>
                    </Typography>
                    <Typography style={{ marginBottom: "20px" }}>
                      We may receive commissions from the lenders and lessors who provide finance for you as our customers. These are not fees
                      payable by you. They are usually calculated as follows:<br />

                      <ul>
                        <li>A fixed base fee; and</li>
                        <li>A percentage of the interest payable at the contract written rate over and above the interest payable on the minimum rate as determined by the Credit Provider; and</li>
                        <li>An additional amount based on a percentage of the above two items may also be payable, however this is unknown at the time of entering into the contract.</li>
                      </ul>
                    </Typography>

                    <Typography style={{ marginBottom: "5px" }}>
                      <strong>COMMISSIONS PAYABLE BY US</strong>
                    </Typography>
                    <Typography style={{ marginBottom: "20px" }}>
                      We may source referrals from broad range of sources including for example, Accountants, Mortgage Brokers, Financial
                      Advisors, Suppliers and vendors etc.; for referring you to us. These referral fees are generally small amounts and accord with
                      usual business practice. These are not fees payable by you. You may, on request, obtain a reasonable estimate of those
                      commissions and how the commission is calculated.
                    </Typography>

                    <Typography style={{ marginBottom: "5px" }}>
                      <strong>OUR INTERNAL DISPUTE RESOLUTION SCHEME</strong>
                    </Typography>
                    <Typography style={{ marginBottom: "20px" }}>
                      We believe that it is essential for our customers to be able to identify and deal with a broker who has the ability, authority and
                      proper training to hear and respond appropriately to any complaints or disputes. It is a requirement under the National
                      Consumer Credit Protection Act 2009 that we have in place an Internal Dispute Resolution procedure. You can lodge
                      complaints by contacting our Complaints Officer via phone on 02 4647 5666 or email via complaints@brokersmutual.com.au
                      or in writing to Brokers Mutual Acceptance Pty Ltd, PO Box 680 Narellan, NSW 2567. You should explain the details of your
                      complaint as clearly as you can. You may do this verbally or in writing. When we receive a complaint, we will attempt to
                      resolve it promptly. We hope that in this way we will stop any unnecessary and inappropriate escalation of minor complaints.
                      We will observe the follow ing principles in handling your complaint;<br />
                      <ul>
                        <li>There is no requirement for face-to-face contact between you and us, although it may be useful for us to come to a satisfactory resolution;</li>
                        <li>We expect that both parties will make a genuine attempt to resolve a complaint promptly;</li>
                        <li>We expect that both parties will provide all essential and relevant information, documents, written statements and any other materials that may properly and reasonably be believed to assist in resolving the complaint;</li>
                        <li>We expect that both parties will comply with all reasonable requests from the other party to provide information within a reasonable time frame.</li>
                      </ul>
                    </Typography>

                    <Typography style={{ marginBottom: "5px" }}>
                      <strong>OUR EXTERNAL DISPUTE RESOLUTION SCHEME</strong>
                    </Typography>
                    <Typography style={{ marginBottom: "20px" }}>
                      If we do not reach agreement on your complaint, you may refer the complaint to an ASIC Approved External Dispute
                      Resolution (EDR) Scheme. Our external dispute resolution provider is Australian Financial Complaints Authority. Their phone
                      number is 1800 931 678 and the website is ww w.afca.org.au. Our member number is 14714. External dispute resolution is a
                      free service established to provide you with an independent mechanism to resolve specific complaints.
                    </Typography>

                    <Typography style={{ marginBottom: "5px" }}>
                      <strong>ACCEPTANCE</strong>
                    </Typography>
                    <Typography style={{ marginBottom: "20px" }}>
                      By signing this document, you agree to the terms set out in this quote and to pay the fees specified above by the time
                      specified above. After you have signed this document in duplicate, we will give you a copy to keep.
                    </Typography>

                    <Typography style={{ marginBottom: "5px" }}>
                      <strong>THINGS YOU SHOULD KNOW</strong>
                    </Typography>
                    <Typography style={{ marginBottom: "20px" }}>
                      The proposed loan arrangements will be based on the products available from our panel lenders and the information provided
                      by you. If the information you provide is incomplete or inaccurate, then before entering the loan you should consider the
                      loan’s appropriateness having regard to your relevant personal circumstances and if necessary seek independent financial
                      advice. We do not provide legal or financial advice. It is important you understand your legal obligations under the loan, and
                      the financial consequences. If you have any doubts, you should obtain independent legal and financial advice before you
                      enter any loan contract.
                    </Typography>
                    {/* <<<<<<<<<<<============  Need to Remove below section =============>>>>>>>>>>>>> */}
                    {/* <Typography style={{ marginBottom: "5px" }}>
                      <strong>YOUR ACKNOWLEDGEMENT and AGREEMENT:</strong>
                    </Typography>
                    <Typography style={{ marginBottom: "20px" }}>
                      <ul>
                        <li>
                          I/We acknowledge that I/we have read the contents of this document and understand its content.
                        </li>
                        <li>
                          I/We acknowledge and agree that I/we will pay you up to the maximum quoted fees and charges
                          for your services if you obtain approval for our loan and it subsequently settles.
                        </li>
                        <li>
                          I/We acknowledge and provide my/our consent to receive this Credit Guide & Quote any other
                          disclosure documents such as the Credit Proposal by electronic means.
                        </li>
                        <li>
                          The consent I/we provided is based on my/our understanding that
                          paper disclosure documents may not be provided and therefore I/we will check nominated
                          electronic communication means for receipt of these documents.
                        </li>
                      </ul>
                    </Typography>
                    <Typography style={{ marginBottom: "20px" }}>
                      I/We understand that I/we may withdraw my/our consent to receive these disclosure documents at any
                      time by contacting the licensee as per above contact details.
                    </Typography> */}
                    {/* <<<<<<<<<<<============  Need to Remove above section =============>>>>>>>>>>>>> */}

                  </AccordionDetails>
                </Accordion>
              )}

              <Grid container item xs={12} justifyContent="center">
                <Grid item sm={12}>
                  <Typography style={{ marginBottom: "30px", fontSize: "18px", fontWeight: 700 }}>
                    I confirm that I am authorised to provide the personal details presented and I consent to my
                    information being checked with the document issuer or official record holder for the purpose of
                    confirming my identity.
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12} justifyContent="center">
                <Grid item sm={12}>
                  <Box
                    style={{
                      margin: "0 0 20px 0",
                      dotSize: 1,
                      // padding: "0 15px",
                      // width: "500px",
                      // borderBottom: "2px solid #333333",
                      zIndex: 10,
                      position: "relative",

                      // fontWeight: 700
                    }}
                  >
                    Sign using the pad below:
                  </Box>

                  <ReactSignatureCanvas
                    ref={(ref) => (sigCanvas = ref)}
                    penColor="#2946bb"
                    clearOnResize={false}
                    onEnd={() => trimSignature()}
                    canvasProps={{
                      className: "sigCanvas",
                      style: {
                        width: "100%",
                        // height: 180,
                        aspectRatio: "2/1",
                        position: "relative",
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        borderRadius: "8px",
                        boxShadow: `0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)`,
                      },
                    }}
                  />
                  <Box
                    style={{
                      margin: "-50px auto 50px",
                      padding: "0",
                      width: "95%",
                      borderBottom: "2px solid #333333",
                      zIndex: 10,
                      position: "relative",
                    }}
                  >
                    {/* <Typography style={{ height: "30px" }}>Signature:</Typography> */}
                    {/* <Typography style={{ fontSize: "32px", userSelect: "none" }}>x</Typography> */}
                  </Box>
                  <Button
                    variant="link"
                    size="small"
                    onClick={() => {
                      sigCanvas.clear();
                      setSignature(null);
                    }}
                    style={{ float: "right" }}
                  >
                    Clear signature
                  </Button>
                  <Grid item sm={12}>
                    <LoadingButton
                      fullWidth
                      disabled={!signature || !canSubmit}
                      variant="contained"
                      onClick={() => handleSubmitPrivacy()}
                      style={{ margin: "30px 0 50px" }}
                    >
                      {!canSubmit ? "Submitting..." : "Accept Agreement"}
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}

          {submitted && !lenderSubmissionUplift && (
            <Grid container item direction="column" xs={12}>
              <Typography
                variant="h1"
                style={{
                  fontSize: "24px",
                  marginBottom: "30px",
                }}
              >
                Agreement Signed Successfully
              </Typography>
              <Typography
                variant="h2"
                style={{
                  fontSize: "18px",
                  marginBottom: "10px",
                }}
              >
                Too easy! You're one step closer and not far to go now. Enjoy the rest of your day.
              </Typography>
              <Typography
                style={{
                  fontSize: "14px",
                }}
              >
                You can close this window.
              </Typography>
            </Grid>
          )}

          {lenderSubmissionUplift && (
            <PrivacyCreditGuide applicationType={applicationType} />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
