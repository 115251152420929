import { useSelector } from "react-redux";
import * as yup from "yup";
import { useFormik } from "formik";
import { ApplicationType, InArrears, FeeFrequency } from "@/store/slices/types/applicationFormSlice";
import type { ApplicationFormSlice, ApplicationState, Fee } from "@/store/slices/types/applicationFormSlice";
// @ts-expect-error: ignoring as it is existing js file
import { applicationSelector } from "@/store/slices/applicationFormSlice";
// @ts-expect-error: ignoring as it is existing js file
import regex from "@/utils/regex";
import { getSelectableFeesList } from "../utils";
import type { LenderProduct } from "../LenderCardContext/types/LenderProductTypes";

enum RepaymentFrequency {
  Weekly = "Weekly",
  Fortnightly = "Fortnightly",
  Monthly = "Monthly",
}

// This extended processingFeeFrequency follows the frequency of the repayments rather than the frequency of the fees.
// It is unique to the Payment Processing Fee.
interface CustomQuoteFee extends Fee {
  processingFeeFrequency?: RepaymentFrequency;
}

interface QuoteCalculatorFormFields {
  rate: number | undefined;
  brokerage: number | undefined;
  inArrears: InArrears;
  repaymentFrequency: RepaymentFrequency;
  fees: CustomQuoteFee[];
  selectFee: string;
  customFee?: string;
  selectableFeesList: Fee[];
  referrer: string;
  lenderDefaultFees: boolean | undefined;
}

const useQuoteCalculatorForm = ({ product }: { product: LenderProduct }) => {
  const { applicationType, loanDetails } = useSelector<ApplicationFormSlice, ApplicationState>(applicationSelector);
  const { lender } = product ?? {};

  const isLoanDetailsFromSelectedLender = loanDetails.lender === lender;

  const initialValuesFromLoanDetails = {
    rate: loanDetails.rate,
    brokerage: loanDetails.brokerage,
    inArrears: loanDetails.inArrears || (applicationType === ApplicationType.Commercial ? InArrears.No : InArrears.Yes),
    // ## TODO: (FeeFrequency) When the fee frequency has changed, we do not need to convert the lettercase.
    fees: loanDetails.fees.map((fee) => ({ ...fee, frequency: fee.frequency.toLowerCase() as FeeFrequency })),
    selectableFeesList: getSelectableFeesList(loanDetails.fees),
  };

  const initialValues: QuoteCalculatorFormFields = {
    rate: 0,
    brokerage: 0,
    inArrears: applicationType === ApplicationType.Commercial ? InArrears.No : InArrears.Yes,
    repaymentFrequency: RepaymentFrequency.Monthly,
    fees: [],
    selectFee: "",
    selectableFeesList: getSelectableFeesList([]),
    referrer: "",
    lenderDefaultFees: undefined,
    ...(isLoanDetailsFromSelectedLender && initialValuesFromLoanDetails),
  };

  const quoteCalculatorFormik = useFormik({
    initialValues,
    onSubmit: () => {},
    validationSchema: yup.object({
      rate: yup.string().required("Rate is required").matches(regex.percentageRegex, "Please enter valid percentage."),
      brokerage: yup
        .string()
        .required("Brokerage is required")
        .matches(regex.percentageRegex, "Please enter valid percentage."),
    }),
  });

  return { quoteCalculatorFormik };
};

export { useQuoteCalculatorForm, RepaymentFrequency };
export type { QuoteCalculatorFormFields };
