import { usePostHog } from "posthog-js/react";
import { fallbackFeatureFlags, type FeatureFlagKeys } from "./fallbackFeatureFlags";

type UseFeatureFlagsWithFallback = undefined | string | boolean;

const useFeatureFlagsWithFallback = (key: FeatureFlagKeys): UseFeatureFlagsWithFallback => {
  const posthog = usePostHog();
  // If the public PostHog key is not set, then fallback feature flags are being used.
  const isFallbackFeatureFlagsEnabled = !import.meta.env.VITE_PUBLIC_POSTHOG_KEY;
  if (isFallbackFeatureFlagsEnabled) {
    return fallbackFeatureFlags[key];
  }
  // Else return feature flags from PostHog.
  return posthog.getFeatureFlag(key);
};

export { useFeatureFlagsWithFallback };
