import { useMemo } from "react";
import { useSelector } from "react-redux";
import { VITE_ALLOWED_LENDER_LIST } from "@/constants";
import {
  useGetCommercialLendersQuery,
  useGetConsumerLendersQuery,
  useGetPersonalLendersQuery,
  // @ts-expect-error: ignoring as it is existing js file
} from "@/store/slices/apiSlice";
import type { UserSlice, UserState } from "@/store/slices/types/userSlice";

/**
 * This hook does heavy crunching by fetching 3 queries and then filteting the lenders.
 * It returns the added lenders and the remaining lenders.
 * This hook should only be called when necessary.
 */
const useLenderList = () => {
  const { lenderSettings } = useSelector<UserSlice, UserState>(({ user }) => user);

  // Queries.
  const { data: consumerData, isFetching: consumerIsFetching } = useGetConsumerLendersQuery();
  const { data: personalData, isFetching: personalIsFetching } = useGetPersonalLendersQuery();
  const { data: commercialData, isFetching: commercialIsFetching } = useGetCommercialLendersQuery();

  // Lender list filters.
  const lendersList = useMemo(() => {
    if (
      consumerData &&
      !consumerIsFetching &&
      personalData &&
      !personalIsFetching &&
      commercialData &&
      !commercialIsFetching
    ) {
      return [
        ...consumerData?.data?.getAllLendersData?.docs,
        ...personalData?.data?.getAllLendersData?.docs,
        ...commercialData?.data?.getAllLendersData?.docs,
      ];
    }
    return [];
  }, [consumerData, consumerIsFetching, personalData, personalIsFetching, commercialData, commercialIsFetching]);

  const allowedLendersList = lendersList.flat().filter(({ lender }) => VITE_ALLOWED_LENDER_LIST.includes(lender));
  const filteredLendersList = allowedLendersList.filter(
    (obj, index, self) => index === self.findIndex((item) => item.lender === obj.lender),
  );

  // Added lenders.
  const addedLendersList = lenderSettings.map((setting) => {
    const logo = filteredLendersList.find(
      ({ lender }) => lender === setting.lender || (lender === "Dynamoney" && setting.lender === "Grow"),
    );
    return { logo: logo?.logoURL, ...setting, lenderCredentials: logo?.lenderCredentials };
  });

  // Remaining lenders to be added.
  const filterAndSortRemainingLenders = useMemo(() => {
    const lenserSettingNames = lenderSettings?.map(({ lender }) => lender);
    const remaininingLenders = filteredLendersList?.filter((lender) => !lenserSettingNames?.includes(lender.lender));
    return remaininingLenders.sort((a, b) => {
      return a.lender.toLowerCase() > b.lender.toLowerCase() ? 1 : -1;
    });
  }, [filteredLendersList, lenderSettings]);

  // Remaining lenders to be added.
  const remainingLendersList = filterAndSortRemainingLenders;

  return { addedLendersList, remainingLendersList, consumerIsFetching, personalIsFetching, commercialIsFetching };
};

export { useLenderList };
